import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'

import Snackbar from 'material-ui/Snackbar'

import Calendar from 'react-calendar'
import moment from 'moment'
import Slider from 'react-slick'
import timezone from 'moment-timezone';
import NumberFormat from 'react-number-format';
import axios from 'axios'
import _ from 'lodash';
import ShowMore from 'react-show-more';
import Cookies from 'universal-cookie'

import styles from './styles.scss'

import config from '../../config'
import { fetch } from '../../utils';
import { getTimeFormate } from '../../config'
import TopNavigation from '../TopNavigation/container'

const HOSTNAME = process.env.API_HOSTNAME
const cookies = new Cookies()
const required = value => value ? undefined : 'Required'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const settingsbg = {
  dots: false,
  infinite: true,
  speed: 800,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

class ClientsAppointment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      openSnackbar: false,
      errMessage: '',
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      userid: '',
      success: '',
      role: '',
      errProfileMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      openSchedule: false,
      source: "",
      times: [],
      date: moment().format("MM/DD/YYYY"),
      timezone: moment.tz.guess(),
      timezone_type: "UTC",
      selectedTime: "",
      noTimeWorks: false,
      noSchedule: false,
      err: {},
      infoLength: 0,
      strategy: [],
      isDateLoading: false,
      strategist: "",
      selectedAgent: [],
      appointment_type: '',
      message: ''
    }
  }

  componentWillMount() {
    let _user = cookies.get('user')
    if (_user) {
      this.setState({ userid: _user.id })
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
      this.props.getClientStrategist(data)
      this.props.getClientAgent(data)
    }
  }

  componentDidMount() {
    document.title = "Jungler: Calendar";
  }

  componentWillUnmount() {
    document.title = "Suburban Jungle";
  }

  componentWillReceiveProps(nextProps) {
    let self = this
    if (nextProps.profilePhase === "error") {
      this.setState({ isLoadingProfile: false, errProfileMessage: nextProps.profileError })
    } else if (nextProps.profilePhase === "success") {
      this.setState({ isLoadingProfile: false, errProfileMessage: "Profile info saved successfully." })

      if (nextProps.isSubmitting != false) {
        if (this.state.userid != "") {
          const data = {}
          data.user_id = this.state.userid;
          this.props.fetchUser(data)
        }
      }
    }
    if (nextProps.profilePhase === "success") {
      if (nextProps.user) {
        let user = nextProps.user
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role
        })
      }
    }
    if (nextProps.submitSchedulePhase === true) {
      this.setState({ message: nextProps.resMessage })
      setTimeout(function () {
        self.closeScheduleBar()
      }, 3000);
    }
    if (nextProps.submitSchedulePhase === false) {
      this.setState({ message: nextProps.resMessage })
      setTimeout(function () {
        self.setState({ message: '' })
      }, 3000);
    }
  }

  availability(id, date) {
    this.setState({ isDateLoading: true })
    let data = {};
    data.user_id = id;
    data.date = moment(date ? date : this.state.date).format("MM/DD/YYYY");
    data.timezone = this.state.timezone;
    fetch(`${HOSTNAME}/client/client_strategist_availability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        return res.json();
      })
      .then(payload => {
        if (payload.status) {
          if (payload.timezone_type) {
            this.setState({ timezone_type: payload.timezone_type })
          }
          let timeArray = payload.data; //res.data.data;
          if (timeArray.length > 0) {
            this.handleSlider(timeArray);
          }
          // else if(!date) {
          //   var currentTimestamp = moment(this.state.date).format("X");
          //   var d = moment(this.state.date).add(1, 'days')._d
          //   var SevenDays = moment().add(7, 'days')._d
          //   var AfterWeektimestamp = moment(SevenDays).format("X");
          //   if (currentTimestamp < AfterWeektimestamp) {
          //     this.setState({ date: moment(d).format("MM/DD/YYYY") }, ()  => {
          //       this.availability(id)
          //     });
          // }
          // else{
          //     this.setState({ times: [], isDateLoading: false })
          //   }
          // }
          else {
            this.setState({ times: [], isDateLoading: false })
          }
        }
        else {
          this.setState({ times: [] })
          alert("Please select different date");
        }
      })
      .catch(error => {
        throw error;
      });
  }

  handleSlider(timeArray) {
    setTimeout(function () { this.setState({ isDateLoading: false }); }.bind(this), 500);
    let finalArray = []
    let arr = []
    let i = -1;
    let next = () => {
      i++;
      if (i < timeArray.length) {
        arr.push(timeArray[i])
        if (arr.length > 8) {
          finalArray.push(arr);
          arr = []
          next();
        } else {
          next();
        }
      } else {
        if (arr.length > 0) {
          finalArray.push(arr);
        }
        this.setState({ times: finalArray })
      }
    }
    next();
  }

  handleDateClick(date) {
    this.setState({ times: [], date: moment(date).format("MM/DD/YYYY") });
    let id = this.state.selectedUserId;
    this.availability(id, date);
  }

  selectTime(time) {
    this.setState({ selectedTime: time, err: {} })
  }

  // Function to set form input values in state
  handleChange(event) {
    if (event.target.value === 'null') {
      return;
    }
    let role = ''
    if (this.state.selectedAgent.length > 0) {
      role = 'agent'
    } else {
      let scheduleArray = _.concat(this.props.strategistData, this.props.agentData);
      let data = _.filter(scheduleArray, function (o) {
        if (o) {
          let id = o.role === 'strategist' ? o.id : o.agent_id;
          return id === parseInt(event.target.value);
        }
      });
      role = data[0].role === 'strategist' ? data[0].role : data[0].agent_role
    }
    this.setState({ selectedUserId: event.target.value, selectedRole: role })
    this.availability(event.target.value)
  }
  selectAppointmentType(val) {
    this.setState({ appointment_type: val, err: {} })
  }

  openScheduleBar(agentData) {
    let arr = []
    let scheduleArray = _.concat(this.props.strategistData, this.props.agentData);
    let val = scheduleArray[0];
    if (agentData) {
      val = agentData;
      arr.push(agentData)
    }
    if (val) {
      let id = val.role === 'strategist' ? val.id : val.agent_id;
      let role = val.role === 'strategist' ? val.role : val.agent_role;
      this.availability(id);
      this.setState({ openSchedule: true, selectedUserId: id, selectedAgent: arr, selectedRole: role })
    }
  }

  closeScheduleBar(event) {
    this.setState({
      openSchedule: false,
      date: moment().format("MM/DD/YYYY"),
      selectedTime: '',
      appointment_type: '',
      selectedUserId: '',
      message: ''
    })
  }

  submitSchedulingData() {
    let { selectedTime, selectedUserId, timezone, appointment_type, err, date } = this.state;
    if (appointment_type === '') {
      err.appointment_type = 'Please select a appointment type.';
    }
    if (this.state.times.length > 0 && selectedTime === '') {
      err.selectedTime = 'Please select a time.';
    }

    this.setState({ err });
    if (!Object.keys(err).length) {
      let data = {}
      data.time = selectedTime;
      data.user_id = selectedUserId;
      data.date = moment(date).format("YYYY-MM-DD");
      data.timezone = timezone;
      data.appointment_type = appointment_type;
      this.props.scheduleMeeting(data)
    }
  }

  render() {
    const { submitSchedulePhase, handleSubmit, pristine, isSubmitting, rxError, user, token, loginUserClient, phase, strategistData, agentData } = this.props
    let authorization = cookies.get('Authorization')
    let scheduleArray = _.concat(strategistData, agentData);
    if (this.state.selectedAgent.length > 0) {
      scheduleArray = this.state.selectedAgent;
    }
    if (user && user.user && user.user.role !== "client") {
      this.setState({ 'user': user })
      return (
        <Redirect to={`/login`} />
      )
    }
    let strategistAppointmentList = ['Initial strategy session', 'Follow up strategy call']
    let agentAppointmentList = ['Initial Agent Town Call', 'Initial Town Tour', 'Follow up Town Tour', 'Inspection', 'Closing']
    let appointmentTypeArray = this.state.selectedRole === 'strategist' ? strategistAppointmentList : agentAppointmentList;
    let times = this.state.times.map((val, index) => {
      let slot = val;
      return (
        <div className="schedule__time--list" key={index}>
          <ul>
            {slot.map((v, i) =>
              <li key={i} className={this.state.selectedTime === v ? 'active' : ''} onClick={this.selectTime.bind(this, v)}>{getTimeFormate(v).tval} {getTimeFormate(v).type} {this.state.timezone_type}</li>
            )}
          </ul>
        </div>
      )
    });
    return (
      <div>
        <TopNavigation onRef={ref => (this.TopNavigation = ref)} {...this.props} openScheduleBar={this.openScheduleBar.bind(this)} />
        <div className="clients__page--container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="clients__page--head">
                  <h1>Welcome <span className="clients__page--username">{user && user.first_name ? user.first_name : ''}</span> to the Suburban Jungle!</h1>
                  <p>Here is your personalized dashboard which has your Home Search Strategy, local agent
                    assignments, town reports and ways to communicate with your entire deal team!
                  </p>
                </div>
                {strategistData && strategistData.length > 0 ? strategistData.map((val, index) => {
                  let schedulingLink = 'signup?strategist=' + val.first_name + ' ' + val.last_name;
                  let strategist_descrp = val.description
                  // if(strategist_descrp.length > 270) {
                  //   strategist_descrp = strategist_descrp.substring(0,270)+'...';
                  // }
                  return (
                    <div className="clients__page--profile-wrapper" key={index}>
                      <div className="clients__page--profile-info">
                        <div className="clients__page--profile--avtar">
                          <img src={val && val.avatar ? val.avatar : ''} />
                        </div>
                        <h1 className="profile__title">{val && val.designation ? val.designation : 'Strategist'}</h1>
                        <a className="profile__email" href={val && val.email ? 'mailto:' + val.email : ''}>{val && val.email ? val.email : ''}</a>
                        <a className="profile__phone" href={val && val.phone_mobile ? 'tel:' + val.phone_mobile : val && val.phone_work ? val.phone_work : ''}>M: {val && val.phone_mobile ? val.phone_mobile : val && val.phone_work ? val.phone_work : ''}</a>
                      </div>
                      <div className="profile__details">
                        <ShowMore
                          lines={4}
                          more='View more'
                          less='View less'
                          anchorClass='ShowMoreToggle'
                        >
                          {val && strategist_descrp ? strategist_descrp : ''}
                        </ShowMore>
                        <Link className="btn btn__transparent btn__schedule" to={schedulingLink}>Schedule Time with {val && val.first_name ? val.first_name : ''} {val && val.last_name ? val.last_name : ''}<img src="img/long-right-arrow.svg" /></Link>
                      </div>
                    </div>
                  );
                }) : ''}

                <div className="clients__page--list">
                  <ul className="clients__page--list-view">
                    {agentData && agentData.length > 0 ? agentData.map((val, index) => {
                      let agent_descrp = val.agent_description
                      // if(agent_descrp.length > 390) {
                      //   agent_descrp = agent_descrp.substring(0,390)+'...';
                      // }
                      return (
                        <li key={index}>
                          <div className="row">
                            <div className="col-md-8 p__r--0">
                              <div className="clients__page--list__info">
                                <div className="media avtar__wrapper">
                                  <div className="media-left media-middle">
                                    <div className="clients__page--list--avtar">
                                      <img className="media-object" src={val.agent_image ? val.agent_image : ''} alt="" />
                                    </div>
                                  </div>
                                  <div className="media-body">
                                    <h4 className="clients__page--list--avtar-title">{val ? val.agent_first_name + ' ' + val.agent_last_name : ''}</h4>
                                    <p className="clients__page--list--avtar-profile">Agent</p>
                                    <button className="btn btn__green agent-schedule" onClick={this.openScheduleBar.bind(this, val)}>Schedule </button>
                                  </div>
                                </div>
                                <p className="clients__page--list--description">
                                  <ShowMore
                                    lines={4}
                                    more='View more'
                                    less='View less'
                                    anchorClass='ShowMoreToggle'
                                  >
                                    {val && agent_descrp ? agent_descrp : ''}
                                  </ShowMore>

                                </p>
                              </div>
                              <div className="clients__page--list__contact--info">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="list__contact">
                                      <h2>Email</h2>
                                      <h4><a href={val.agent_email ? 'mailto:' + val.agent_email : ''}>{val.agent_email ? val.agent_email : ''}</a></h4>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="list__contact">
                                      <h2>Phone Number</h2>
                                      <h4><a href={val.agent_mobile ? 'tel:' + val.agent_mobile : ''}>{val.agent_mobile ? val.agent_mobile : ''}</a></h4>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="list__contact">
                                      <h2>Upcoming Dates</h2>
                                      <h4>{val.upcoming_schedules ? val.upcoming_schedules : 'No Schedules'}</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 b__left">
                              <div className="clients__town--reports">
                                <div className="clients__town--reports-head">
                                  <img className="town__icon" src="img/town-report-icon.svg" />  View Selected Town Reports
                                </div>
                                <ul className="clients__town--reports-list">
                                  {val && val.towns && val.towns.length > 0 ? val.towns.map((towns, index2) => {
                                    let town_link = 'javascript:void(0);'
                                    if (towns && towns.town_url) {
                                      town_link = towns.town_url ? towns.town_url + '?userid=' + this.state.userid + '&auth=' + authorization : '';
                                    }

                                    let discription = towns.town_description ? towns.town_description : ''
                                    if (discription.length > 70) {
                                      discription = discription.substring(0, 70) + '...';
                                    }
                                    return (
                                      <li key={index2}>
                                        <a className="clients__town--title">{towns && towns.town_name ? towns.town_name : ''}</a>
                                        <div className="clients__town--description">{towns && discription}</div>
                                        <div className="clients__town--reports-action">
                                          <a className="btn btn__grey" href={towns && town_link} target="_blank">View Town Report </a>
                                        </div>
                                      </li>
                                    );
                                  }) : ''}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    }) : ''}
                  </ul>
                </div>

                <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
                  <div className="schedule__sidebar--header">
                    + Appointment
                    <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                      <img src="img/close.svg" />
                    </div>
                  </div>
                  <div className="schedule__sidebar--body">
                    <div className="schedule__sidebar--form--wrapper">
                      <div className="form-group">
                        <label className="text__label--sm">Client Name</label>
                        <select className="form-control custom-select">
                          <option selected>Select the client</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="text__label--sm">Appointment Type</label>
                        <select className="form-control custom-select">
                          <option selected>Select the type of appointment</option>
                        </select>
                      </div>


                      <div className="form-group">
                        <label className="text__label--sm">Select Date</label>
                        <input type="text" className="form-control date__picker" placeholder="Select Date" />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text__label--sm">Start</label>
                            <select className="form-control custom-select">
                              <option selected>Start Time</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text__label--sm">End</label>
                            <select className="form-control custom-select">
                              <option selected>End Time</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-group rem__marg--btm">
                        <label className="text__label--sm">Appointment Description</label>
                        <textarea className="custom-textarea" placeholder="Enter description here..."></textarea>
                      </div>

                    </div>
                  </div>

                  <div className="schedule__sidebar--footer">
                    <button className="btn btn__green btn__green--lg btn-block btn__schedule" onClick={this.submitSchedulingData.bind(this)}>Schedule</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default reduxForm({
  form: 'clientsappointment',  // a unique identifier for this form
  destroyOnUnmount: true,
})(ClientsAppointment)
