/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment } from 'react'
import {
  get,
  map,
  difference,
  compact,
  size,
  filter,
  findIndex,
  includes
} from 'lodash'
import moment from 'moment'
import { DatePicker, Spin } from 'antd'
import Cookies from 'universal-cookie'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import './styles.scss'
import { SUCCESS, ERROR } from '../../../../../constants/phase'
import { multiSelectStyles } from './partials/variable'
import BizDevCredit from './partials/BizDevCredit'
import RefferringClient from './partials/RefferringClient'
import { DownCaretIcon, CloseFillIcon, EditIcon } from '../../../../../components/icons'
import Loader from '../../../../../components/Loader'
const cookies = new Cookies()

export default class OtherComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      review: '',
      isLoading: false,
      isLoadingBizCredit: false,
      isLoadingRefferingClient: false,
      speakToPress: '',
      bizDevOption: [],
      reviewSource: [],
      refferringClientOption: [],
      selectedBizDev: [],
      selectedRefferringClient: [],
      searchTerm: '',
      reviewDate: '',
      editReviewDate: false,
    }
  }
  componentDidMount() {
    const clientId = this.props.match.params.id && this.props.match.params.id
    this.setState({ isLoading: true }, () => {
      this.props.fetchStrategistBDList({ clientId })
      this.props.fetchClientById({ clientId })
      this.props.fetchStrategistDevBiz({ clientId })
    })
  }
  componentDidUpdate(preProps, state) {
    // on change of location using browser go back button fetch client details
    if (location.pathname !== preProps.location.pathname) {
      this.setState(
        {
          searchTerm: '',
          speakToPress: '',
          review: '',
          selectedBizDev: [],
          initialBizDev: false,
          selectedRefferringClient: [],
          editReviewDate: false,
        },
        () => {
          const clientId = this.props.match.params.id && this.props.match.params.id
          this.props.fetchStrategistBDList({ clientId })
          this.props.fetchClientById({ clientId })
          this.props.fetchStrategistDevBiz({ clientId })
        }
      )
    }
  }
  static getDerivedStateFromProps(props, state) {
    // get data if background profile save
    let states = { ...state }
    // first timse fetch client option details for select option value
    if (props.fetchReviewPhase === SUCCESS) {
      props.clearReviewPhase()
      states = {
        ...states,
        review: props.clientDetail.review,
        speakToPress: props.clientDetail.speak_to_press,
        isLoading: false,
      }
    }
    // on update client details for refetch client details
    if (props.addUpdateClientPhase === SUCCESS) {
      const clientId = props.match.params.id && props.match.params.id
      props.fetchClientById({ clientId })
      props.clearClientPhase()
      states = { ...states, isLoading: true }
    }
    // fetch strategist biz dev data with success
    if (props.fetchStrategistBizDevPhase === SUCCESS) {
      const bizDevOption = compact(
        map(props.devBizList, (bd) => {
          if (bd.role !== 'client') return { label: bd.name+' ('+bd.role+')', value: bd.id }
        })
      )
      const clientId = props.match.params.id && props.match.params.id
      // selected option for client selected options
      props.fetchClientStrategistBDList({ clientId })
      props.clearClientPhase()
      states = { ...states, bizDevOption, isLoadingBizCredit: false}
    }
    // on fetching of strategist db from api make option for bizdev and reffering client
    if (props.fetchStrategistBDPhase === SUCCESS) {
      let referringClients = []
      map(props.strategistBDList, (bd) => {

        if (bd && bd.role === 'client' && (bd.secondary === true || bd.secondary === 1) && bd.secondary_name && bd.id) {
          referringClients.push({
            label: bd.secondary_name,
            value: `${bd.id}1`,
            secondary: true,
            id: bd.id,
          })
        }
        if (bd && bd.role === 'client' && bd.primary === true && bd.name && bd.id) {
          referringClients.push({
            label: bd.name,
            value: `${bd.id}0`,
            secondary: false,
            id: bd.id,
          })
        }

      })

      props.clearClientPhase()
      states = {
        ...states,
        refferringClientOption: referringClients,
        isLoadingRefferingClient: false,
      }
    }
    // on create reffering client update or added successfully fetch selected option for client
    if (props.createRefferingClientPhase === SUCCESS) {
      const clientId = props.match.params.id && props.match.params.id
      props.fetchClientStrategistBDList({ clientId })
      props.clearClientPhase()
      states = { ...states, isLoadingRefferingClient: false }
    }
    // on create reffering client update or added successfully fetch selected option for client
    if (props.createRefferingClientPhase === ERROR) {
      props.clearClientPhase()
      states = { ...states, isLoadingRefferingClient: false }
    }
    if (props.fetchClientStrategistBDPhase === SUCCESS) {

      const filterRefferClients = []
      const ids = []
      map(props.clientStrategistBDList, (bd) => {
        if (bd.type === 'strategist_bd') {
          ids.push(bd.user_id)
        }
        if (bd.type === 'client') {
          if (bd.secondary === 1 || bd.secondary === true) {
            const selectedOptions = filter(
              states.refferringClientOption,
              (v) => v.value === `${bd.user_id}1`
            )
            // eslint-disable-next-line no-bitwise
            const isSelected = findIndex(selectedOptions, (p) => {
              const txt = (bd.secondary === 1 || bd.secondary === true) ? true : false
              return p.secondary === txt
            })
            if (isSelected !== -1) {
              filterRefferClients.push(selectedOptions[isSelected])
            }
          }

          if (bd.secondary === 0) {
            const selectedOptions = filter(
              states.refferringClientOption,
              (v) => v.value === `${bd.user_id}0`
            )
            const isSelected = findIndex(
              selectedOptions,
              (p) => p.secondary === false
            )
            if (isSelected !== -1) {
              filterRefferClients.push(selectedOptions[isSelected])
            }
          }
        }
      })

      const filterArr = filter(states.bizDevOption, (v) => includes(ids, v.value))
      if (size(compact(filterArr)) > 0 && size(state.selectedBizDev) === 0 && !state.initialBizDev) {
        states = { ...states, selectedBizDev: compact(filterArr), initialBizDev: true }
      }
      props.clearClientPhase()
      states = {
        ...states,
        selectedRefferringClient:
          size(compact(filterRefferClients)) > 0
            ? compact(filterRefferClients)
            : [],
      }
    }
    if (props.createBizDevPhase === SUCCESS || props.createBizDevPhase === ERROR) {
      props.clearClientPhase()
      states = { ...states, isLoadingBizCredit: false }
    }
    return states
  }
  // change reivew and review and speaktopress and update client
  handleChange = (event) => {
    const { value, name } = event.target
    this.setState({ [name]: value })
    const data = {}
    if (name === 'speakToPress') {
      data.speak_to_press = value
    }
    if (name === 'review') {
      if (value !== 'Yes') {
        data.review_source = []
        data.review_date = ''
      }
      data.review = value
    }
    if (name === 'review_source') {
      data.review_source = value
    }
    data.id = this.props.match.params.id
    this.props.addUpdateClient(data)
  }
  handleBizDev = (options) => this.setState({ selectedBizDev: options })
  // on click on send button
  sendAndCreateBizDev = () => {
    const { selectedRefferringClient, selectedBizDev } = this.state
    this.setState({ isLoadingBizCredit: true })
    const user = cookies.get('user')
    this.props.createBizDev({
      client_id: this.props.match.params.id,
      user_id: map(selectedBizDev, (option) => option.value),
      created_by: user.id,
      type: 'add',
      refferClients: map(selectedRefferringClient, (ref) => ref.id),
    })
  }
  // handle reffering client option
  handleRefferingClient = (handleOption) => {
  
    let newOption = handleOption

    this.setState({ selectedRefferringClient: handleOption, isLoadingRefferingClient: true })
    const diffRemove = difference(this.state.selectedRefferringClient, newOption)

    if (size(diffRemove) > 0) {
      // if item are selected need to remove
      newOption = diffRemove
    }

    if (newOption && newOption !== undefined && newOption !== null) {
      const user = cookies.get('user')
      const clientId = this.props.match.params.id
      const userIds = map(newOption, (user) => user.id)
      
      const data = {
        client_id: parseInt(clientId),
        user_id: [userIds[userIds.length - 1]],
        created_by: user.id,
        type: 'add',
        bizdevs: map(this.state.selectedBizDev, (dev) => dev.value),
        secondary:
          [newOption[newOption.length - 1]] &&
          [newOption[newOption.length - 1]][0].secondary &&
          ([newOption[newOption.length - 1]][0].secondary === true || [newOption[newOption.length - 1]][0].secondary === 1)
            ? true
            : false
      }
    this.props.createRefferClients(data)
    }
  }
  // filter reffering client option and fetch strategist option
  filterRefferClientsOption = (searchTerm) => {
  
    if (searchTerm.length >= 2 ) {
     
      const clientId = this.props.match.params.id && this.props.match.params.id
      
      this.setState({ 
        isLoadingRefferingClient: true, 
        searchTerm 
      }, () => this.props.fetchStrategistBDList({ clientId, searchTerm }))      
    } else {
      this.setState({searchTerm}) 
    }
  }
  // handle change review with add update client details and change review
  handleChangeReview = (options) => {
    const data = {
      id: this.props.match.params.id,
      review_source: [],
    }
    if (size(options)) {
      data.review_source = map(options, (option) => option.value).join(',')
    }
    this.props.addUpdateClient(data)
    this.setState({ reviewSource: options })
  }
  // review date change with edit review and review date change
  handleReviewDateChange = (date) => {
    this.props.addUpdateClient({
      id: this.props.match.params.id,
      review_date: moment(date).format('YYYY-MM-DD'),
    })
    this.setState({ reviewDate: date, editReviewDate: false })
  }
  handleEditReviewDate = () => this.setState({ editReviewDate: true })
  closeHandleEditReviewDate = () => this.setState({ editReviewDate: false })

  render() {
    const { user } = this.props
    const {
      isLoading,
      isLoadingBizCredit,
      isLoadingRefferingClient,
      reviewDate,
      reviewSource,
    } = this.state
    const client = get(this.props, 'clientDetail', {})
    const addTracking =
      client && client.ad_tracking_codes ? client.ad_tracking_codes : ''
    const sources =
      (client && client.review_source && client.review_source.length > 0)
        ? (typeof client.review_source === 'string' && client.review_source.split(','))
        : []
    const reviewsSource =
      reviewSource.length > 0
        ? reviewSource
        : compact(
            map(sources, (source) => {
              if (source !== '') {
                return { label: source, value: source }
              }
            })
          )
    const reviewsDate =
      reviewDate !== ''
        ? moment(reviewDate)
        : client && client.review_date
        ? moment.utc(client.review_date)
        : ''
    const isEnable =
      user &&
      user.role &&
      ['strategist', 'superadmin', 'admin'].includes(user.role)
    return (
      <div>
        <div className="card">
          <div className="card-header d__flex align__items__center">
            <div>
              <h5 className="card-title mb__0">Other</h5>
            </div>
          </div>
          <Spin size="large" spinning={isLoading} indicator={<Loader />}>
            <div className="card-body card-body-sm">
              <div className="">
                <ul className="other__list__info">
                  <li className="row">
                    <div className="col-md-3">
                      <div className="info__block">
                        <label>Booked How</label>
                        <p>{client.booked_by}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <select
                          className="form-control custom__select material-textfield-input"
                          name="review"
                          value={this.state.review}
                          onChange={this.handleChange}
                        >
                          <option value="">Select an option</option>
                          <option value="Asked">Asked</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Declined">Declined</option>
                        </select>
                        <label className="material-textfield-label">
                          Left Review
                        </label>
                      </div>
                    </div>
                  </li>
                  {this.state.review === 'Yes' && (
                    <li className="row">
                      <div className="col-md-3">
                        <div className="other-review-date">
                          <div className="info__block">
                            {this.state.editReviewDate ? (
                              <Fragment>
                                <div className="btn__follow-up-date">
                                  <div className="follow-up-date-label">
                                    Review Date
                                  </div>
                                  <DatePicker
                                    className="datepicker__follow-up-date"
                                    dropdownClassName="datepicker__overlay__follow-up-date"
                                    onChange={this.handleReviewDateChange}
                                    value={reviewsDate}
                                    name="reviewsDate"
                                    inputReadOnly={true}
                                    allowClear={false}
                                    format="MM/DD/YYYY"
                                    placeholder="Select Date"
                                  />
                                  <DownCaretIcon className="caret-icon" />
                                  {/*<button className="btn btn__btn" onClick={this.closeHandleEditReviewDate}><CloseFillIcon/></button>*/}
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <label onClick={this.handleEditReviewDate}>Review Date </label>
                                <p>
                                  {reviewsDate !== ''
                                    ? moment(reviewsDate).format('MM/DD/YYYY')
                                    : 'N/A'}
                                  {reviewsDate !== '' && <button className="btn btn__btn" style={{minHeight: "inherit"}} onClick={this.handleEditReviewDate}><EditIcon/></button>}
                                </p>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <div className="multiselect__checkboxes__field">
                            <ReactMultiSelectCheckboxes
                              styles={multiSelectStyles}
                              width="100%"
                              name="reviewSource"
                              placeholderButtonLabel="Select"
                              value={
                                this.state.reviewSource.length > 0
                                  ? this.state.reviewSource
                                  : reviewsSource
                              }
                              options={[
                                { value: 'Facebook', label: 'Facebook' },
                                { value: 'Google', label: 'Google' },
                              ]}
                              onChange={this.handleChangeReview}
                            />
                            <label className="multiselect__checkboxes__field__label">
                              Where The Review Was Posted?
                            </label>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                  <li className="row">
                    <div className="col-md-3">
                      <div className="info__block">
                        <label>Created Date </label>
                        <p>
                          {client.created
                            ? moment(client.created).format('MM/DD/YYYY')
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <select
                          className="form-control custom__select material-textfield-input"
                          name="speakToPress"
                          value={this.state.speakToPress}
                          onChange={this.handleChange}
                        >
                          <option value="">Select an option</option>
                          <option value="Asked">Asked</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Declined">Declined</option>
                        </select>
                        <label className="material-textfield-label">
                          Willing to speak to press/featured in a blog
                        </label>
                      </div>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-md-3">
                      <div className="info__block">
                        <label>iOS App Installed</label>
                        {client && client.is_app_installed === 1 ? (
                          <p>
                            Yes(
                            {client.app_install_date &&
                            client.app_install_date !== '0000-00-00 00:00:00'
                              ? moment(client.app_install_date).format('MM-DD-YYYY')
                              : 'N/A'}
                            )
                          </p>
                        ) : (
                          <p>No</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <BizDevCredit
                        isEnable={isEnable}
                        isLoadingBizCredit={isLoadingBizCredit}
                        selectedBizDev={this.state.selectedBizDev}
                        bizDevOption={this.state.bizDevOption}
                        handleBizDev={this.handleBizDev}
                        sendAndCreateBizDev={this.sendAndCreateBizDev}
                        {...this.props}
                      />
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-md-3">
                      {/* <div className="info__block">
                        <label>Ad Tracking Code</label>
                        <p>
                          {addTracking && addTracking.trim() ? addTracking : 'N/A'}
                        </p>
                      </div> */}
                    </div>
                    <div className="col-md-6">
                      <RefferringClient
                        isEnable={isEnable}
                        isLoadingRefferingClient={isLoadingRefferingClient}
                        selectedRefferringClient={this.state.selectedRefferringClient}
                        refferringClientOption={this.state.refferringClientOption}
                        searchTerm={this.state.searchTerm}
                        filterRefferClientsOption={this.filterRefferClientsOption}
                        handleRefferingClient={this.handleRefferingClient}
                        {...this.props}
                      />
                      <div className="info__block mt__10">
                        <label>Ad Tracking Code</label>
                        <p>{(addTracking && addTracking.trim() ? addTracking : 'N/A')}</p>
                      </div>
                      {/* <div className="source__code__ad">
                        {(addTracking && addTracking.trim() ? addTracking : 'N/A')}
                      </div> */}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    )
  }
}
