import 'rxjs'
import React, { PureComponent, Component } from 'react'
import { Drawer } from 'antd'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment';
import { CloseIcon } from '../../components/icons'
import _, { map, get } from "lodash";
// import moment from 'moment-timezone';
import config from '../../config'
import { fetch } from '../../utils';
import Slider from 'react-slick'
import axios from 'axios'
import { getTimeFormate, formatPhoneNumber, timeConverter } from '../../config'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import ShowMore from 'react-show-more';
import io from 'socket.io-client'
import $ from 'jquery'
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert'; // Import core
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import btnStyles from './styles.scss'
import {getHostname} from "../../utils/helper";

const cookies = new Cookies()
const HOSTNAME = process.env.API_HOSTNAME
const SUBURBAN_HOST = process.env.SUBURBAN_HOST
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
// const SOCKET_HOSTNAME = process.env.SOCKET_HOSTNAME
const SOCKET_HOSTNAME = process.env.SUBURBANJUNGLE_API_HOST
let socket = io.connect(SOCKET_HOSTNAME, {
  transports:['websocket', 'polling']
})

let _user = ''
let pre_Auth = ''
let defaultTotalTown = []

const initialValues = {
}

const settings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
  townArray: [],
  agentArray: [],
  initDataLoading: true,
  shareInfoToggle: false,
  agnetAutoAssignTown: false,
  localArray: [],
  businessArray: [],
  totalTownArray: [],
  autoplay: false,
  openChat: false,
  chat: "",
  chatStrategist: '',
  responsive: [
    {
      breakpoint: 767,
      dots: true,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const customStyCSS = {
  control: provided => ({
    ...provided,
    minHeight: "30px",
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: "30px"
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: "5px"
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: "5px"
  })
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent !important',
    border: '0px !important',
    border: '0px !important'
  }
}
const dragList = {
  ulll: {
    height: 'calc(100vh - 250px)',
    zIndex: '99999',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  items: {
    borderBottom: '2px solid #F4F4F4',
    padding: '20px',
    // width: 'calc(100% - 30px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    background: '#fff',
    zIndex: '99999',
    position: 'relative'
  },
  btn: {
    width: '30px',
    marginRight: '20px',
    background: 'none',
    border: '0',
    padding: '0'
  },
  name: {
    fontFamily: 'Pier Sans',
    fontWeight: 'bold',
    fontSize: '16px',
    width: 'calc(100% - 100px)',
    lineHeight: '19px',
    color: '#3B3B3B'
  },
  icon: {
    width: '30px',
    marginLeft: '20px'
  },
  nameagent: {
    fontFamily: 'Pier Sans',
    fontWeight: 'bold',
    fontSize: '16px',
    width: 'calc(100% - 155px)',
    lineHeight: '19px',
    color: '#3B3B3B'
  },
  imgs: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    ObjectFit: 'cover'
  }
}


//This is editing town data
const SortableItem1 = SortableElement(({ value, onRemove }) =>
  <li style={dragList.items} key={`input-${value}`}>
    {/* <button className="drag__list--icons" style={dragList.btn} key={`input-${value}`} onClick={() => onRemove(value)}></button> */}
    <div style={dragList.name} id={value.town_id}>{value.town_name}</div>
    <div style={dragList.icon} ><img src="img/rate-menu.svg" /></div>
  </li>
)

const SortableList1 = SortableContainer(({ items, onRemove }) => {
  return (
    <div className="rate__town--listing">
      <ul style={dragList.ulll}>
        {items.map((value, index) => (
          <SortableItem1 key={`item-${index}`} onRemove={onRemove} index={index} value={value} />
        ))}
      </ul>
    </div>
  )
})

class SortableComponent1 extends Component {
  render() {
    return <SortableList1 items={this.props.data} onRemove={this.props.onRemove} onSortEnd={this.props.onSortEnd} axis="xy" />
  }
}

const SortableItem2 = SortableElement(({ value, onRemove }) =>
  <li style={dragList.items} key={`input-${value}`}>
    {/* <button className="drag__list--icons" style={dragList.btn} key={`input-${value}`} onClick={() => onRemove(value)}></button> */}
    <span className="profile__image"><img style={dragList.imgs} src={value.image ? value.image : "img/default-user.svg"} /></span>
    <div style={dragList.nameagent} id={value.id}>{value.name}</div>
    <div style={dragList.icon} ><img src="img/rate_menu.svg" /></div>
  </li>
)

const SortableList2 = SortableContainer(({ items, onRemove }) => {
  return (
    <div className="rate__town--listing">
      <ul style={dragList.ulll}>
        {items.map((value, index) => (
          <SortableItem2 key={`item-${index}`} onRemove={onRemove} index={index} value={value} />
        ))}
      </ul>
    </div>
  )
})

class SortableComponent2 extends Component {
  render() {
    return <SortableList2 items={this.props.data} onRemove={this.props.onRemove} onSortEnd={this.props.onSortEnd} axis="xy" />
  }
}

//=========================

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button
        className="selectdate__global letterspacing__one selectbox__height"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class ClientDashboardComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      visible: false,
      currentDate: moment(),
      selectedStrAgent: [],
      availableDates: [],
      selectedRole: '',
      startDate: moment(),
      bookingConfirmedDate: moment(),
      timezone: moment.tz.guess(),
      timezone_type: "UTC",
      isLoading: false,
      selectedTime: '',
      appointment_type: '',
      err: {},
      err1: {},
      modalIsOpen: false,
      initDataLoading: false,
      userName: '',
      userTime: '',
      classShowHide: false,
      pre_Auth: '',
      reSchedulLoading: false,
      matchDate: moment(),
      addTownOpen: false,
      chatData: [],
      townOptions: [],
      totalRecommendedTowns: [],
      totalTownArray: [],
      openModel: false,
      town_name: '',
      rateTownToggle: false,
      msgToggle: false,
      agentDropDown: false,
      agentSearch: '',
      agentClickable: false,
      chat_text: '',
      chatSelectAgentId: 0,
      chatAgentName: '',
      clientChatWithAgentData: [],
      ratingType: "add",
      editingType: "town",
      listItemTown: [],
      listItemTownAllData: [],
      listItemAgent: [],
      listItemAgentAllData: [],
      townShowing: false,
      agentShowing: false,
      menuIconDrawer: false,
      schduleOpenClose: false,
      dropDownVal: "recommendedCount",
      dropDownBoolVal: false,
      secondDropDownVal: "New Appointment",
      secondDropDownBoolVal: false,
      strategist_note: '',
      openStrategistModel: false,
      selectStrategistData: [],
      selectedId: '',
      checkClientNY: false,
      feedbackModelOpen: false,
      feedbackText: '',
      rating: 0,
      moveStatus: null,
      townOpenModel: false,
      townOpenModelConfirm: false,
      townOpenModelTitle: 'Move Town',
      moveTownName: '',
      moveTownId: 0,
      moveAgentId: 0,
      openComment: false,
      comment: '',
      commentsData: [],
      comment_id: null,
      isComment: false,
      agentArray: [],
      isAgentLocalUpdate: false,
      strategistList: []
    }
    this.shouldCancelStart = this.shouldCancelStart.bind(this)
    this.orderListTown = this.orderListTown.bind(this)
    this.orderListAgent = this.orderListAgent.bind(this)
    this.remove = this.remove.bind(this)
    this.deleteAgent = this.deleteAgent.bind(this)
  }
  componentWillMount() {
    const { fetchUser } = this.props;
    _user = cookies.get('user')
    if (_user) {
      this.setState({ userid: _user.id })
      this.setState({ timezone: _user.time_zone })
      const data = {}
      data.user_id = _user.id;
      fetchUser(data)
      this.props.getClientTownRateData()
      this.props.getTowns()
      this.props.getClientStrategist(data)
      this.props.getClientAgent(data)
      this.props.getClientChatData(data)
      this.props.getRecommendedTowns()
      const data2 = {}
      data2.user_id = _user.id
      data2.timezone = _user.time_zone
      this.props.getClientAppointments(data2)
      pre_Auth = cookies.get('i_user')
      if (pre_Auth) {
        this.setState({ pre_Auth: pre_Auth })
      }
    }
    defaultTotalTown = []
    let defaultObj = {
      town_name: 'zzzzzzzzzz',
      town_id: -1,
      town_url: 'demo_div',
      status: 1,
      town_image: 'demo_img'
    }
    defaultTotalTown.push(defaultObj)
    // socket.on('new_message', (data) => {
    //   this.componentDidUpdate()
    // })
  }

  componentDidMount() {
    if (this.getParameterByName('chat') && this.getParameterByName('chat') != '' && this.getParameterByName('chat') != null) {
      this.setState({ openChat: true })
    }
    if(localStorage.getItem('appointments_drawer')) {
      this.setState({visible:true,secondDropDownVal:'Upcoming Appointment'})
      localStorage.removeItem('appointments_drawer');
    }
    socket.on('client_strategist_chat', (data) => {
      if (this.state.userid) {
        const clientId = parseInt(this.state.userid)
        if (data && ((clientId === parseInt(data.sender_id)) || (clientId === parseInt(data.receiver_id)))) {
          var chat = this.state.chatData
          chat.push(data)
          this.setState({ chatData: _.cloneDeep(chat) })
        }
      }
    })
    socket.on('security_alert', (data) => {
      if (data.is_deleted && data.user_id) {
        if (this.state.userid == data.user_id) {
          this.userSignout()
        }
      }
    })

    document.querySelectorAll('[data-autoresize]').forEach(function (element) {
      element.style.boxSizing = 'border-box'
      var offset = element.offsetHeight - element.clientHeight
      document.addEventListener('input', function (event) {
        event.target.style.height = 'auto'
        event.target.style.height = event.target.scrollHeight + offset + 'px'
      });
      element.removeAttribute('data-autoresize')
    })

  }

  scheduleAppointmentDrawer = () => {
    this.closeMenuButton()
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      "selectedUserId": null,
      "appointment_type": null,
      "startDate": null,
      "selectedTime": ''
    });
  };

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  // componentDidMount(){
  //   socket.on('new_message', (data) => {
  //     if(this.state.userid){
  //       var chat = this.state.clientChatWithAgentData
  //       chat.push(data)
  //       this.setState({ clientChatWithAgentData: _.cloneDeep(chat)})
  //     }
  //   })
  // }

  async componentWillReceiveProps(nextProps) {
    let townArray = []
    let agentArray = []
    let localArray = []
    let businessArray = []
    let recTowns = []
    let ratingTownIdArr = []
    // const { recommendedTownData } = this.props
    if (nextProps.chatHistoryData !== this.props.chatHistoryData) {
      let chatDataArr = nextProps.chatHistoryData && nextProps.chatHistoryData.data
      this.setState({ clientChatWithAgentData: chatDataArr })
    }

    if (nextProps.addTownPhase !== this.props.addTownPhase) {
      this.setState({ addTownOpen: false, townOptions: [], initDataLoading: true })
      document.body.style.overflow = "visible"
      this.props.getRecommendedTowns()
    }

    if (nextProps.removeTownPhase !== this.props.removeTownPhase) {
      this.setState({ initDataLoading: true })
      const data = {}
      data.user_id = _user.id;
      this.props.getClientAgent(data)
      this.props.getRecommendedTowns()
    }

    if (nextProps.getAgentPhase !== this.props.getAgentPhase) {
      this.setState({ initDataLoading: true })
      if (nextProps.agentData && nextProps.agentData.length > 0) {
        let last_chat_activity = localStorage.getItem('agent_id')
        if (last_chat_activity) {
          let getAgentData = await _.filter(nextProps.agentData, function (user) {
            if (user.agent_id == last_chat_activity) {
              return user
            }
          })
          let agent_full_name = (getAgentData[0].agent_first_name).trim() + ' ' + (getAgentData[0].agent_last_name).trim()
          this.setState({ agentSearch: agent_full_name, agentClickable: true, chatSelectAgentId: getAgentData[0].agent_id, chatAgentName: agent_full_name }, () => {
            const receiver_id = getAgentData[0].agent_id
            if (this.state.userid != '' && receiver_id != '') {
              const data = {}
              data.sender_id = this.state.userid
              data.receiver_id = receiver_id
              this.props.getChatHistory(data)
            }
          })
        }
      }
      this.props.getRecommendedTowns()
    }

    if (nextProps.removeAgentTownPhase !== this.props.removeAgentTownPhase) {
      const data = {}
      data.user_id = _user.id;
      this.props.getClientAgent(data)
    }

    if (nextProps.shareClientInfoData !== this.props.shareClientInfoData) {
      const data = {}
      data.user_id = _user.id;
      this.props.getClientAgent(data)
    }

    if (nextProps && nextProps.agentData && nextProps.agentData.length > 0 && !this.state.isAgentLocalUpdate){
      const jwtToken = cookies.get('Authorization')

      nextProps.agentData.forEach((agent, agIdx) => {
        if(agent.agent_id !== '' && agent.agent_role === "agent") {
          agent.towns && agent.towns.forEach((town, townIdx) => {
            let agentObj = {}
            agentObj.agent_id = agent.agent_id
            agentObj.agent_full_name = agent.agent_first_name + " " + agent.agent_last_name
            agentObj.agent_image = agent.agent_image
            agentObj.agent_role = agent.agent_role
            agentObj.agent_time_zone = agent.agent_time_zone
            agentObj.town_name = town.town_name
            agentObj.town_id = town.town_id
            agentObj.town_image = town.town_image
            agentObj.share_info = agent.share_info
            agentObj.user_id = agent.user_id
            agentObj.town_url = town.town_url + '?tc=' + jwtToken
            agentObj.agent_description = agent.agent_description
            agentObj.agent_email = agent.agent_email
            agentObj.agent_mobile = agent.agent_mobile
            agentArray.push(agentObj)
          })
        }
      })

      this.setState({agentArray})
    }

    if (nextProps && nextProps.agentData && nextProps.agentData.length > 0) {
      const jwtToken = cookies.get('Authorization')
      nextProps.agentData && nextProps.agentData.map((agent, agIdx) => {
        //when agent and locals are not assign to town
        if (agent.agent_id == '' || agent.agent_id == null) {
          agent.towns && agent.towns.map((town, townIdx) => {
            if (town.town_id !== null && town.town_id !== '') {
              let townObj = {}
              townObj.town_name = town.town_name
              townObj.town_id = town.town_id
              townObj.town_url = town.town_url + '?tc=' + jwtToken
              townObj.status = 1
              townObj.town_image = town.town_image
              townObj.isResearch = town.isResearch
              townObj.insightCount = town.insightCount
              townArray.push(townObj)
            }
          })
        }
        else if (agent.agent_id !== '') {
          if (agent.agent_role === "local") {
            agent.towns && agent.towns.map((town, townIdx) => {
              let localObj = {}
              localObj.agent_id = agent.agent_id
              localObj.agent_full_name = agent.agent_first_name + " " + agent.agent_last_name
              localObj.agent_image = agent.agent_image
              localObj.agent_role = agent.agent_role
              localObj.agent_time_zone = agent.agent_time_zone
              localObj.town_name = town.town_name
              localObj.town_id = town.town_id
              localObj.share_info = agent.share_info
              localObj.town_image = town.town_image
              localObj.town_url = town.town_url + '?tc=' + jwtToken
              localObj.agent_description = agent.agent_description
              localObj.agent_email = agent.agent_email
              localObj.agent_mobile = agent.agent_mobile
              localArray.push(localObj)
            })
          } else if (agent.agent_role === "business development") {
            agent.towns && agent.towns.map((town, townIdx) => {
              let localObj = {}
              localObj.agent_id = agent.agent_id
              localObj.agent_full_name = agent.agent_first_name + " " + agent.agent_last_name
              localObj.agent_image = agent.agent_image
              localObj.agent_role = agent.agent_role
              localObj.agent_time_zone = agent.agent_time_zone
              localObj.town_name = town.town_name
              localObj.town_id = town.town_id
              localObj.share_info = agent.share_info
              localObj.town_image = town.town_image
              localObj.town_url = town.town_url + '?tc=' + jwtToken
              localObj.agent_description = agent.agent_description
              localObj.agent_email = agent.agent_email
              localObj.agent_mobile = agent.agent_mobile
              businessArray.push(localObj)
            })
          }
        }
      })
      this.setState({ townArray: townArray, localArray: localArray, businessArray: businessArray }, () => { })

      let totalTowns = []
      if (nextProps && nextProps.agentData && nextProps.agentData.length > 0) {
        const jwtToken = cookies.get('Authorization')
        nextProps.agentData && nextProps.agentData.map((agent, agIdx) => {
          agent.towns && agent.towns.map((town, townIdx) => {
            if (town.town_id !== null && town.town_id !== '') {
              let townObj = {}
              townObj.town_name = town.town_name
              townObj.town_id = town.town_id
              townObj.town_url = town.town_url + '?tc=' + jwtToken
              townObj.status = 1
              townObj.town_image = town.town_image
              townObj.isResearch = town.isResearch
              townObj.insightCount = town.insightCount
              totalTowns.push(townObj)
            }
          })
        })
        this.setState({ totalTownArray: totalTowns }, () => { })
      }
    }

    if (nextProps.strategistData !== this.props.strategistData) {
      this.setState({
        strategistList: nextProps.strategistData.map(strat => strat.id),
        chatStrategistId: _.get(nextProps.strategistData, '[0].id', ''),
        strategistAvatar: _.get(nextProps.strategistData, '[0].avatar', '../../img/default-user.svg'),
        strategistName: _.get(nextProps.strategistData, '[0].first_name', '') + ' ' + _.get(nextProps.strategistData, '[0].last_name', '')
      })
    }
    if (nextProps.recommendedTownData && nextProps.recommendedTownData.status) {
      this.setState({ initDataLoading: false })
      const jwtToken = cookies.get('Authorization')

      if (nextProps.recommendedTownData && nextProps.recommendedTownData.data.length > 0) {
        nextProps.recommendedTownData.data.map((twn, id) => {
          let townObj2 = {}
          townObj2.town_name = twn.town_name
          townObj2.town_id = twn.town_id
          townObj2.town_url = twn.town_url + '?tc=' + jwtToken
          townObj2.status = twn.status
          townObj2.town_image = twn.image
          townObj2.isResearch = twn.isResearch
          townObj2.insightCount = twn.insightCount
          townObj2.isAssigned = twn.isAssigned
          townObj2.agent_id = twn.agent_id
          townObj2.comments = twn.comments
          recTowns.push(townObj2)
          ratingTownIdArr.push(townObj2)
        })
      }
      let rateDataUniq = _.uniqBy(ratingTownIdArr, 'town_id')
      let rateDataOrder = _.orderBy(rateDataUniq, ['town_name'], ['asc'])
      let rateDateFilter = _.filter(rateDataOrder, (r) => {
        return r.status === 1
      })
      this.setState({ initDataLoading: false, totalRecommendedTowns: recTowns })
    }

    if (nextProps.getStrategistDataPhase === true) {
      this.setState({ isLoading: false, initDataLoading: false })
      this.props.clearDatePhase()
      if (nextProps.getStrategistData && nextProps.getStrategistData.length) {
        this.availability(this.state.selectedUserId, nextProps.getStrategistData[0].date);
        const availableDates = []
        if (nextProps.getStrategistData && nextProps.getStrategistData.length) {
          nextProps.getStrategistData.map(function (date) {
            availableDates.push(moment(date.date))
          })

        }
        this.setState({ availableDates, startDate: moment(nextProps.getStrategistData[0].date) })
      }
    } else {
      if (nextProps.getStrategistDataPhase === false) {
        this.setState({ isLoading: false, initDataLoading: false })
      }
    }

    if (nextProps.getRateTownData !== this.props.getRateTownData) {
      let townDataArr = nextProps.getRateTownData && nextProps.getRateTownData.data && nextProps.getRateTownData.data.towns
      let agentDataArr = nextProps.getRateTownData && nextProps.getRateTownData.data && nextProps.getRateTownData.data.agents
      let townRateStatus = nextProps.getRateTownData.is_rated
      let agentRateStatus = nextProps.getRateTownData.is_agent_rated
      this.setState({ listItemTown: townDataArr, listItemTownAllData: townDataArr, listItemAgent: agentDataArr, listItemAgentAllData: agentDataArr, townShowing: townRateStatus, agentShowing: agentRateStatus })
    }

    if (nextProps.updateClientTownRateData !== this.props.updateClientTownRateData) {
      this.props.getClientTownRateData()
      this.props.getRecommendedTowns()
    }

    if (nextProps.addAgentTownPhase == 'success') {
      this.props.clearPhase()
      this.props.getRecommendedTowns()
    }

    if (nextProps.getStrategistAvaillabilityPhase === true) {
      this.setState({ isLoading: false, initDataLoading: false })
      this.props.clearDatePhase()
      if (nextProps.getStrategistAvaillabilityData.dates.length) {
        const data = {}
        data.user_id = this.state.selectedUserId
        data.date = moment(nextProps.getStrategistAvaillabilityData.dates[0]).format("MM/DD/YYYY");
        // data.timezone = this.state.timezone
        data.timezone = _user.time_zone
        this.props.getAgentAvailability(data)
        //this.availability(this.state.selectedUserId,  nextProps.getStrategistAvaillabilityData.dates[0]);
        const availableDates = []
        if (nextProps.getStrategistAvaillabilityData.dates.length) {
          nextProps.getStrategistAvaillabilityData.dates.map(function (date) {
            availableDates.push(moment(date))
          })
        }
        this.setState({ availableDates, startDate: moment(nextProps.getStrategistAvaillabilityData.dates[0]) })
      }
    } else {
      if (nextProps.getStrategistAvaillabilityPhase === false) {
        this.setState({ isLoading: false, initDataLoading: false })
      }
    }

    if (nextProps.availabilityPhase === "success") {
      this.setState({
        initDataLoading: false
      })
    }
    if (nextProps.availabilityPhase === "error") {

    }
    if (nextProps.agentAppPhase === "success" && nextProps.agentAppData && nextProps.agentAppData.status === true) {
      const data2 = {}
      data2.user_id = _user.id
      data2.timezone = this.state.timezone
      this.props.getClientAppointments(data2)
      this.setState({
        selectedTime: '',
        selectedUserId: '',
        date: moment().format("MM/DD/YYYY"),
        startDate: moment(),
        timezone: '',
        appointment_type: '',
        isDateLoading: false,
        initDataLoading: false,
        modalIsOpen: true
      })
      this.closeCustomPopup();
      const data = {}
      data.user_id = _user.id
      this.props.getClientStrategist(data)
      this.props.getClientAgent(data)
      this.props.clearPhase()
    }
    if (nextProps.agentAppPhase === "error") {
      toast("Appointment Not Created.....", { autoClose: true })
      this.props.clearPhase()
    }
    if (nextProps.addClientStrategyAptPhase === "success" && nextProps.addClientStrategyAptData.status == true) {
      const data2 = {}
      data2.user_id = _user.id
      data2.timezone = this.state.timezone
      this.props.getClientAppointments(data2)
      this.setState({
        selectedTime: '',
        selectedUserId: '',
        date: moment().format("MM/DD/YYYY"),
        startDate: moment(),
        timezone: '',
        appointment_type: '',
        isDateLoading: false,
        initDataLoading: false,
        modalIsOpen: true
      })
      this.closeCustomPopup();
      const data = {}
      data.user_id = _user.id
      this.props.getClientStrategist(data)
      this.props.getClientAgent(data)
      this.props.clearPhase()
      this.props.clearFilterPhase()
    }
    if (nextProps.addClientStrategyAptPhase === "error") {
      toast("Appointment Not Created.....", { autoClose: true })
      this.props.clearFilterPhase()
    }
    if (nextProps.cancelAptPhase === "success") {
      toast("Appointment cancelled Successfully.....", { autoClose: true })
      const data2 = {}
      data2.user_id = _user.id
      data2.timezone = this.state.timezone
      this.props.getClientAppointments(data2)
      this.closeCustomPopup()
      this.onClose()
      this.setState({
        selectedTime: '',
        selectedUserId: '',
        date: moment().format("MM/DD/YYYY"),
        startDate: moment(),
        timezone: '',
        appointment_type: '',
        isDateLoading: false,
        initDataLoading: false,
        modalIsOpen: false,

      })
      this.props.clearFilterPhase()
    }
    if (nextProps.cancelAptPhase === "error") {
      toast("Appointment Not Cancel.....", { autoClose: true })
      this.props.clearFilterPhase()
    }
    if (nextProps.cancelStrAptPhase === "success") {
      toast("Appointment cancelled Successfully.....", { autoClose: true })
      const data2 = {}
      data2.user_id = _user.id
      data2.timezone = this.state.timezone
      this.props.getClientAppointments(data2)
      this.closeCustomPopup()
      this.setState({
        selectedTime: '',
        selectedUserId: '',
        date: moment().format("MM/DD/YYYY"),
        startDate: moment(),
        timezone: '',
        appointment_type: '',
        isDateLoading: false,
        initDataLoading: false,
        modalIsOpen: false
      })
      this.props.clearFilterPhase()
    }
    if (nextProps.cancelStrAptPhase === "error") {
      toast("Appointment Not Cancel.....", { autoClose: true })
      this.props.clearFilterPhase()
    }

    if (nextProps.clientChatPhase === "success" || nextProps.clientChatData.status === true) {
      this.setState({ chatData: nextProps.clientChatData.data })
    }

    if (nextProps && nextProps.impersonatePhase != this.props.impersonatePhase) {
      if (nextProps.impersonateData && nextProps.impersonateData.magic_link) {
        let magic_link = nextProps.impersonateData.magic_link
        // let hash = bcrypt.hashSync(magic_link)
        if (magic_link !== undefined) {
          // cookies.set('characteristicsAuth',hash )
          // cookies.set('characteristicsClient', this.state.userid )
          // cookies.set('characteristicsClient', this.state.userid, { path: '/' })
          window.location.href = `${SUBURBAN_HOST}/edit-match/characteristics/${magic_link}`
          // window.location.href = `http://localhost:8000/edit-match/characteristics/${magic_link}`
        }
      }
    }

    if (nextProps.addAgentTownPhase === "success" && nextProps.addAgentTownData.status === false) {
      this.notAgentOnTownClick(nextProps.addAgentTownData.status)
    }
    if (nextProps.user !== this.props.user) {
      let suburbsData = nextProps.user && nextProps.user.suburbs
      const nyCity = _.find(suburbsData, { suburb_name: "New York City" })
      if (nyCity) {
        this.setState({ checkClientNY: true })
      } else {
        this.setState({ checkClientNY: false })
      }
    }
  }

  notAgentOnTownClick(e) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deletealert-custom-ui allpoppadding piersandfonts">
            <div className="flexdiv">
              <button
                onClick={onClose}
                type="button"
                id="close_user_modal"
                className="close_img close_img--white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="../../img/close_btn.svg" />
              </button>
            </div>
            <p className="allpoppadding__para marg25 piersandfonts">Your Strategist can set you up for a town tour. Please reach out with any questions.</p>
            <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={() => {
              onClose()
            }}>Ok</button>
          </div>
        )
      }
    })
  }

  async userSignout(logout) {
    // const jwtToken = cookies.get('Authorization');
    // if (jwtToken) {
    //   const res = await axios({
    //     method: 'POST',
    //     url: `${process.env.API_HOSTNAME}/client/logout`,
    //     data: { 'token': jwtToken }
    //   })
    // }
    localStorage.clear();
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem("agent_id")
    this.props.handleSignOut();
    this.props.history.push('/login')
  }

  handleTownChange(selectedOption) {
    let { getTownData } = this.props
    let towns = selectedOption.split(",")
    _.forEach(towns, function (towndata) {
      var serv = _.findIndex(getTownData, function (town) {
        return town.name == towndata
      })
    }
    )
    var array = JSON.parse("[" + selectedOption + "]")
    this.setState({ townOptions: array })
  }

  addTownBtn(e) {
    let data = {
      ids: this.state.townOptions
    }
    this.props.addTowns(data)
  }

  chatHandleChange(e) {
    this.setState({ chat_text: e.target.value })
  }

  // eslint-disable-next-line space-before-blocks
  handleChange(event) {
    if (event.target.value === 'null') {
      return;
    }
    let role = ''
    this.setState({ appointment_type: '' })
    // if(this.state.selectedStrAgent.length > 0){
    //   role = 'agent'
    // }else{
    let scheduleArray = _.concat(this.props.strategistData, this.state.agentArray)
    // let scheduleArray = this.props.strategistData
    let data = _.filter(scheduleArray, function (o) {
      if (o) {
        let id = (o.role === 'strategist' || o.role === 'admin') ? o.id : o.agent_id
        return id === parseInt(event.target.value)
      }
    })
    role = (data[0].role === 'strategist' || data[0].role === 'admin') ? data[0].role : data[0].agent_role
    // }
    this.setState({ initDataLoading: true, selectedUserId: event.target.value, selectedRole: role, timezone: _user.time_zone })

    if (role == "strategist" || role == "admin") {
      this.setState({ initDataLoading: true })
      this.props.getClientStrategistData({ strategist_id: event.target.value, date: moment(this.state.currentDate._d).format("MM/DD/YYYY"), timezone: _user.time_zone })
      this.setState({ availableDates: [], times: [] })
      this.props.clearAgentDatePhase()
    } else {
      this.setState({ initDataLoading: true })
      const data = {
        user_id: event.target.value,
        date: moment(this.state.currentDate._d).format("MM/DD/YYYY"),
        timezone: _user.time_zone
      }
      this.props.getClientStrategistAvaillability(data)
      this.setState({ availableDates: [], times: [], timezone: _user.time_zone })
      this.props.clearAgentDatePhase()
    }
  }

  handleDateClick(date) {
    this.setState({ times: [], date: moment(date).format("MM/DD/YYYY"), startDate: moment(date), initDataLoading: true })
    let id = this.state.selectedUserId;
    if (this.state.selectedRole == "strategist" || this.state.selectedRole == "admin") {
      this.availability(id, date)
    } else {
      const data = {}
      data.user_id = this.state.selectedUserId
      data.timezone = _user.time_zone
      // data.date     = moment(date).format("MM/DD/YYYY")
      data.date = moment(date).format("YYYY-MM-DD")
      this.props.getAgentAvailability(data)
    }
  }

  afterOpenModal() {
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  afterTownAddOpen() {

  }

  closeTownModel() {
    this.setState({ addTownOpen: false, townOptions: [] })
    document.body.style.overflow = "visible"
  }

  closeCustomPopup = () => {
    $('.close-btn').click()
  }

  handleChangeTime(event) {
    this.setState({ selectedTime: event.target.value })
  }

  handleChangeApt(event) {
    this.setState({ appointment_type: event.target.value })
  }

  handleChangeOption(selectOpt) {
    this.setState({ appointment_type: selectOpt })
  }

  chatHandle(e) {
    this.setState({ chat: e.target.value })
  }

  strClick(str, e) {
    let strFullName = str.first_name + ' ' + str.last_name
    let strAvatar = str.avatar ? str.avatar : "../../img/default-user.svg"
    let strId = str.id
    this.setState({ chatStrategistId: strId, strategistAvatar: strAvatar, strategistName: strFullName })
  }

  strHandleChange(e) {
    this.setState({ strategist_note: e.target.value })
  }

  rateClick(rate, e) {
    this.setState({ rating: rate, err: '' })
  }

  feedbackChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  availability(id, date) {
    this.setState({ isDateLoading: true, initDataLoading: true })
    let data = {};
    data.user_id = id;
    data.date = moment(date ? date : this.state.date).format("YYYY-MM-DD");
    data.timezone = this.state.timezone;

    const queryString = new URLSearchParams({
      date: data.date,
      timezone: data.timezone
    })

    fetch(`${SUBURBANJUNGLE_API_HOST}/strategists/${data.user_id}/availability-times?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        return res.json();
      })
      .then(payload => {
        if (payload.status) {
          if (payload.timezone_type) {
            this.setState({ timezone_type: payload.timezone_type })
          }
          if (payload.data && payload.data.length > 0) {
            let timeArray = payload.data; //res.data.data;
            if (timeArray.length > 0) {
              this.handleSlider(timeArray, moment(date ? date : this.state.date).format("YYYY-MM-DD"));
            }
          }
          else {
            this.setState({ times: [], isDateLoading: false, initDataLoading: false })
          }
        }
        else {
          this.setState({ times: [] })
          alert("Please select different date");
        }
      })
      .catch(error => {
        throw error;
      });
  }

  handleSlider(timeArray, date) {
    //moment(h.activity_date).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")
    // console.log('moment.tz.guess()', moment.tz.guess())
    //console.log('moment.tz.guess()1', moment(new Date()).tz("EST"))
    setTimeout(function () { this.setState({ isDateLoading: false, initDataLoading: false }); }.bind(this), 500);
    let currentTimeArray = []
    let now = moment().format("MMMM DD, YYYY hh:mm a")
    if(this.state.timezone_type !== 'IST')
      now = moment(new Date()).tz(this.state.timezone_type).format("MMMM DD, YYYY hh:mm a")
    timeArray && timeArray.map((val, idx) => {
      const v = moment(date + ' ' + val).format("MMMM DD, YYYY hh:mm a")
      if(new Date(v).getTime() > new Date(now).getTime()){
        if (moment(this.state.matchDate).format("YYYY-MM-DD") == moment(this.state.startDate).format("YYYY-MM-DD")) {
          if (this.state.userTime !== val) {
            currentTimeArray.push(val)
          }
        } else {
          currentTimeArray.push(val)
        }
      }
    })
    this.setState({ times: currentTimeArray })
  }

  openChatBtn(e) {
    $(".chat_ul_scroll").animate({ scrollTop: $('.chat_ul_scroll').prop("scrollHeight") }, 1000)
    this.setState({ openChat: true })
    document.body.style.overflow = "hidden"
    // }

  }

  closeChatBar(e) {
    this.setState({ openChat: false })
    document.body.style.overflow = "visible"
  }

  chatChange(e) {
    this.setState({ chat: e.target.value })
  }

  changeTownStatus(e) {
    this.setState({ moveStatus: e.target.value })
  }

  moveTownClick(townId, agentId, townName, status, e) {
    this.setState({
      moveStatus: status,
      townOpenModel: true,
      moveTownName: townName,
      moveTownId: townId,
      moveAgentId: agentId,
    })
  }

  handleCommentChange(e) {
    this.setState({ "comment": e.target.value })
  }
  openCommentClick(townId, agentId, townName, status, e) {
    let obj = {
      client_id: parseInt(_user.id),
      town_id: parseInt(townId),
      type: "client"
    }
    axios({
      'method': 'POST',
      'url': HOSTNAME + '/client/get_leave_comments',
      'data': obj,
      // 'headers': { 'Authorization': jwtToken },
    })
      .then((res) => {
        if (res.data && res.data.status && res.data.data) {
          this.setState({
            moveStatus: status,
            openComment: true,
            moveTownName: townName,
            moveTownId: townId,
            commentsData: res.data.data
          })
        } else {
          this.setState({
            moveStatus: '',
            openComment: true,
            moveTownName: '',
            moveTownId: null,
            commentsData: []
          })
        }
      })
  }

  sendComment(e) {
    let { moveTownId, comment } = this.state
    //const jwtToken = cookies.get('Authorization')
    const err = {}
    if (comment === '' || comment.trim() === '') {
      err.comment = 'Please provide any comment/note.'
    } else {
      err.comment = ''
    }

    let obj = {
      client_id: parseInt(_user.id),
      town_id: parseInt(moveTownId),
      comment: comment,
      sender_id: parseInt(_user.id),
      type: 'text'
    }

    if (this.state.comment_id) {
      obj.id = this.state.comment_id
    }

    this.setState({ err })
    if (err.comment == '') {
      axios({
        'method': 'POST',
        'url': HOSTNAME + '/client/add_leave_comments',
        'data': obj,
        // 'headers': { 'Authorization': jwtToken },
      })
        .then((res) => {
          this.setState({
            moveStatus: '',
            openComment: false,
            moveTownName: '',
            moveTownId: null,
            commentsData: [],
            comment: '',
            comment_id: null
          })
          this.props.getRecommendedTowns()
        })
    }
  }

  editComment(comment_id, sender_id, comment, e) {
    this.setState({
      comment: comment,
      comment_id: comment_id
    })
  }

  closeCommentModel(e) {
    this.setState({
      openComment: false,
      comment: '',
      moveTownName: '',
      moveTownId: null,
    })
    this.props.getRecommendedTowns()
  }


  deleteComment(comment_id, e) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deletealert-custom-ui allpoppadding piersandfonts">
            <div className="flexdiv">
              <h4 className="popupheader__heading">Are you sure?</h4>
              <button
                onClick={onClose}
                type="button"
                id="close_user_modal"
                className="close_img close_img--white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="../../img/close_btn.svg" />
              </button>
            </div>
            <p className="allpoppadding__para marg25 piersandfonts">You want to delete this comment/note ?</p>
            <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={() => {
              this.handleClickDelete(comment_id)
              onClose()
            }}>Yes, Delete it!</button>
          </div>
        )
      }
    })
  }

  handleClickDelete(comment_id) {
    let obj = {
      comment_id: comment_id
    }
    axios({
      'method': 'POST',
      'url': HOSTNAME + '/client/delete_leave_comments',
      'data': obj,
      // 'headers': { 'Authorization': jwtToken },
    })
      .then((res) => {
        this.setState({
          moveStatus: '',
          openComment: false,
          moveTownName: '',
          moveTownId: null,
          commentsData: [],
          comment: '',
          comment_id: null
        })
      })
  }

  afterTownOpenModel() {

  }

  closeTownOpenModel() {
    this.setState({
      moveStatus: null,
      moveTownName: '',
      moveTownId: 0,
      moveAgentId: 0,
      townOpenModel: false,
      townOpenModelConfirm: false,
      townOpenModelTitle: "Move Town"
    })
  }


  confirmMoveTown(townId, agentId, moveStatus, e) {

    if (parseInt(moveStatus) === 0) {
      this.setState({
        townOpenModelConfirm: true,
        townOpenModelTitle: "Are You Sure?"
      })
    } else {
      this.moveTown(townId, agentId, moveStatus, e)
    }

  }

  moveTown(townId, agentId, status, e) {
    let data = {
      town_id: townId,
      client_id: this.state.userid,
      status: status,
      agent_id: agentId
    }

    this.props.removeTown(data)
    this.setState({
      initDataLoading: true,
      moveStatus: null,
      moveTownName: '',
      moveTownId: 0,
      moveAgentId: 0,
      townOpenModel: false,
      townOpenModelConfirm: false,
      townOpenModelTitle: "Move Town"      

    })
  }

  removeTown(townId, agentId) {
    let data = {
      town_id: townId,
      client_id: this.state.userid,
      status: 0,
      agent_id: agentId
    }
    this.props.removeTown(data)
    this.setState({ initDataLoading: true })
  }

  addTownRecClick(townId, e) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deletealert-custom-ui allpoppadding piersandfonts">
            <div className="flexdiv">
              <h4 className="popupheader__heading">Are you sure?</h4>
              <button
                onClick={onClose}
                type="button"
                id="close_user_modal"
                className="close_img close_img--white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="../../img/close_btn.svg" />
              </button>
            </div>
            <p className="allpoppadding__para marg25 piersandfonts">Do you want to remove this town from not interested?</p>
            <div className="flexbuttons">
              <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={onClose}
              >No</button>
              <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={() => {
                this.addTownInRec(townId, e)
                onClose()
              }}>Yes</button>
            </div>
          </div>
        )
      }
    })
  }

  addTownInRec(townId, e) {
    let data = {
      town_id: townId,
      client_id: this.state.userid,
      status: 1
    }
    this.props.removeTown(data)
    this.setState({ initDataLoading: true })
  }

  sendBtn(e) {
    const { chatStrategistId, chat, userid } = this.state
    if (chat && chat.replace(/\s/g, '').length !== 0) {
      socket.emit('client_strategist_chat', {
        message: chat,
        receiver_id: chatStrategistId,
        sender_id: userid,
        sender_type: "client",
        receiver_type: "strategist",
        client_id: userid,
        strategist_id: chatStrategistId
      })
      $(".chat_ul_scroll").animate({ scrollTop: $('.chat_ul_scroll').prop("scrollHeight") }, 1000)
      this.setState({ chat: '' })
      document.getElementById('my_textareaBox').style.height = '58px'
    }
  }

  //mobile number formatting
  formatNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  //mobile number formatting for link
  formatNumberForLink = (str) => {
    if (!str || str === '') return ""

    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3]
    }
    return null
  }


  openScheduleBar(strAgentData, event) {
    this.setState({ visible: true })
    // document.getElementById('pills-home-tab').click()
    if (strAgentData) {
      const data = {}
      data.user_id = strAgentData.agent_id
      data.timezone = _user.time_zone
      data.date = moment(this.state.currentDate._d).format("MM/DD/YYYY")

      let arr = []
      // let scheduleArray = _.concat(this.props.strategistData, this.props.agentData);
      let scheduleArray = this.props.strategistData
      let val = scheduleArray[0];
      if (strAgentData && strAgentData.agent_id || strAgentData && strAgentData.id) {
        val = strAgentData
        arr.push(strAgentData)
      }
      if (val) {
        let id = val.role === 'strategist' ? val.id : val.agent_id
        let role = val.role === 'strategist' ? val.role : val.agent_role

        if (val.role == "strategist" || val.role == "admin") {
          this.props.getClientStrategistData({ strategist_id: val.id, date: moment(this.state.currentDate._d).format("MM/DD/YYYY"), timezone: _user.time_zone })
          this.setState({ isLoading: true, availableDates: [], times: [], initDataLoading: true })
          this.props.clearAgentDatePhase()
        } else {
          const data = {
            user_id: val.agent_id,
            date: moment(this.state.currentDate._d).format("MM/DD/YYYY"),
            timezone: _user.time_zone
          }
          this.props.getClientStrategistAvaillability(data)
          this.setState({ isLoading: true, initDataLoading: true, availableDates: [], times: [] })
          this.props.clearAgentDatePhase()
        }
        this.setState({
          timezone: val.time_zone,
          selectedUserId: id,
          selectedStrAgent: arr,
          selectedRole: role
        })
      }
    }
  }

  onAptClick(aptdata, idx, event) {
    if (aptdata) {
      let currentDate = moment(aptdata.start_date)
      let id = aptdata.user_id.toString()
      //=========Start from here==============
      this.setState({ selectedUserId: id, selectedRole: aptdata.role, timezone: _user.time_zone, reSchedulLoading: true, err1: '' })
      if (aptdata.role == "strategist" || aptdata.role == "admin") {
        this.props.getClientStrategistData({ strategist_id: id, date: moment(this.state.currentDate._d).format("MM/DD/YYYY"), timezone: _user.time_zone })
        this.setState({ reSchedulLoading: true, availableDates: [], times: [] })
        this.props.clearAgentDatePhase()
      } else {
        const data = {
          user_id: id,
          date: moment(this.state.currentDate._d).format("MM/DD/YYYY"),
          timezone: _user.time_zone
        }
        this.props.getClientStrategistAvaillability(data)
        this.setState({ reSchedulLoading: true, availableDates: [], times: [], timezone: _user.time_zone })
        this.props.clearAgentDatePhase()
      }
      //=========End from here================

      setTimeout(function () {
        if (aptdata.role == "strategist" || aptdata.role == "admin") {
          this.availability(id, currentDate)
        } else {
          const data = {}
          data.user_id = this.state.selectedUserId
          data.timezone = _user.time_zone
          data.date = moment(currentDate).format("MM/DD/YYYY")
          this.props.getAgentAvailability(data)
        }
        this.setState({
          times: [],
          date: moment(currentDate).format("MM/DD/YYYY"),
          startDate: moment(currentDate)/*, initDataLoading:true*/,
          userTime: moment(aptdata.start_date).format("HH:mm"),
          appointment_type: aptdata.appointment_type,
          selectedRole: aptdata.role,
          selectedUserId: aptdata.user_id,
          reSchedulLoading: false,
          matchDate: moment(currentDate).format("MM/DD/YYYY")
        }, () => {
          $("#apt"+idx).click()
        })

        // modal-backdrop fade in
        $(".modal-backdrop.fade.in").addClass("backdrop-remove");

      }.bind(this), 4000)
    }
  }

  scheduledButton(event) {
    this.setState({
      selectedTime: '',
      selectedUserId: '',
      date: moment().format("MM/DD/YYYY"),
      startDate: moment(),
      timezone: '',
      appointment_type: '',
      isDateLoading: false,
      initDataLoading: false,
      secondDropDownVal: "New Appointment"

    })
  }

  mobileScheduledButton(val, e) {
    this.setState({
      selectedTime: '',
      selectedUserId: '',
      date: moment().format("MM/DD/YYYY"),
      startDate: moment(),
      timezone: '',
      appointment_type: '',
      isDateLoading: false,
      initDataLoading: false,
      secondDropDownBoolVal: !this.state.secondDropDownBoolVal,
      secondDropDownVal: val
    })
  }

  mobileUpcomingButton(val, e) {
    this.setState({
      secondDropDownBoolVal: !this.state.secondDropDownBoolVal,
      secondDropDownVal: val
    })
  }

  strChatClick(id, e) {
    this.setState({ chatStrategist: id })
  }

  upcomingButton(event) {
    this.setState({ secondDropDownVal: "Upcoming Appointment" })
  }

  upcomingClick(event) {
    this.setState({ modalIsOpen: false, userTime: '', matchDate: moment(), secondDropDownVal: "Upcoming Appointment", visible: true })
    // document.getElementById('pills-profile-tab').click()
  }

  upcomingSchBtn() {
    this.setState({ secondDropDownVal: "New Appointment" })
    // document.getElementById('pills-home-tab').click()
  }

  closeBtn(event) {
    this.setState({ classShowHide: false })
  }

  blankTownClick(e) {
    this.props.getTowns()
    this.setState({ addTownOpen: true })
    document.body.style.overflow = "hidden"
  }

  chrtBtn(e) {
    let data = {}
    data.client_id = this.state.userid
    data.notRenew = true
    this.props.impersonateUser(data)
    // cookies.set('characteristicsClient', this.state.userid, { path: '/' })
    // window.location.href = 'http://localhost:8000/match/characteristics'
  }

  authClick(event) {
    // const jwtToken = cookies.get('Authorization');
    // if (jwtToken) {
    //   const res = axios({
    //     method: 'POST',
    //     url: `${process.env.API_HOSTNAME}/client/logout`,
    //     data: { 'token': jwtToken }
    //   })
    // }
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth() + 2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });

    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem('agent_id')
    if (pre_Auth.role === "superadmin") {
      this.props.history.push({ 'pathname': "/administration/dashboard" })
      // this.props.history.push({ 'pathname': "/strategist/clients" })
      // this.props.history.push({'pathname' : "/dashboard" })
    }
    if (pre_Auth.role === "admin" || pre_Auth.role === "strategist") {
      this.props.history.push({ 'pathname': "/administration/dashboard" })
      // this.props.history.push({ 'pathname': "/strategist/clients" })
      // this.props.history.push({'pathname' : "/home/new-clients" })
    }
  }

  submitSchedulingData() {
    const err = {}
    const { selectedTime, selectedUserId, timezone, appointment_type, date, startDate } = this.state
    this.setState({ startDate: moment() })
    if (selectedUserId === '' || selectedUserId === "Select agent or strategist" || selectedUserId === undefined) {
      err.selectedUserId = 'Please select agent or strategist'
    }

    const appointment_type_name =
      appointment_type ?
        (appointment_type.name ? appointment_type.name : appointment_type)
      : null
    if (!appointment_type_name || appointment_type_name === "Select appointment type") {
      err.appointment_type = 'Please select a appointment type'
    }
    if (selectedTime === '' || selectedTime === "Time" || selectedTime.trim() === '') {
      err.selectedTime = 'Please select a time.'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      let data = {}
      data.time = selectedTime
      data.date = moment(startDate).format("YYYY-MM-DD");
      data.timezone = _user.time_zone
      data.appointment_type = appointment_type_name
      data.description = ""

      if (this.state.selectedRole == "strategist" || this.state.selectedRole == "admin") {
        data.appointment_id = 0
        data.client_id = _user.id
        data.strategist_id = selectedUserId
        // this.props.addStrategistApt(data)
        this.props.addClientStragistApt(data)
      } else {
        data.user_id = selectedUserId
        this.props.createAgentAppointment(data, "new_schedule")
      }

      let allStrAgent = _.concat(this.props.strategistData, this.state.agentArray);
      let userName = ''
      _.filter(allStrAgent, function (o) {
        if (o.id == selectedUserId || o.agent_id == selectedUserId) {
          userName = o.role === 'strategist' ? o.first_name + ' ' + o.last_name : o.agent_full_name
        }
      })
      this.setState({ userTime: selectedTime, bookingConfirmedDate: startDate, userName: userName, schduleOpenClose: false, visible: false })
      document.body.style.overflow = "visible"
    }
  }

  rescheduleButton(apt, event) {
    const err1 = {}
    let appointment_type = this.state.appointment_type
    const { selectedTime, selectedUserId, timezone, date, startDate } = this.state
    if (appointment_type && appointment_type == 'Initial strategy session') {
      appointment_type = 'Initial Strategy Session'
    }
    if (selectedTime === '' || selectedTime === "Time" || selectedTime.trim() === '') {
      err1.selectedTime = 'To reschedule an appointment, change the date and time'
    }
    this.setState({ err1 })

    if (!Object.keys(err1).length) {
      let data = {}
      data.time = selectedTime
      data.date = moment(startDate).format("YYYY-MM-DD");
      data.timezone = _user.time_zone
      data.description = ""
      data.client_id = _user.id
      data.appointment_type = appointment_type

      if (apt.role == "strategist" || apt.role == "admin") {
        data.appointment_id = apt.appointment_id
        data.strategist_id = selectedUserId
        this.props.addClientStragistApt(data)
      } else {
        data.user_id = selectedUserId
        data.event_id = apt.event_id
        this.props.createAgentAppointment(data, "re_schedule")
      }

      let allStrAgent = _.concat(this.props.strategistData, this.state.agentArray);
      let userName = ''
      _.filter(allStrAgent, function (o) {
        if (o.id == selectedUserId || o.agent_id == selectedUserId) {
          userName = o.role === 'strategist' ? o.first_name + ' ' + o.last_name : o.agent_full_name
        }
      })

      this.setState({ visible: false, userTime: selectedTime, bookingConfirmedDate: startDate, userName: userName, classShowHide: false, schduleOpenClose: false })
      document.body.style.overflow = "visible"
      $('#aptClose').click()
    }
  }

  cancelAppointment(apt, event) {
    if (apt.role === "strategist" || apt.role === "admin") {
      // cancelStrategistAppointment
      this.props.cancelStrategistAppointment(apt.appointment_id)
    } else if (apt.role === "agent") {
      this.props.cancelAppointment(apt.event_id)
    }
  }

  closeResearchBar(e) {
    this.setState({ openModel: false })
  }

  insightClick(town, e) {
    this.setState({ openModel: true, town_name: town.town_name })
    let data = {
      town_id: town.town_id,
      client_id: this.state.userid
    }
    this.props.getInsightData(data)
  }

  shareInfoToggle(agentData, e) {
    this.setState({ isLoadingData: true })
    const {agentArray, userid} = this.state
    const share_info = e.target.checked
    if(Array.isArray(agentArray) && agentArray.length >0 && agentData){
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            agentArray: prevState.agentArray.map((el) => {
              if (
                el.agent_id === agentData.agent_id &&
                el.user_id === agentData.user_id
              ) {
                el.share_info = !el.share_info;
              }
              return el;
            }),
            isAgentLocalUpdate: true,
          };
        },
        () => {
          let data = {
            agent_id: parseInt(agentData.agent_id),
            share_info,
          };
          this.props.shareClientInfo(data);
        }
      );
    }

  }

  agnetAutoAssign(townData, e) {
    this.setState({ agnetAutoAssignTown: !this.state.agnetAutoAssignTown }, () => {

      if (townData && townData.agent_id > 0) {
        let val = {
          agent_id: townData.agent_id,
          town_id: townData.town_id,
          type: 'toggle'
        }
        this.removeAgentClick(val)
      }
      if (townData && townData.agent_id == 0) {
        let data = {
          town_id: [townData.town_id],
          client_id: parseInt(this.state.userid),
        }
        this.addAgentClick(data)
      }
    })
  }

  shouldCancelStart(e) {
    // Prevent sorting from being triggered if target is input or button
    if (['input', 'button'].indexOf(e.target.tagName.toLowerCase()) !== -1) {
      return true; // Return true to cancel sorting
    }
  }

  agentClick(data, e) {
    this.setState({ shareInfoToggle: data.share_info })
  }

  rateTownClick(e) {
    this.props.getClientTownRateData()
    this.setState({ rateTownToggle: true })
  }

  contactStrategist(e) {
    $(".chat_ul_scroll").animate({ scrollTop: $('.chat_ul_scroll').prop("scrollHeight") }, 1000)
    this.setState({ rateTownToggle: false, openChat: true })
  }

  closeRateTown(e) {
    this.setState({ rateTownToggle: false, ratingType: "add", editingType: this.state.editingType })
  }

  messageClick = () => {
    this.setState({ msgToggle: true })
  }

  agentSearchClick(e) {
    this.setState({ agentSearch: e.target.value, agentDropDown: true })
  }

  dropDownAgentClick(e) {
    this.setState({ agentDropDown: !this.state.agentDropDown })
  }

  selectAgent(val, e) {
    this.setState({ agentDropDown: false, agentSearch: val.agent_full_name, agentClickable: true, chatSelectAgentId: val.agent_id, chatAgentName: val.agent_full_name })
    const receiver_id = val.agent_id
    if (this.state.userid != '' && receiver_id != '') {
      const data = {}
      data.sender_id = this.state.userid
      data.receiver_id = receiver_id
      this.props.getChatHistory(data)
      //this is for entery for id iside current user
      localStorage.setItem("agent_id", receiver_id)
    }
  }

  closeMsgToggleBar() {
    this.setState({ msgToggle: false })
  }

  sendChatMessage() {
    const receiver_id = this.state.chatSelectAgentId
    if (this.state.chat_text !== '' && receiver_id != '' && this.state.userid != '') {
      socket.emit('new_message', {
        sender_id: this.state.userid,
        receiver_id: receiver_id,
        sender_role: _user.role,
        type: "text",
        content: this.state.chat_text
      })
      this.setState({ chat_text: '' })
    }
  }

  removeAgentClick(val, e) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="deletealert-custom-ui allpoppadding piersandfonts">
            <div className="flexdiv">
              <h4 className="popupheader__heading">Are you sure?</h4>
              <button
                onClick={onClose}
                type="button"
                id="close_user_modal"
                className="close_img close_img--white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="../../img/close_btn.svg" />
              </button>
            </div>
            <p className="allpoppadding__para marg25 piersandfonts">Once you click “yes”, the assigned partner agent will be removed and a new one may be assigned. If you already toured or are in touch with the assigned partner agent and want to continue working with them, select the “x”.</p>
            <div className="text-right">
              {/* <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={onClose}>No</button> */}
              <button className="btn btn__green btn__flat btn__flat__lg w__180" onClick={() => {
                this.removeClientAgent(val)
                onClose()
              }}>Yes</button>
            </div>
          </div>

        )
      }
    })
  }

  addAgentClick(val, e) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deletealert-custom-ui allpoppadding piersandfonts">
            <div className="flexdiv">
              <h4 className="popupheader__heading">Are you sure?</h4>
              <button
                onClick={onClose}
                type="button"
                id="close_user_modal"
                className="close_img close_img--white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="../../img/close_btn.svg" />
              </button>
            </div>
            <p className="allpoppadding__para marg25 piersandfonts">Once you click “yes”, a partner agent will be assigned to reach out to you.</p>
            <div className="text-right">
              {/* <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={onClose}>No</button> */}
              <button className="btn btn__green btn__flat btn__flat__lg w__180" onClick={() => {
                this.addAgentOnTownClick(val)
                onClose()
              }}>Yes</button>
            </div>
          </div>

        )
      }
    })
  }

  addAgentOnTownClick(data) {
    $(".btn-warning").click()
    this.setState({ initDataLoading: true })
    this.props.addAgentOnTown(data)
  }


  removeClientAgent(val) {
    $(".btn-warning").click()
    let data = {
      client_id: parseInt(this.state.userid),
      agent_id: parseInt(val.agent_id),
      town_id: parseInt(val.town_id)
    }
    if (val.type !== undefined) {
      data.type = val.type
    }
    this.props.removeAgentFromTown(data)
  }

  townClickButton(data, e) {
    this.setState({ editingType: "town" })
  }

  agentClickButton(data, e) {
    this.setState({ editingType: "agent" })
  }

  editRateAgentAndTown(e) {
    this.setState({ ratingType: "edit" })
  }

  cancelRateAgentAndTown(e) {
    this.setState({ ratingType: "add", editingType: "town", listItemTown: this.state.listItemTownAllData, listItemAgent: this.state.listItemAgentAllData })
  }

  orderListTown({ oldIndex, newIndex }) {
    this.setState(({ listItemTown }) => ({
      listItemTown: arrayMove(listItemTown, oldIndex, newIndex)
    }))
  }

  orderListAgent({ oldIndex, newIndex }) {
    this.setState(({ listItemAgent }) => ({
      listItemAgent: arrayMove(listItemAgent, oldIndex, newIndex)
    }))
  }

  remove(town) {
    let rateAllTownArr = this.state.listItemTown && this.state.listItemTown.map((t) => {
      return { ...t, status: town.town_id === t.town_id ? 0 : (t.status === 0 ? 0 : 1) }
    })
    let rateDateFilter = _.filter(rateAllTownArr, (r) => {
      return r.status === 1
    })
    this.setState({ listItemTown: rateDateFilter })
  }

  deleteAgent(agent) {
    let rateAllAgentArr = this.state.listItemAgent && this.state.listItemAgent.map((a) => {
      return { ...a, id: agent.id === a.id ? 0 : (a.id === 0 ? 0 : a.id) }
    })
    let rateDateFilter = _.filter(rateAllAgentArr, (r) => {
      return r.id !== 0
    })
    this.setState({ listItemAgent: rateDateFilter })
  }

  ratedTownClick(e) {
    this.setState({ ratingType: "edit", editingType: "town" })
  }

  ratedAgentClick(e) {
    this.setState({ ratingType: "edit", editingType: "agent" })
  }

  dragDropSave(whereFrom, e) {
    let data = {}
    if (whereFrom === "town") {
      let town_ids = []
      let is_rated = []
      let remove_ids = []
      this.state.listItemTown && this.state.listItemTown.map((town, ind) => {
        town_ids.push(town.town_id)
      })
      var diff = _.differenceWith(this.state.listItemTownAllData, this.state.listItemTown, _.isEqual)
      diff && diff.map((town, ind) => {
        remove_ids.push(town.town_id)
      })
      data.town_ids = town_ids
      data.remove_ids = remove_ids
      data.is_rated = this.state.townShowing === false ? 0 : 1
    } else if (whereFrom === "agent") {
      let agent_ids = []
      let agent_remove_ids = []
      this.state.listItemAgent && this.state.listItemAgent.map((agent, ind) => {
        agent_ids.push(agent.id)
      })
      var diff = _.differenceWith(this.state.listItemAgentAllData, this.state.listItemAgent, _.isEqual)
      diff && diff.map((agent, ind) => {
        agent_remove_ids.push(agent.id)
      })
      data.agent_ids = agent_ids
      data.agent_remove_ids = agent_remove_ids
      data.is_agent_rated = this.state.agentShowing === false ? 0 : 1
    }
    this.props.updateClientTownRatingData(data)
    this.setState({ ratingType: "add", editingType: "town" })
  }

  menuIconbutton(e) {
    this.setState({ menuIconDrawer: true })
    document.body.style.overflow = "hidden"
  }

  closeMenuButton(e) {
    this.setState({ menuIconDrawer: false })
  }

  scheduleButtton(e) {
    this.setState({ schduleOpenClose: !this.state.schduleOpenClose }, () => {
      if (this.state.schduleOpenClose) {
        document.body.style.overflow = "hidden"
        document.body.style.maxHeight = "100vh"
        document.body.style.height = "100vh"
      } else {
        document.body.style.overflow = "visible"
        document.body.style.maxHeight = "auto"
        document.body.style.height = "auto"
      }
    })
  }

  onSelectClick(val, e) {
    this.setState({ dropDownBoolVal: !this.state.dropDownBoolVal, dropDownVal: val })
  }

  onSelectScheduleClick(val, e) {
    this.setState({ secondDropDownBoolVal: !this.state.secondDropDownBoolVal, secondDropDownVal: val })
  }

  onCloseCheckModal() {
    this.setState({ openStrategistModel: false, strategist_note: '', selectStrategistData: [] })
  }

  removeStrategistClick(strData, e) {
    this.setState({ openStrategistModel: true, selectStrategistData: strData })
  }

  removeStrategist(e) {
    if (this.state.selectStrategistData) {
      this.setState({ openStrategistModel: false })
      $(".btn-str_warning").click()
      let authorization = cookies.get('Authorization')
      if (this.state.selectStrategistData && this.state.selectStrategistData.id) {
        let data = {
          strategist_id: this.state.selectStrategistData.id,
          client_id: _user.id,
          strategist_remove_note: this.state.strategist_note
        }
        axios({
          method: 'POST',
          url: HOSTNAME + '/strategist/remove_strategist_from_client_profile',
          'headers': { 'Authorization': authorization },
          data: data
        }).then((res) => {
          if (res && res.data && res.data.status === true) {
            const data = {}
            data.user_id = _user.id
            this.props.getClientStrategist(data)
          }
        })
      }
    }
  }

  feedbackClick(e) {
    this.setState({ feedbackModelOpen: !this.state.feedbackModelOpen })
  }

  feedbackCloseClick(e) {
    this.setState({ rating: 0, feedbackText: "", feedbackModelOpen: false, err: {} })
  }

  feedbackSubmit(e) {
    let err = {}
    if (this.state.rating === 0) {
      err.rating = "Please select a star from above"
    }
    this.setState({ err })
    let authorization = cookies.get('Authorization')
    if (!Object.keys(err).length) {
      if (_user.id) {
        let data = {
          rating: this.state.rating,
          client_id: _user.id,
          feedbackNote: this.state.feedbackText
        }
        axios({
          method: 'POST',
          url: HOSTNAME + '/client/send_feedback',
          'headers': { 'Authorization': authorization },
          data: data
        }).then((res) => {
          if (res && res.data && res.data.status) {
            this.setState({ rating: 0, feedbackText: "", feedbackModelOpen: false })
          }
        })
      }
    }
  }

  handleKeyDown = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }
  checkTimeContinuous(timeList, index) {
    // if first element is present return true as continues
    if (index == 0) {
      return true;
    }
    const currentTime = timeList[index];
    const previousTime = timeList[index - 1];
    // check curent time and next time to continuty
    return moment(currentTime, "HH:mm").format("HH:mm") === moment(previousTime, "HH:mm").add(15, 'minutes').format("HH:mm");
  }
  // filter array and return continue time
  filterTwoHoursDifference(timeList) {
    const timeSlots = [];
    let response = [];
    // convert time into slots with continue times
    timeList.length > 0 && timeList.map((time, index) => {
      // check current time and next time is same them return
      if (this.checkTimeContinuous(timeList, index)) {
        response.push(time);
      } else {
        // if continutiy is brack create new array for continues time
        timeSlots.push(response);
        response = [time];
      }
    });
    timeSlots.push(response);
    // filter time with 2 hours using last element from slots
    const times = timeSlots.length > 0 && timeSlots.map(timeSlot => {
      if (timeSlot.length > 0) {
        const lastTime = timeSlot[timeSlot.length - 1] ?
          // make last time by add 2 hours and 15 minutes
          moment.utc(timeSlot[timeSlot.length - 1], 'H:mm').subtract(2, 'hours').format('HH:mm') :
          ''
        return _.filter(timeSlot, startTime => lastTime >= startTime)
      }
    })
    // return total filter time list for show availablity
    const availabilityTimes = []
    times.length > 0 && map(_.compact(times), time => {
      if (time) {
        availabilityTimes.push(...time)
      }
    })
    return availabilityTimes
  }

  render() {
    const {
      user,
      strategistData,
      agentData,
      clientAptData,
      availabilityData,
      clientChatData,
      getTownData,
      recommendedTownData,
      insightData,
      chatHistoryData
    } = this.props

    // let allTotalTowns = _.concat(this.state.totalTownArray, this.state.totalRecommendedTowns)
    let unqAllTown = _.uniqBy(this.state.totalRecommendedTowns, 'town_id');
    let concatAllTown = _.concat(unqAllTown, defaultTotalTown)
    let totalTownArray = _.orderBy(concatAllTown, ['town_name'], ['asc']);
    let strData = _.uniqBy(strategistData, 'id');
    let agentDataArray = _.uniqBy(this.state.agentArray, 'agent_id');
    // let scheduleArray = _.concat(strData, agentDataArray);
    let scheduleArray = strData;
    let appointment = ''
    let upcomingApt = _.orderBy(clientAptData.data, ['start_date'], ['asc'])

    let recommendedCount = _.filter(totalTownArray, function (o) {
      return o.status == 1;
    })

    let notReadyTourCount = _.filter(totalTownArray, function (o) {
      return o.status == 3;
    })
    let notInterestedTown = _.filter(totalTownArray, function (o) {
      return o.status == 0;
    })

    if (user && user.user && user.user.role !== "client") {
      this.setState({ 'user': user })
      return (
        <Redirect to={`/login`} />
      )
    }

    let agentArrAlpha = _.orderBy(agentDataArray, ['agent_full_name'], ['asc']);
    let agentSearchData = []
    if (agentArrAlpha) {
      agentSearchData = agentArrAlpha.filter(
        (data) => {
          return (
            (data.agent_full_name.toLowerCase().indexOf(this.state.agentSearch.toLowerCase())) !== -1
          );
        }
      );
    }

    let cData = [];
    if (strategistData && strategistData.length > 0) {
      cData = {
        showMsg: true,
        userid: this.state.userid,
        created_date: "2018-07-12T05:41:59.000Z",
        message: "Hi there! Let me know if you have any questions?",
        receiver_id: this.state.userid,
        role: "strategist",
        sender_avatar: this.state.strategistAvatar,
        sender_full_name: this.state.strategistName,
        sender_id: this.state.chatStrategistId
      }
    }

    let concatChat = _.concat(this.state.chatData, cData)
    let allChatData = _.orderBy(concatChat, ['created_date'], ['desc'])

    // if(this.state.openChat || this.state.addTownOpen || this.state.openModel || this.state.menuIconDrawer){
    //   document.body.style.overflow = "hidden"
    // }else{
    //   document.body.style.overflow = "auto"
    // }

    let options = []
    getTownData && getTownData.map(function (data, key) {
      options.push({ label: data.name, value: data.id })
    })

    appointment = config.strategistAppointment

    // if (this.state.selectedRole === 'strategist' || this.state.selectedRole === 'admin') {
    //   // appointment = config.strategistAppointment.map((val, index) => {
    //   //   return (
    //   //     <option key={index} value={val.name}>{val.name}</option>
    //   //   )
    //   // });
    //   appointment = config.strategistAppointment
    // } else {
    //   // appointment = config.clientAgentAppointment.map((val, index) => {
    //   //   return (
    //   //     <option key={index} value={val.name}>{val.name}</option>
    //   //   )
    //   // });
    //   appointment = config.clientAgentAppointment
    // }

    let strDefault = [];
    if (strategistData && strategistData.length > 0) {
      strDefault = {
        showMsg: true,
        userid: this.state.userid,
        created: "2015-07-12T05:41:59.000Z",
        content: "Hi there! Let me know if you have any questions?",
        receiver_id: this.state.userid,
        role: "strategist",
        sender_avatar: this.state.strategistAvatar,
        sender_full_name: this.state.strategistName,
        sender_id: this.state.chatStrategistId
      }
    }

    let finalClientChatData = _.concat(this.state.clientChatWithAgentData, strDefault)
    let chatClientAgentHistory = []
    if (localStorage.getItem('agent_id')) {
      chatClientAgentHistory = _.orderBy(finalClientChatData, ['created'], ['desc'])
    }
    let timeSlots = availabilityData && availabilityData.data && availabilityData.data.slot
    let times = ""


    // check condition for appointment for initail town towur and agent type
    if (get(this.state, "appointment_type.value", "") === "Initial Town Tour" && _.find(scheduleArray, { agent_role: "agent", agent_id: parseInt(this.state.selectedUserId) })) {
      // check appointmnet is on today so that we can hide above from current time
      if (moment().format("YYYY-MM-DD") === moment(this.state.startDate).format("YYYY-MM-DD")) {
        // remove from current time
        timeSlots = _.filter(timeSlots, startTime => moment().format("HH:mm") <= startTime)
      }
      timeSlots = this.filterTwoHoursDifference(timeSlots)
      times = timeSlots.map((slotval, indexslot) => {
        return <option key={indexslot} value={slotval}>{timeConverter(slotval)}</option>
      });
    } else {
      // else old logic
      if (availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0) {
        times = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => {
          if (moment(this.state.matchDate).format("YYYY-MM-DD") == moment(this.state.startDate).format("YYYY-MM-DD")) {
            if (this.state.userTime !== slotval) {
              return <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
            }
          } else {
            return <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
          }
        });
      } else {
        times = this.state.times && this.state.times.length > 0 && this.state.times.map((slotval, indexslot) => (
          <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
        ));
      }
    }

    const logoFilename = `./img/suburban_logo.png`

    return (
      <div className="clients__changes__parent">
        {
          (pre_Auth) ?
            <div className="auth-name">You are impersonating {'( ' + _user.first_name + ' ' + _user.last_name + ' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
            : ''
        }
        {this.state.initDataLoading === true ?
          <div className="loader__agent" >
            <img src="../../img/loader2.svg" />
          </div>
          : ''}

        {this.state.reSchedulLoading === true ?
          <div className="loader__agent loader__agent_z_index" >
            <img src="../../img/loader2.svg" />
          </div>
          : ''}
        <ToastContainer autoClose={3000} />
        <div className="client__home--page">
          <div className="container-fluid">
            <div className="row">
              <div className="strategist_side col-md-6 col-sm-6 col-xs-12 tabletviewmargin">
                <div>
                  <header className="desktopmenu">
                    <nav id="navbar-example2" className="navbar navbar-light bg-light dec__marg--btm">
                      <div className="logo-header">
                        <img src="/img/logo.svg" alt="Suburban Jungle" /> {/*<span>| &nbsp;&nbsp; Edit Profile</span>*/}
                      </div>
                      <div className="secondary__navbar__header--navigation m__l--auto">
                        {/* <h6 className="feedback__button--client" onClick={this.feedbackClick.bind(this)}>Feedback</h6>
                          <span className="desktopchatebuttn">
                            {
                              (this.state.agentArray && this.state.agentArray.length > 0) &&
                              <Link to={`/client-chat-with-agent`} className="msg__agent--greenbtnmob">
                                Chat with Agent</Link>
                            }
                          </span> */}
                        <div className="secondary__cta">
                          {/*<button className="btn btn__feedback" onClick={this.feedbackClick.bind(this)}>Feedback</button>*/}
                          <button className="btn btn__schedule__appointment" onClick={this.scheduleAppointmentDrawer}>Schedule Appointment</button>
                          {
                            (this.state.agentArray && this.state.agentArray.length > 0) &&
                            <Link to={`/client-chat-with-agent`} className="btn btn__chat-with-agent">
                              Chat with Agent</Link>
                          }
                        </div>
                        {/* <div>
                      <Link to={`/client-chat-center`}  className="navigation__links btn__grey client_sms--btn">
                          Message Agent</Link>
                      </div> */}
                        <div className="avtar__dropdown">
                          <div className="dropdown costom-dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                              {/* <span className="profile-avtar">
                                {
                                  (user && user.avatar) ?
                                    <img src={user && user.avatar ? user.avatar : 'img/login-bg-img.png'}/>
                                  :
                                  <div
                                    className="strategist--profile--item"
                                    data-toggle="modal"
                                    >
                                    <span className="no-ImgBox no__img">{user && user.first_name && user.first_name.charAt(0)}</span>
                                  </div>
                                }

                              </span> */}
                              <span className="client__avatar avatar pull-left">
                                {
                                  (user && user.avatar) ?
                                    <img src={user && user.avatar ? user.avatar : 'img/login-bg-img.png'} />
                                    :
                                    <span className="avatar-string">{user && user.first_name && user.first_name.charAt(0)}</span>
                                }
                              </span>
                              <span className="profile-name">{user && user.first_name && user.last_name ? user.first_name + " " + user.last_name : ''}</span><br />
                              <span className="profile-email">{user && user.role ? user.role : ''}</span>
                              <img className="avart__arrow--icon" src="./img/profile-arrow.svg" /></a>
                            <ul className="dropdown-menu">
                              <li><Link to={`/client-my-profile`}>My Profile</Link></li>
                              {/*<li className="edit__suburbs" onClick={this.chrtBtn.bind(this)}><a>Edit Suburb Characteristics</a></li>*/}
                              <li><a href="javascript:void(0)" onClick={this.userSignout.bind(this)} className="logout-agent-client">Logout</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </header>

                  <div className={pre_Auth ? "stickyClient" : "fixedhead"}>
                    
                    <div className="tabletViewheader">

                      <nav className="navbar navbar-default navbar-edit">

                        <div className="navbar-header navbar__header--mobile" style={{ width: '100%' }}>

                          <Link to={`/dashboard`}>
                            <img className="mobile-sidebar-resize" src="img/logo.svg"/>
                          </Link>

                          <div className="right__contents">
                            {/* <img src="./img/searchiconmobile.svg" className="searchicon" />
                            <img src="./img/notificaton.svg" className="notificationicon" /> */}
                            <button className="mobilemenuopen" type="button" onClick={this.menuIconbutton.bind(this)}>
                              <img src="./img/mobiconmenu.svg" />
                            </button>
                          </div>
                        </div>

                        <div className={this.state.menuIconDrawer ? "mobile__drawer" : "mobile__drawer mobile__drawer--close"}>
                          <div className="mobileclose"><img src="./img/mobileclose.svg" onClick={this.closeMenuButton.bind(this)} /></div>
                          <ul>
                            <li><Link to={`/client-my-profile`}>My Profile</Link></li>
                            {
                              (this.state.agentArray && this.state.agentArray.length > 0) &&
                              <li><Link to={`/client-chat-with-agent`}>
                                Chat with Agent</Link></li>
                            }
                            <li><span style={{cursor: 'pointer'}} onClick={this.scheduleAppointmentDrawer}>Schedule Appointment</span></li>
                            {/*<li className="edit__suburbs" onClick={this.chrtBtn.bind(this)}><a>Edit Suburb Characteristics</a></li>*/}
                            <li><a href="javascript:void(0)" onClick={this.userSignout.bind(this)} className="logout-agent-client">Logout</a>
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </div>

                  <h1 className="section--label tab__section--label">Welcome to the Jungler</h1>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="strategist__profile">
                        <h3 className="strategist--title">Your Strategist(s):</h3>
                        {
                          strategistData && strategistData.length > 0 && strategistData.map((str, strIdx) => {
                            if (str && str.role === 'strategist') {
                              return (
                                <div key={strIdx} className="strategist--profile--list">
                                  <div className="strategist--profile--item">
                                    {str.avatar ?
                                      <img
                                        data-toggle="modal"
                                        data-target={"#profileModalCenter" + strIdx}
                                        className="strategist--img"
                                        src={str.avatar} />
                                      :
                                      <div className="strategist--profile--item"
                                        data-toggle="modal"
                                        data-target={"#profileModalCenter" + strIdx}
                                      >
                                        <span className="no-ImgBox">{str.first_name.charAt(0)}</span>
                                      </div>
                                    }
                                  </div>
                                  {/*Profile Modal For Strategists*/}
                                  <div className="profile__modal">
                                    <div className="modal fade" id={"profileModalCenter" + strIdx} tabIndex="-1" role="dialog" aria-labelledby="profileModalCenterCenterTitle" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                          <button type="button" className="btn close-btn" data-dismiss="modal">
                                            <img data-dismiss="modal" src="./img/close_btn.svg" className="close32" />
                                          </button>
                                          <div className="modal-body modalbody24">
                                            <div className="user--profile">
                                              {str.avatar ?
                                                <img src={str.avatar} />
                                                :
                                                <span className="no-ImgBox">{str.first_name.charAt(0)}</span>
                                              }
                                            </div>
                                            <h1 className="profile-title newheadergolbal">{str.first_name + " " + str.last_name}</h1>
                                            <h3 className="profile-sub--title">{str.designation}</h3>
                                            <div className="email__contact">
                                              <a href={str && str.email ? 'mailto:' + str.email : ''}><img src="./img/noun_Email.svg" />{str.email}</a>
                                              {
                                                (str.phone_mobile !== '') ?
                                                  <a href={str && str.phone_mobile ? 'tel:' + str.phone_mobile : str && str.phone_mobile ? str.phone_mobile : ''}><img src="./img/noun_call.svg" />{str.phone_mobile}</a>
                                                  : ""
                                              }
                                            </div>
                                            {
                                              (str.description !== '') ?
                                                <ShowMore
                                                  lines={4}
                                                  more='View more'
                                                  less='View less'
                                                  anchorClass='ShowMoreToggle'
                                                >
                                                  <p>{str.description}</p>
                                                </ShowMore>
                                                : ""
                                            }

                                            <button
                                              type="button"
                                              data-dismiss="modal"
                                              className="btn btn__green btn-block profile--button"
                                              onClick={() => {
                                                this.openScheduleBar(this, str)
                                              }}
                                              // onClick={() => this.openScheduleBar.bind(this, str)}
                                            >
                                              SCHEDULE
                                            </button>
                                            <button style={{ display: "none" }} data-dismiss="modal" className="btn-str_warning"></button>

                                            {
                                              str && str.suburbs && str.suburbs.map((sub, subIdx) => {
                                                if (sub.id === 1 && this.state.checkClientNY) {
                                                  return (
                                                    <button
                                                      key={subIdx}
                                                      type="button"
                                                      data-dismiss="modal"
                                                      onClick={this.removeStrategistClick.bind(this, str)}
                                                      className="popup__thanks--btn remove__strategist">
                                                      I would prefer to work with another Strategist
                                                    </button>
                                                  )
                                                }
                                              })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*Profile Modal END*/}
                                </div>
                              )
                            }
                          })
                        }
                      </div>
                      {
                        strategistData && strategistData.length > 0 &&
                        <button onClick={this.openChatBtn.bind(this)} className={(this.state.openChat != true) ? "suggest__btn help__find--btn" : "suggest__btn for__chat"}><img src="./img/idea.svg" /> Help me find more towns - Message Strategist
                        </button>
                      }
                    </div>
                  </div>

                  {/*Towns Seciton START*/}
                  <section className="towns__tab--sectino">
                    <ul className="nav nav-pills mb-3 ul__marg ul__scroll hidelist__tab" id="pills-tab" role="tablist">
                      <li className="nav-item minus__gap show active">
                        <a className="nav-link" id="pills-towns-tab" data-toggle="pill" href="#pills-towns" role="tab" aria-controls="pills-towns" aria-selected="true">FAVORITES AND READY TO EXPLORE! ({recommendedCount && recommendedCount.length - 1})</a>
                      </li>
                      <li className="nav-item minus__gap">
                        <a className="nav-link" id="pills-tour-tab" data-toggle="pill" href="#pills-towns-tour" role="tab" aria-controls="pills-towns-tour" aria-selected="false">INTERESTED BUT NOT READY TO TOUR YET! ({notReadyTourCount && notReadyTourCount.length})</a>
                      </li>
                      <li className="nav-item minus__gap">
                        <a className="nav-link" id="pills-town-not" data-toggle="pill" href="#pills-not" role="tab" aria-controls="pills-not" aria-selected="false">NOT INTERESTED ({notInterestedTown && notInterestedTown.length})</a>
                      </li>
                      <li className="nav-item minus__gap">
                        <a className="nav-link" id="pills-agent-tab" data-toggle="pill" href="#pills-agent" role="tab" aria-controls="pills-agent" aria-selected="false">AGENTS ({this.state.agentArray && this.state.agentArray.length ? this.state.agentArray && this.state.agentArray.length : 0})</a>
                      </li>
                      {/* <li className="nav-item minus__gap">
                        <a className="nav-link" id="pills-locals-tab" data-toggle="pill" href="#pills-locals" role="tab" aria-controls="pills-locals" aria-selected="false">LOCALS ({this.state.localArray && this.state.localArray.length ? this.state.localArray && this.state.localArray.length : 0})</a>
                      </li> */}
                      {/*<li className="nav-item">
                        <a className="nav-link" id="pills-business-tab" data-toggle="pill" href="#pills-business" role="tab" aria-controls="pills-business" aria-selected="false">BUSINESS DEVELOPMENT ({this.state.businessArray && this.state.businessArray.length ? this.state.businessArray && this.state.businessArray.length: 0})</a>
                      </li>*/}

                    </ul>

                    {/* mobile dashboard dropdown open */}
                    <div className="mobview__dropdown--select" style={{ position: 'relative' }}>
                      {/* <select name="selectedUserId" className="selectinputs__global letterspacing__one">
                        <option onClick={this.onSelectClick.bind(this, `recommendedCount`)}>
                          FAVORITES AND READY TO EXPLORE! ({ recommendedCount && recommendedCount.length - 1 })
                        </option>
                        <option onClick={this.onSelectClick.bind(this, `INTERESTED BUT NOT READY TO TOUR YET! (${ notReadyTourCount && notReadyTourCount.length})`)}>
                          INTERESTED BUT NOT READY TO TOUR YET! ({ notReadyTourCount && notReadyTourCount.length })
                        </option>
                        <option onClick={this.onSelectClick.bind(this, `NOT INTERESTED (${notInterestedTown && notInterestedTown.length})`)}>
                          NOT INTERESTED ({ notInterestedTown && notInterestedTown.length })
                        </option>
                        <option onClick={this.onSelectClick.bind(this,`AGENTS (${this.state.agentArray && this.state.agentArray.length ? this.state.agentArray && this.state.agentArray.length : 0})`)}>
                          AGENTS ({this.state.agentArray && this.state.agentArray.length ? this.state.agentArray && this.state.agentArray.length:0})
                        </option>
                        <option onClick={this.onSelectClick.bind(this, `LOCALS (${ this.state.localArray && this.state.localArray.length ? this.state.localArray && this.state.localArray.length : 0 })`)}>
                          LOCALS ({this.state.localArray && this.state.localArray.length ? this.state.localArray && this.state.localArray.length : 0})
                        </option>
                      </select> */}
                      <button className={this.state.dropDownBoolVal ? "agentclient__btn--drop agentclient__btn--dropfocus" : "agentclient__btn--drop"} onClick={this.onSelectClick.bind(this, this.state.dropDownVal)}>{this.state.dropDownVal === "recommendedCount" ? `FAVORITES AND READY TO EXPLORE! (${recommendedCount && recommendedCount.length - 1})` : this.state.dropDownVal}</button>
                      {
                        (this.state.dropDownBoolVal) &&
                        <div className={this.state.dropDownBoolVal ? "agentclient__dropdownsshow agentclient__dropdowns" : "agentclient__dropdowns"}>
                          <ul>
                            <li onClick={this.onSelectClick.bind(this, `recommendedCount`)} className="nav-item minus__gap show active">
                              <a className="nav-link" id="pills-towns-tab" data-toggle="pill" href="#pills-towns" role="tab" aria-controls="pills-towns" aria-selected="true">FAVORITES AND READY TO EXPLORE! ({recommendedCount && recommendedCount.length - 1})</a>
                            </li>
                            <li onClick={this.onSelectClick.bind(this, `INTERESTED BUT NOT READY TO TOUR YET! (${notReadyTourCount && notReadyTourCount.length})`)} className="nav-item minus__gap">
                              <a className="nav-link" id="pills-tour-tab" data-toggle="pill" href="#pills-towns-tour" role="tab" aria-controls="pills-towns-tour" aria-selected="false">INTERESTED BUT NOT READY TO TOUR YET! ({notReadyTourCount && notReadyTourCount.length})</a>
                            </li>
                            <li onClick={this.onSelectClick.bind(this, `NOT INTERESTED (${notInterestedTown && notInterestedTown.length})`)} className="nav-item minus__gap">
                              <a className="nav-link" id="pills-town-not" data-toggle="pill" href="#pills-not" role="tab" aria-controls="pills-not" aria-selected="false">NOT INTERESTED ({notInterestedTown && notInterestedTown.length})</a>
                            </li>
                            <li onClick={this.onSelectClick.bind(this, `AGENTS (${this.state.agentArray && this.state.agentArray.length ? this.state.agentArray && this.state.agentArray.length : 0})`)} className="nav-item minus__gap">
                              <a className="nav-link" id="pills-agent-tab" data-toggle="pill" href="#pills-agent" role="tab" aria-controls="pills-agent" aria-selected="false">AGENTS ({this.state.agentArray && this.state.agentArray.length ? this.state.agentArray && this.state.agentArray.length : 0})</a>
                            </li>
                            {/*<li onClick={this.onSelectClick.bind(this, `LOCALS (${this.state.localArray && this.state.localArray.length ? this.state.localArray && this.state.localArray.length : 0})`)} className="nav-item minus__gap">*/}
                            {/*  <a className="nav-link" id="pills-locals-tab" data-toggle="pill" href="#pills-locals" role="tab" aria-controls="pills-locals" aria-selected="false">LOCALS ({this.state.localArray && this.state.localArray.length ? this.state.localArray && this.state.localArray.length : 0})</a>*/}
                            {/*</li>*/}

                          </ul>
                        </div>
                      }
                    </div>
                    {/* mobile dashboard dropdown close */}
                    <div className="tab-content tabcontent__padbtm" id="pills-tabContent">
                      <div className="tab-pane fade active in" id="pills-towns" role="tabpanel" aria-labelledby="pills-towns-tab">
                        <div className="flexboxnew flexboxnewmob">
                          <p className="newtext"> Click the <img data-dismiss="modal" src="./img/noun_move.svg" /> to move a town to your Favorites, Interested or Not Interested sections</p>
                          {/*<span className="ratetown__btn" onClick={this.rateTownClick.bind(this)}>Rank Towns/Agents <img src="./img/ratetown-arrow.svg" /></span>*/}
                        </div>

                        <div className="popular__towns--container">
                          <div className="popular__cities--wrapper">
                            {
                              totalTownArray && totalTownArray.length > 0 ?
                                totalTownArray.map((town, townIdx) => {
                                  let isAssigned = town.isAssigned == 1 ? true : false
                                  if (town.status === 1) {
                                    return (
                                      <div key={townIdx} className="popular__cities">
                                        {
                                          (town.town_id != -1) ?
                                            <span>
                                              <a href={town.town_url}>
                                                <div className="towns__box towns__boxnew">
                                                  <img className="popular__cities--city-image" src={town.town_image} alt="new_york" />
                                                  <div className="position-overlay overlay"></div>
                                                  <div className="popular__cities--name">{town.town_name}</div>
                                                </div>
                                              </a>
                                              {/*<span className="comment-update" onClick={this.openCommentClick.bind(this, town.town_id, town.agent_id, town.town_name, 1)}><img src="./img/comment-icon-updated.svg" />{town.comments > 0 ? (<span className="counter">{town.comments}</span>) : ''}</span>*/}
                                              <span onClick={this.moveTownClick.bind(this, town.town_id, town.agent_id, town.town_name, 1)} className="testbtn newtestbtns"><img src="./img/noun_move.svg" /></span>
                                              {
                                                (town.isResearch === true && town.insightCount !== 0) ?
                                                  (<span onClick={this.insightClick.bind(this, town)}><button className="btn__view--insight btnviewinsight">Click here to View Insight ({town.insightCount})</button></span>) : ''}
                                            </span>
                                            :
                                            <a onClick={this.blankTownClick.bind(this)}>
                                              <div className="towns__box gray__box">
                                                <img src="./img/dummylist-img.png" />
                                                <span>
                                                  <img src="./img/add.svg" />
                                                  <p>Add Town We Missed</p>
                                                </span>
                                              </div>
                                            </a>
                                        }
                                        {/*{*/}
                                        {/*  (town.town_id != -1) &&*/}
                                        {/*  <div className="toggle__wrapper toggle__wrapper--clients">*/}
                                        {/*    <p className="para__color yesno__para">Set up a town tour with a local Suburban Jungle partner agent </p>*/}
                                        {/*    {*/}
                                        {/*      isAssigned ? <p className="para__middle"><span className="yes__label">Yes</span></p> : <p className="para__middle"><span className="no__label">No</span></p>*/}
                                        {/*    }*/}
                                        {/*    <label className="switch1">*/}
                                        {/*      <input type="checkbox" checked={isAssigned} onChange={this.agnetAutoAssign.bind(this, town)} />*/}
                                        {/*      <span style={{ marginTop: '10px' }} className="slider1 round switch__padding"></span>*/}
                                        {/*    </label>*/}

                                        {/*  </div>*/}
                                        {/*}*/}
                                      </div>
                                    )
                                  }
                                })
                                :
                                <div className="upcoming__empty">
                                  <p>You have no towns assigned to you at the moment. Please check back later or contact your strategist.</p>
                                  <img className="arrow_img" src="img/curved-arrow-with-broken-line.svg" />
                                </div>
                            }

                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-towns-tour" role="tabpanel" aria-labelledby="pills-tour-tab">
                        {notReadyTourCount && notReadyTourCount.length > 0 ? (
                          <div className="flexboxnew flexboxnewmob">
                            <p className="newtext"> Click the <img data-dismiss="modal" src="./img/noun_move.svg" /> to move a town to your Favorites, Interested or Not Interested sections</p>
                          </div>) : ''}
                        <div className="popular__towns--container">
                          <div className="popular__cities--wrapper">
                            {
                              totalTownArray && totalTownArray.length > 0 ?
                                totalTownArray.map((town, townIdx) => {
                                  if (town.status === 3) {
                                    return (
                                      <div key={townIdx} className="popular__cities">
                                        <span>
                                          <a href={town.town_url}>
                                            <div className="towns__box towns__boxnew">
                                              <img className="popular__cities--city-image" src={town.town_image} alt="new_york" />
                                              <div className="position-overlay overlay"></div>
                                              <div className="popular__cities--name">{town.town_name}</div>
                                            </div>
                                          </a>
                                          {/*<span className="comment-update" onClick={this.openCommentClick.bind(this, town.town_id, 0, town.town_name, 1)}><img src="./img/comment-icon-updated.svg" />{town.comments > 0 ? (<span className="counter">{town.comments}</span>) : ''}</span>*/}
                                          <span onClick={this.moveTownClick.bind(this, town.town_id, town.agent_id, town.town_name, 3)} className="testbtn newtestbtns"><img src="./img/noun_move.svg" /></span>
                                          {
                                            (town.isResearch === true && town.insightCount !== 0) ?
                                              (<span onClick={this.insightClick.bind(this, town)}><button className="btn__view--insight">Click here to View Insight ({town.insightCount})</button></span>) : ''}
                                        </span>
                                      </div>
                                    )
                                  } else {
                                    if (notReadyTourCount && notReadyTourCount.length === 0) {
                                      return (
                                        <div key={"notReadyTourCount" + townIdx} className="upcoming__empty">
                                          <p>You haven’t selected any towns that you are interested but not ready to tour yet</p>
                                          {<img className="arrow_img" src="" />}
                                        </div>
                                      )
                                    }
                                  }
                                })
                                :
                                <div className="upcoming__empty">
                                  <p>You have no town remove.</p>
                                  <img className="arrow_img" src="img/curved-arrow-with-broken-line.svg" />
                                </div>
                            }

                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-agent" role="tabpanel" aria-labelledby="pills-agent-tab">
                        <div className="popular__towns--container">
                          <div className="popular__cities--wrapper">
                            {
                              (this.state.agentArray && this.state.agentArray.length > 0) ?
                                this.state.agentArray && this.state.agentArray.map((agent, agentIdx) => {
                                  return (
                                    <div key={agentIdx} className="popular__cities">
                                      <div onClick={this.agentClick.bind(this, agent)} className="schedule__collapse" data-toggle="modal" data-target={"#profileModalAgent" + agentIdx}>
                                        <div className="schedule--img--box">
                                          <div className="schedule--img schedule__profile--text">
                                            {agent.agent_image ?
                                              <img src={agent.agent_image} />
                                              :
                                              <span className="no-ImgBox">{agent.agent_full_name.charAt(0)}</span>
                                            }
                                          </div>
                                          <div>
                                            <h2 className="title">{agent.agent_full_name}</h2>
                                            <p>{agent.agent_role}</p>
                                          </div>
                                        </div>
                                        <div className="schedule__collapse--arrow">
                                          <img src="./img/scheduler_dropdown.svg" />
                                        </div>
                                      </div>
                                      <a href={agent.town_url}>
                                        <div className="towns__box towns__boxnew">
                                          <img className="popular__cities--city-image" src={agent.town_image} alt="new_york" />
                                          <div className="position-overlay overlay"></div>
                                          <div className="popular__cities--name">{agent.town_name}</div>
                                        </div>
                                      </a>

                                      {/*Profile Modal Agents*/}
                                      <div id="agent_id_for_close" className="profile__modal profile__modal--mobile">
                                        <div className="modal fade" id={"profileModalAgent" + agentIdx} tabIndex="-1" role="dialog" aria-labelledby="profileModalAgentTitle" aria-hidden="true">
                                          <div className="modal-dialog modal-dialog-centered modalmartop" role="document">
                                            <div className="modal-content">

                                              <button type="button" className="btn close-btn desk__close" data-dismiss="modal">
                                                <img data-dismiss="modal" src="./img/close_btn.svg" />
                                              </button>

                                              <div className="mob__header--close">
                                                <div>
                                                  <button type="button" className="btn close-btn" data-dismiss="modal">
                                                    <img data-dismiss="modal" src="./img/ArrowBack.svg" className="closearrow" />
                                                  </button>
                                                </div>
                                                <div className="centered">
                                                  <h3>About {agent.agent_full_name}</h3>
                                                </div>
                                                <div>&nbsp;</div>
                                              </div>


                                              <div className="modal-body modalbody24">
                                                <div className="user--profile">
                                                  {agent.agent_image ?
                                                    <img src={agent.agent_image} />
                                                    :
                                                    <span className="no-ImgBox">{agent.agent_full_name.charAt(0)}</span>
                                                  }

                                                </div>
                                                <h1 className="profile-title newheadergolbal piersandfonts">{agent.agent_full_name}</h1>
                                                <div className="email__contact">
                                                  <a href={`mailto:${agent.agent_email}`}><img src="./img/noun_Email.svg" />{agent.agent_email}</a>
                                                  <a href={`tel:${this.formatNumberForLink(agent.agent_mobile)}`}><img src="./img/noun_call.svg" />{agent.agent_mobile ? (
                                                    (agent.agent_mobile.replace(/\D/g, '').length == 10) ?
                                                      this.formatNumber(agent.agent_mobile)
                                                      :
                                                      agent.agent_mobile
                                                  )
                                                    :
                                                    ""
                                                  }</a>
                                                </div>
                                                {
                                                  (agent.agent_description !== '') ?
                                                    <ShowMore
                                                      className="showmore_option"
                                                      lines={4}
                                                      more='View more'
                                                      less='View less'
                                                      anchorClass='ShowMoreToggle'
                                                    >
                                                      <p className="showmore__para">{agent.agent_description}</p>
                                                    </ShowMore>
                                                    : ""
                                                }

                                                <div>
                                                  <p className="share__para">Share my contact info with {agent.agent_full_name}</p>
                                                  <div className="clearfix" />
                                                  <div className="toggle__wrapper">
                                                    <label className="switch1">
                                                      <input type="checkbox" checked={agent.share_info} onChange={this.shareInfoToggle.bind(this, agent)} />
                                                      <span style={{ marginTop: '10px' }} className="slider1 round switch__padding"></span>
                                                    </label>
                                                    {
                                                      (agent.share_info) ? <p style={{ marginTop: '10px' }}>Status: <span>Yes</span></p> : <p style={{ marginTop: '10px' }}>Status: <span>No</span></p>
                                                    }
                                                  </div>



                                                  <button data-dismiss="modal" className="btn-warning"></button>
                                                </div>

                                                <div className="mobile__btns">
                                                  {/*<button*/}
                                                  {/*  type="button"*/}
                                                  {/*  data-dismiss="modal"*/}
                                                  {/*  className="btn btn__green btn-block profile--button"*/}
                                                  {/*  onClick={this.openScheduleBar.bind(this, agent)}>*/}
                                                  {/*  schedule*/}
                                                  {/*</button>*/}
                                                  {/*<Link*/}
                                                  {/*  to={{ pathname: `/client-chat-with-agent/${agent.agent_id}`, state: agent }}*/}
                                                  {/*  onClick={() => {*/}
                                                  {/*    this.closeCustomPopup();*/}
                                                  {/*  }}*/}
                                                  {/*  type="button"*/}
                                                  {/*  className="btn btn__grey--schedule" style={{ marginBottom: '12px' }}>*/}
                                                  {/*  <span className="msg__desktop">message agent</span>*/}
                                                  {/*  <span className="chat__mobile">chat</span>*/}
                                                  {/*</Link>*/}
                                                  <button type="button" onClick={this.removeAgentClick.bind(this, agent)} className="popup__thanks--btn">
                                                    Thanks, but don't love this agent...please swap out
                                                  </button>
                                                  <p className="popup__paragraph">
                                                    Once you click on the <b>"Thanks, but don't love this agent... please swap out"</b> button, the agent will be removed
                                                  </p>
                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/*Profile Modal END*/}
                                    </div>
                                  )
                                })
                                :
                                <div className="upcoming__empty">
                                  <p>You have no agents assigned to you at the moment. Please check back later or contact your strategist.</p>
                                  <img className="arrow_img" src="img/curved-arrow-with-broken-line.svg" />
                                </div>
                            }

                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-locals" role="tabpanel" aria-labelledby="pills-locals-tab">
                        <div className="popular__towns--container">
                          <div className="popular__cities--wrapper">
                            {
                              (this.state.localArray && this.state.localArray.length > 0) ?
                                this.state.localArray && this.state.localArray.map((local, localIdx) => {
                                  return (
                                    <div key={localIdx} className="popular__cities">
                                      <div onClick={this.agentClick.bind(this, local)} className="schedule__collapse" data-toggle="modal" data-target={"#profileModalLocal" + localIdx}>
                                        <div className="schedule--img--box">
                                          <div className="schedule--img schedule__profile--text">
                                            {local.agent_image ?
                                              <img src={local.agent_image} />
                                              :
                                              <span className="no-ImgBox">{local.agent_full_name.charAt(0)}</span>
                                            }
                                          </div>
                                          <div>
                                            <h2 className="title">{local.agent_full_name}</h2>
                                            <p>{local.agent_role}</p>
                                          </div>
                                        </div>
                                        <div className="schedule__collapse--arrow">
                                          <img src="./img/scheduler_dropdown.svg" />
                                        </div>
                                      </div>
                                      <a href={local.town_url}>
                                        <div className="towns__box towns__boxnew">
                                          <img className="popular__cities--city-image" src={local.town_image} alt="new_york" />
                                          <div className="position-overlay overlay"></div>
                                          <div className="popular__cities--name">{local.town_name}</div>
                                        </div>
                                      </a>

                                      {/*Profile Modal Locals*/}
                                      <div className="profile__modal profile__modal--mobile">
                                        <div className="modal fade" id={"profileModalLocal" + localIdx} tabIndex="-1" role="dialog" aria-labelledby="profileModalLocalTitle" aria-hidden="true">
                                          <div className="modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">

                                              {/* <button type="button" className="btn close-btn" data-dismiss="modal">
                                                <img data-dismiss="modal" src="./img/close_btn.svg"/>
                                              </button>    */}

                                              <button type="button" className="btn close-btn desk__close" data-dismiss="modal">
                                                <img data-dismiss="modal" src="./img/close_btn.svg" />
                                              </button>

                                              <div className="mob__header--close">
                                                <button type="button" className="btn close-btn" data-dismiss="modal">
                                                  <img data-dismiss="modal" src="./img/ArrowBack.svg" className="closearrow" />
                                                </button>
                                                <h3>About {local.agent_full_name}</h3>
                                              </div>

                                              <div className="modal-body modalbody24">
                                                <div className="user--profile">
                                                  {local.agent_image ?
                                                    <img src={local.agent_image} />
                                                    :
                                                    <span className="no-ImgBox">{local.agent_full_name.charAt(0)}</span>
                                                  }
                                                </div>
                                                <h1 className="profile-title newheadergolbal piersandfonts">{local.agent_full_name}</h1>
                                                <div className="email__contact">
                                                  <Link to={'/client-home'}><img src="./img/noun_Email.svg" />{local.agent_email}</Link>
                                                  <Link to={'/client-home'}><img src="./img/noun_call.svg" />{local.agent_mobile ? (
                                                    (local.agent_mobile.replace(/\D/g, '').length == 10) ?
                                                      this.formatNumber(local.agent_mobile)
                                                      :
                                                      local.agent_mobile
                                                  )
                                                    :
                                                    ""
                                                  }</Link>
                                                </div>
                                                {
                                                  (local.agent_description !== '') ?
                                                    <ShowMore
                                                      lines={4}
                                                      more='View more'
                                                      less='View less'
                                                      anchorClass='ShowMoreToggle'
                                                    >
                                                      <p>{local.agent_description}</p>
                                                    </ShowMore>
                                                    : ""
                                                }
                                                {/* <div>
                                                  <p>Share my contact info with {local.agent_full_name}</p>
                                                    <label class="switch1">
                                                      <input type="checkbox" checked={this.state.shareInfoToggle} onChange={this.shareInfoToggle.bind(this, local)}/>
                                                      <span class="slider1 round switch__padding"></span>
                                                    </label>
                                                    {
                                                      (this.state.shareInfoToggle)? <p>Yes</p> : <p>No</p>
                                                    }
                                                </div> */}
                                                <div>
                                                  <p className="share__para">Share my contact info with {local.agent_full_name}</p>
                                                  <div className="clearfix" />
                                                  <div className="toggle__wrapper addmarbtms">
                                                    <label className="switch1">
                                                      <input type="checkbox" checked={this.state.shareInfoToggle} onChange={this.shareInfoToggle.bind(this, local)} />
                                                      <span className="slider1 round switch__padding"></span>
                                                    </label>
                                                    {
                                                      (this.state.shareInfoToggle) ? <p>Status: <span>Yes</span></p> : <p>Status: <span>No</span></p>
                                                    }
                                                  </div>
                                                </div>


                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/*Profile Modal END*/}
                                    </div>
                                  )
                                })
                                :
                                <div className="upcoming__empty">
                                  <p>You have no locals assigned to you at the moment. Please check back later or contact your strategist.</p>
                                  <img className="arrow_img" src="img/curved-arrow-with-broken-line.svg" />
                                </div>
                            }

                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-business" role="tabpanel" aria-labelledby="pills-business-tab">
                        <div className="popular__towns--container">
                          <div className="popular__cities--wrapper">
                            {
                              (this.state.businessArray && this.state.businessArray.length > 0) ?
                                this.state.businessArray && this.state.businessArray.map((local, businessIdx) => {
                                  return (
                                    <div key={businessIdx} className="popular__cities">
                                      <div className="schedule__collapse" data-toggle="modal" data-target={"#profileModalLocal" + businessIdx}>
                                        <div className="schedule--img--box">
                                          <div className="schedule--img schedule__profile--text">
                                            {local.agent_image ?
                                              <img src={local.agent_image} />
                                              :
                                              <span className="no-ImgBox">{local.agent_full_name.charAt(0)}</span>
                                            }
                                          </div>
                                          <div>
                                            <h2 className="title">{local.agent_full_name}</h2>
                                            <p>{local.agent_role}</p>
                                          </div>
                                        </div>
                                        <div className="schedule__collapse--arrow">
                                          <img src="./img/scheduler_dropdown.svg" />
                                        </div>
                                      </div>
                                      <a href={local.town_url}>
                                        <div className="towns__box towns__boxnew">
                                          <img className="popular__cities--city-image" src={local.town_image} alt="new_york" />
                                          <div className="position-overlay overlay"></div>
                                          <div className="popular__cities--name">{local.town_name}</div>
                                        </div>
                                      </a>

                                      {/*Profile Modal Locals*/}
                                      <div className="profile__modal">
                                        <div className="modal fade" id={"profileModalLocal" + businessIdx} tabIndex="-1" role="dialog" aria-labelledby="profileModalLocalTitle" aria-hidden="true">
                                          <div className="modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">
                                              <button type="button" className="btn close-btn" data-dismiss="modal">
                                                <img data-dismiss="modal" src="./img/close_btn.svg" />
                                              </button>
                                              <div className="modal-body">
                                                <div className="user--profile">
                                                  {local.agent_image ?
                                                    <img src={local.agent_image} />
                                                    :
                                                    <span className="no-ImgBox">{local.agent_full_name.charAt(0)}</span>
                                                  }
                                                </div>
                                                <h1 className="profile-title">{local.agent_full_name}</h1>
                                                <div className="email__contact">
                                                  <Link to={'/client-home'}><img src="./img/noun_Email.svg" />{local.agent_email}</Link>
                                                  <Link to={'/client-home'}><img src="./img/noun_call.svg" />{local.agent_mobile}</Link>
                                                </div>
                                                {
                                                  (local.agent_description !== '') ?
                                                    <ShowMore
                                                      lines={4}
                                                      more='View more'
                                                      less='View less'
                                                      anchorClass='ShowMoreToggle'
                                                    >
                                                      <p>{local.agent_description}</p>
                                                    </ShowMore>
                                                    : ""
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/*Profile Modal END*/}
                                    </div>
                                  )
                                })
                                :
                                <div className="upcoming__empty">
                                  <p>You have no business development assigned to you at the moment. Please check back later or contact your strategist.</p>
                                  <img className="arrow_img" src="img/curved-arrow-with-broken-line.svg" />
                                </div>
                            }

                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-not" role="tabpanel" aria-labelledby="pills-towns-not">

                        {notInterestedTown && notInterestedTown.length > 0 ? (
                          <div className="flexboxnew flexboxnewmob">
                            <p className="newtext"> Click the <img data-dismiss="modal" src="./img/noun_move.svg" /> to move a town to your Favorites, Interested or Not Interested sections</p>
                          </div>) : ''}
                        <div className="popular__towns--container">
                          <div className="popular__cities--wrapper">
                            {
                              totalTownArray && totalTownArray.length > 0 ?
                                totalTownArray.map((town, townIdx) => {
                                  if (town.status === 0) {
                                    return (
                                      <div key={townIdx} className="popular__cities">
                                        <span>
                                          <a href={town.town_url}>
                                            <div className="towns__box towns__boxnew">
                                              <img className="popular__cities--city-image" src={town.town_image} alt="new_york" />
                                              <div className="position-overlay overlay"></div>
                                              <div className="popular__cities--name">{town.town_name}</div>
                                            </div>
                                          </a>
                                          <span onClick={this.moveTownClick.bind(this, town.town_id, 0, town.town_name, 0)} className="testbtn newtestbtns"><img src="./img/noun_move.svg" /></span>
                                          {
                                            (town.isResearch === true && town.insightCount !== 0) ?
                                              (<span onClick={this.insightClick.bind(this, town)}><button className="btn__view--insight">Click here to View Insight ({town.insightCount})</button></span>) : ''}
                                        </span>
                                      </div>
                                    )
                                  } else {
                                    if (notInterestedTown && notInterestedTown.length === 0) {
                                      return (
                                        <div key={"not__InterestedTown__" + townIdx} className="upcoming__empty">
                                          <p>You haven’t selected any towns that you are not interested in</p>
                                          {<img className="arrow_img" src="" />}
                                        </div>
                                      )
                                    }
                                  }
                                })
                                :
                                <div className="upcoming__empty">
                                  <p>You have no town remove.</p>
                                  <img className="arrow_img" src="img/curved-arrow-with-broken-line.svg" />
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>


              </div>


            </div>
          </div>
          {
            (!this.state.schduleOpenClose) &&
            <button onClick={this.scheduleAppointmentDrawer} className="scheduleapp__mob--btn">Schedule Appointment</button>
          }
          <Drawer
            // title="Basic Drawer"
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
            width="590px"
            className="drawer-schedule-appointment"
          >
            <div >
              <div className="drawer-schedule-appointment-header">
                <h1 className="drawer-title"> Schedule Appointment</h1>
                <button className="btn btn-close-drawer" onClick={this.onClose}><CloseIcon /></button>
              </div>
              {/*{*/}
              {/*  (this.state.agentArray && this.state.agentArray.length > 0) &&*/}
              {/*  <Link to={`/client-chat-with-agent`} className="msg__agent--greenbtnmob showonlymob">*/}
              {/*    Chat with Agent</Link>*/}
              {/*}*/}
              <div className={this.state.schduleOpenClose ? "strategist__form--mob strategist__form--mobshow" : "strategist__form--mob"}>
                <div className={(this.state.classShowHide === true) ? "strategist_form strategist_form--inherit client_prt client_pl" : "strategist_form client_prt client_pl"}>
                  <div>
                    <div className="strategist_form--tabe open__sans--font">

                      {/* <div className="mobileclose mobileclosemob tabmobileclosemob"><img src="./img/mobileclose.svg" onClick={this.scheduleButtton.bind(this)} /></div> */}

                      {/* mobile dashboard dropdown open */}
                      <div className="mobview__dropdown--select tabmob mobview__dropdown--selectshced show__mob--desk">
                        <button className={this.state.secondDropDownBoolVal ? "agentclient__btn--drop gapdrop agentclient__btn--dropfocus letterspacing__one" : "agentclient__btn--drop gapdrop letterspacing__one"} onClick={this.onSelectScheduleClick.bind(this, this.state.secondDropDownVal)}>{this.state.secondDropDownVal}</button>
                        {
                          (this.state.secondDropDownBoolVal) &&
                          <div className={this.state.secondDropDownBoolVal ? "agentclient__dropdownsshow agentclient__dropdowns" : "agentclient__dropdowns"}>
                            <ul>
                              <li onClick={this.mobileScheduledButton.bind(this, "New Appointment")} className="nav-item active">
                                <a className="nav-link" id="pills-home-tab" data-toggle="pill" href="#new-appointment" role="tab" aria-controls="pills-home" aria-selected="true">New Appointment</a>
                              </li>
                              <li onClick={this.mobileUpcomingButton.bind(this, `Upcoming Appointment (${clientAptData && clientAptData.data && clientAptData.data.length})`)} className="nav-item">
                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#upcoming_tab " role="tab" aria-controls="pills-profile" aria-selected="false">Upcoming Appointment ({clientAptData && clientAptData.data && clientAptData.data.length})</a>
                              </li>
                            </ul>
                          </div>
                        }
                      </div>
                      {/* mobile dashboard dropdown close */}

                      <div className="tab-content" id="pills-tabContent">
                        {
                          (this.state.secondDropDownVal === "New Appointment") &&
                          <div className="tab-pane fade active in" id="new-appointment" role="tabpanel" aria-labelledby="new-appointment-tab">
                            <div className="profile__container--form addclient__container--form">
                              <div className="panel panel-default">
                                <div className="panel-body">
                                  <div className="form-group text-group">
                                    <label className="label__text">WITH</label>
                                    <select
                                      name="selectedUserId"
                                      className="selectinputs__global letterspacing__one selectbox__height"
                                      value={this.state.selectedUserId}
                                      onChange={this.handleChange.bind(this)}>
                                      <option value={'null'}>Select strategist</option>
                                      {scheduleArray && scheduleArray.length > 0 ? scheduleArray.map((val, index) => {
                                        let name = val && val.role === 'strategist' ? val && val.first_name + ' ' + val.last_name + ' (Strategist)' : val && val.agent_full_name + ' (Agent)';
                                        let id = val && val.role === 'strategist' ? val && val.id : val && val.agent_id;
                                        let canScheduleClients = (val && val.role === 'strategist') ? val.can_schedule_clients : false;
                                        if (id && id != null && canScheduleClients) {
                                          return (
                                            <option
                                              key={index}
                                              value={id}
                                              // disabled={!canScheduleClients}
                                            >
                                              {name}
                                            </option>
                                          );
                                        }

                                      }) : ''}
                                    </select>
                                    {this.state.err.selectedUserId ?
                                      <span className="alert alert-danger error_field-schedule">
                                        {this.state.err.selectedUserId}
                                      </span> : ''}
                                  </div>
                                  <div className="form-group text-group editselectbox">
                                    <label className="label__text">APPOINTMENT TYPE</label>
                                    <select
                                      name='appointment_type'
                                      placeholder="Select appointment type"
                                      className="selectinputs__global letterspacing__one selectbox__height"
                                      value={this.state.appointment_type}
                                      onChange={this.handleChangeApt.bind(this)}
                                    >
                                      <option>Select appointment type</option>
                                      {appointment && appointment.map(appointmentValue =>
                                        <option key={appointmentValue.id} value={appointmentValue.value}>{appointmentValue.name}</option>
                                      )};
                                    </select>
                                    {this.state.err.appointment_type ?
                                      <span className="alert alert-danger error_field-schedule">
                                        {this.state.err.appointment_type}
                                      </span> : ''}
                                  </div>

                                  <div>
                                    <div className="form-group text-group">
                                      <label className="label__text">SELECT DATE</label>
                                      <div>
                                        <div className="react-datepicker-wrapper">
                                          <DatePicker
                                            selected={this.state.startDate}
                                            className="selectdate__global letterspacing__one selectbox__height"
                                            customInput={<DatePickerCustomInput />}
                                            onChange={this.handleDateClick.bind(this)}
                                            includeDates={this.state.availableDates}
                                            placeholderText="Select Date" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group text-group select__time addpadbottom">
                                      <label className="label__text">SELECT TIME</label>
                                      <div>
                                        <div className="react-datepicker-wrapper">
                                          <div className="react-datepicker__input-container">
                                            <select
                                              name='selectedTime'
                                              placeholder="Select Time"
                                              className="selecttime__global letterspacing__one selectbox__height"
                                              value={this.state.selectedTime}
                                              onChange={this.handleChangeTime.bind(this)}>
                                              <option>Select Time</option>
                                              {times}
                                            </select>
                                            {this.state.err.selectedTime ?
                                              <div className="alert alert-danger error_field-schedule">
                                                {this.state.err.selectedTime}
                                              </div>
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn__flat btn__flat__lg btn-block btn__green"
                                    onClick={this.submitSchedulingData.bind(this)}>
                                    Schedule
                               </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        {
                          (this.state.secondDropDownVal !== "New Appointment") &&
                          <div className="tab-pane fade active in" id="upcoming_tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                            {
                              (upcomingApt && upcomingApt.length > 0) ?
                                upcomingApt && upcomingApt.map((apt, aptIdx) => {
                                  const isScheduledThroughAcuity = apt.start_date && apt.end_date && apt.booked_by === 'from_acuity_link'
                                  const isScheduledByClient = apt.start_date && apt.end_date && apt.scheduled_by === _user.id
                                  const isAppointmentWithAgent = apt.role && apt.role === 'agent'

                                  const appointmentStartDate = (isScheduledThroughAcuity || isAppointmentWithAgent || isScheduledByClient) ? moment(apt.start_date).format('LLL') : moment(apt.start_date).utc().tz(moment.tz.guess()).format('LLL')

                                  let appointmentType = (apt.appointment_type === 'Follow up agent town tour' ? 'Follow up agent visit' : apt.appointment_type)

                                  return (
                                    <div key={aptIdx} className="schedule__collapse--sectino">
                                      <div id={"apt" + aptIdx} data-toggle="modal"
                                          data-target={"#appointmentModalCenter" + aptIdx}
                                        />
                                      <div
                                        onClick={this.onAptClick.bind(this, apt, aptIdx)}
                                        className="schedule__collapse"
                                        >
                                        <div className="schedule--img--box">
                                          <div className="schedule--img schedule__profile--text">
                                            <img src="./img/scheduler_img.svg" />
                                          </div>
                                          <div>
                                            <h2 className="title">{appointmentType}</h2>
                                            <p>{appointmentStartDate}</p>
                                          </div>
                                        </div>
                                        <div className="schedule__collapse--arrow">
                                          <img src="./img/scheduler_dropdown.svg" />
                                        </div>
                                      </div>

                                      {/*Appointment Details Modal*/}
                                      <div>
                                        <div className="appointment__modal">
                                          <div className="modal fade" id={"appointmentModalCenter" + aptIdx} tabIndex="-1" role="dialog" aria-labelledby="appointmentModalCenterTitle" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered modal__dialog--mob" role="document">
                                              <div className="modal-content">

                                                <div className="modal-header">
                                                  <div className="flexd">
                                                    <h4 className="popup__header padd24">Appointment Details</h4>
                                                    <button onClick={this.closeBtn.bind(this)} type="button" className="close_img--white" data-dismiss="modal">
                                                      <img data-dismiss="modal" src="./img/close_btn.svg" />
                                                    </button>
                                                  </div>
                                                </div>

                                                <div className="modal-body">
                                                  <ul className="appointment__list">
                                                    <li className="list_1">WITH</li>
                                                    <li className="list_2">{apt.strategist_first_name + " " + apt.strategist_last_name}</li>
                                                    <li className="list_1">APPOINTMENT TYPE</li>
                                                    <li>
                                                      <div className="form-group text-group">
                                                        <select
                                                          name="appointment_type"
                                                          onChange={this.handleChangeApt.bind(this)}
                                                          value={this.state.appointment_type}
                                                          className="form-control custom-select appointment_type-select">
                                                          <option value={apt.appointment_type}>{appointmentType}</option>
                                                          {/*appointment*/}
                                                        </select>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                  <div className="profile__container--form addclient__container--form">
                                                    <div className="panel panel-default">
                                                      <div className="panel-body">
                                                        <div className="form-group text-group">
                                                          <label className="labelinputs__global">SELECT DATE</label>
                                                          <div>
                                                            <div className="react-datepicker-wrapper">
                                                              <div className="react-datepicker__input-container">
                                                                <DatePicker
                                                                  selected={this.state.startDate}
                                                                  value={this.state.startDate}
                                                                  customInput={<DatePickerCustomInput />}
                                                                  onChange={this.handleDateClick.bind(this)}
                                                                  includeDates={this.state.availableDates}
                                                                  placeholderText="Select Date" />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="form-group text-group">
                                                          <label className="labelinputs__global">SELECT TIME</label>
                                                          <div>
                                                            <div className="react-datepicker-wrapper">
                                                              <div className="react-datepicker__input-container">
                                                                <select
                                                                  name='selectedTime'
                                                                  placeholder="Select Time"
                                                                  className="selecttime__global selectbox__height"
                                                                  value={this.state.selectedTime}
                                                                  onChange={this.handleChangeTime.bind(this)}>
                                                                  {
                                                                    (moment(this.state.matchDate).format("YYYY-MM-DD") == moment(this.state.startDate).format("YYYY-MM-DD")) ?
                                                                      <option value="" disabled selected>{timeConverter(this.state.userTime)}</option>
                                                                      :
                                                                      <option value="" disabled selected>Select Time</option>
                                                                  }

                                                                  {times}
                                                                </select>
                                                                {this.state.err1.selectedTime ?
                                                                  <div className="alert alert-danger error_field-schedule">
                                                                    {this.state.err1.selectedTime}
                                                                  </div>
                                                                  : ''
                                                                }
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div id={'aptClose'} data-dismiss="modal" />
                                                        <button
                                                          type="submit"
                                                          onClick={this.rescheduleButton.bind(this, apt)}
                                                          className="btn btn-block btn__green btn__green--lg btngreenshedule piersandfonts">
                                                          RESCHEDULE
                                                   </button>
                                                        <button
                                                          onClick={this.cancelAppointment.bind(this, apt)}
                                                          type="submit"
                                                          className="btn btn-block btn__green--lg btn--cancel btngreencancel piersandfonts">
                                                          CANCEL
                                                   </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/*Appointment Details Modal END*/}
                                    </div>
                                  )
                                })
                                :
                                <div className="upcoming__empty">
                                  <img className="empt__cal--img" src="img/empty_cal_img.svg" />
                                  <p>You have no upcoming appointment scheduled. Click the button below to schedule one.</p>
                                  <button
                                    type="submit"
                                    onClick={this.upcomingSchBtn.bind(this)}
                                    className="btn btn-block btn__green btn__green--lg upcoming__emptybtn">
                                    SCHEDULE
                               </button>
                                </div>
                            }

                          </div>
                        }

                        {/* {
                       (this.state.secondDropDownVal === "New Appointment") &&
                       <button
                         className="btn scheduleappform__mob--btn"
                         onClick={this.submitSchedulingData.bind(this)}>
                         SCHEDULE
                       </button>
                     } */}
                      </div>

                    </div>
                  </div>
                  {/*strategist_form END*/}
                </div>
              </div>
            </div>
          </Drawer>

          {/*Booking Modal START*/}
          {/*<div className="view__booking--modal">
          <div className="modal fade" id="bookingModalCenter" tabindex="-1" role="dialog" aria-labelledby="bookingModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src="./img/noun_fireworks.svg"/>
                  <p>
                    Thank You, one of our <span> strategist or agent </span>will be in
                    touch with you at <span>9:30am</span> on <span>Jan 1, 2019</span>
                  </p>
                  <button type="submit" className="btn btn-block btn__green btn__green--lg">VIEW BOOKING</button>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
          {/*Booking Modal END*/}
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="view__booking--modal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <img src="./img/noun_fireworks.png" />
                    <p>
                      Thank You, <span> {this.state.userName} </span>will be in
                    touch with you at <span>{timeConverter(this.state.userTime)}</span> on <span>{moment(this.state.bookingConfirmedDate).format('LL')}</span>
                    </p>
                    <button
                      type="submit"
                      onClick={this.upcomingClick.bind(this)}
                      className="btn btn-block btn__green btn__green--lg">
                      VIEW BOOKING
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>


          <Modal
            isOpen={this.state.addTownOpen}
            onAfterOpen={this.afterTownAddOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="add--modal add--modaledit piersandfonts">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body text-center">

                    <div>
                      <div className="form__header form__headerflex">
                        <h5 className="modal-title modal-title-custom" id="exampleModalLabel">
                          Add Another Town
                          </h5>
                        <button
                          onClick={this.closeTownModel.bind(this)}
                          type="button"
                          id="close_user_modal"
                          className="close_img"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <img src="../../img/close_btn.svg" />
                        </button>
                      </div>
                      <div className="form__wrapper">
                        <p className="h3-custom" style={{ margin: '20px 0', fontSize: '16px' }}>Search from our database of towns and add any
                          additional towns you are interested in.</p>
                        <div className="form__wrapper--fields login-tab-wrapper">
                          <div className="row">
                            <div className="col-md-12">
                              <div id="emailTab" className="form-group coverage__area coverage__areaedit">
                                <label className="label__text">SEARCH TOWN</label>
                                <Select
                                  multi
                                  simpleValue
                                  placeholder="Select town"
                                  name="category"
                                  menuIsOpen={true}
                                  options={options}
                                  onChange={this.handleTownChange.bind(this)}
                                  value={this.state.townOptions}>
                                </Select>
                              </div>
                            </div>
                          </div>

                          <button disabled={(this.state.townOptions && this.state.townOptions.length > 0) ? false : true} onClick={this.addTownBtn.bind(this)} className="btn btn-block btn__primary">
                            ADD TOWN
                          </button>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </Modal>


        </div>

        <div className={this.state.openChat ? "right__chat--section" : "chat__box__open"}>
          <div className={this.state.openChat ? "schedule__sidebar--container open right__section" : "schedule__sidebar--container right__section"}>
            <div className="schedule__sidebar--header">
              Chat With Strategist
            <div className="close-btn" onClick={this.closeChatBar.bind(this)}>
                <img src="img/close.svg" />
              </div>
            </div>
            <div className="chat__listing--section">
              <div className="chat__listing chat__listingnewmob">
                {
                  (allChatData && allChatData.length > 0) ?
                    <ul className="chat_ul_scroll chat_ul_scrollnew">
                      {
                        allChatData && allChatData.map((client, idx) => {
                          // var finalTime = new Date(client.created_date).toLocaleString("en-US", {timeZone: _user.time_zone})
                          return (
                            <div key={idx}>
                              {
                                (this.state.strategistList.includes(client.sender_id)) ? (
                                  <li>
                                    {/*<div className="for__date--format">{(client.showMsg !== true) && moment(finalTime).format("MMM Do YYYY h:mm a") }</div>*/}
                                    <img src={client.sender_avatar ? client.sender_avatar : "../../img/default-user.svg"} alt="" />
                                    <div className="details">
                                      <h6>{client.sender_full_name}</h6>
                                      <p>{client.message}</p>
                                      {
                                        (client.showMsg !== true) &&
                                        <h5 className="agentchatfont__med strategist__time">{moment(client.created_date).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                                      }
                                    </div>
                                  </li>
                                ) :
                                  <div>
                                    {
                                      (this.state.userid == client.sender_id && this.state.strategistList.includes(client.receiver_id)) ? (
                                        <li className="you">
                                          {/*<div className="for__date--format">{moment(finalTime).format("MMM Do YYYY h:mm a")}</div>*/}
                                          <img src={client.sender_avatar ? client.sender_avatar : "../../img/default-user.svg"} alt="" />
                                          <div className="details">
                                            <h6>You</h6>
                                            <p spellCheck="true">{client.message}</p>
                                            <h5 className="agentchatfont__med strategist__time">{moment(client.created_date).tz(_user && _user.time_zone).format('MMMM D, YYYY [at] hh:mm a z')}</h5>
                                          </div>
                                        </li>
                                      ) : ""
                                    }
                                  </div>
                              }
                            </div>
                          )
                        })
                      }
                    </ul>
                    :
                    <ul className="chat_ul_scroll">
                      <li>
                        <img src={this.state.strategistAvatar} alt="" />
                        <div className="details">
                          <h6>{this.state.strategistName}</h6>
                          <p>Hi there! Let me know if you have any questions?</p>
                        </div>
                      </li>
                    </ul>
                }

                <div className="send__wrapper--clientstrat sendwrapper__mob">
                  <textarea data-autoresize id="my_textareaBox" type="text" name="chat" value={this.state.chat} onChange={this.chatHandle.bind(this)} placeholder="Chat with your strategist… " />
                  <button onClick={this.sendBtn.bind(this)}>Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* **********************Client side bar for Insight or research data********************** */}
        <div className={this.state.openModel ? "right__chat--section" : "chat__box__open"}>
          <div className={this.state.openModel ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
            <div className="schedule__sidebar--header">
              Insight for {this.state.town_name}
              <div className="close-btn" onClick={this.closeResearchBar.bind(this)}>
                <img src="img/close.svg" />
              </div>
            </div>

            <div className="schedule__sidebar--body schedule__sidebar--ht">
              <div className="checkbox__listing">
                <ul>
                  {
                    (insightData && insightData.length > 0) ?
                      insightData.map((res, idx) => {
                        if (res.link && res.link !== '') {
                          if (!res.link.match(/^[a-zA-Z]+:\/\//)) {
                            res.link = 'https://' + res.link;
                          }
                        }
                        return (
                          <li key={idx}>
                            <div className="listing__left">
                              {
                                (res.file) ?
                                  <h6 className="green__types green__anchor"><a href={res.file} download={res.file_name}>{(res.file_name)}</a></h6>
                                  :
                                  (res.link) ?
                                    <h6 className="green__anchor"><a href={res.link} target='_blank'>{(res.link)}</a></h6>
                                    : <h6></h6>
                              }
                              <h5><div className="for__text__break">{res.note}</div></h5>
                            </div>
                          </li>
                        )
                      })
                      : (<p className="no__data--show">No Insight Data</p>)
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* ******************************************** */}
        <div className={this.state.rateTownToggle ? "right__chat--section" : "chat__box__open"}>
          <div className={this.state.rateTownToggle ? " activity__page schedule__sidebar--container open right__section" : "schedule__sidebar--container right__section"}>
            <div className="schedule__sidebar--header rate__color">
              {
                (this.state.ratingType === "add") ?
                  <span className="center__rate">Rank</span>
                  :
                  (this.state.editingType === "town") ?
                    <span className="center__rate">Rank Towns</span>
                    :
                    <span className="center__rate">Rank Agents</span>
              }
              {
                (this.state.ratingType !== "edit") &&
                <div className="close-btn close__btn--townrate" onClick={this.closeRateTown.bind(this)}>
                  {/* <img src="img/close.svg"/> */}
                  <CloseIcon />
                </div>
              }

              {
                (this.state.listItemTown && this.state.listItemTown.length > 0 || this.state.listItemAgent && this.state.listItemAgent.length > 0) && (this.state.ratingType === "add") ?
                  this.state.editingType === "town" && this.state.townShowing === true && this.state.listItemTown.length > 0 ?
                    <span onClick={this.editRateAgentAndTown.bind(this)} className="for__edit for__edit--center">Edit</span>
                    :
                    this.state.editingType === "agent" && this.state.agentShowing === true && this.state.listItemAgent.length > 0 &&
                    <span onClick={this.editRateAgentAndTown.bind(this)} className="for__edit for__edit--center">Edit</span>
                  :
                  (this.state.ratingType === "edit") &&
                  <span onClick={this.cancelRateAgentAndTown.bind(this)} className="for__edit for__edit--center">Cancel</span>
              }
            </div>

            {
              this.state.listItemTownAllData && this.state.listItemTownAllData.length > 0 || this.state.listItemAgentAllData && this.state.listItemAgentAllData.length > 0 ?
                <div>
                  {/* ===========start rate==========  */}
                  <div className="rate__town--outer">
                    {
                      (this.state.ratingType === "add") ? (
                        <div>
                          <ul className="nav nav-pills mb-3 towns__agents--tabs" id="pills-tab" role="tablist">
                            <li onClick={this.townClickButton.bind(this, this.state.listItemTown)} className="nav-item active">
                              <a className="nav-link" id="towns_rate_id" data-toggle="pill" href="#towns_href" role="tab" aria-controls="pills-homep" aria-selected="true">Towns</a>
                            </li>
                            <li onClick={this.agentClickButton.bind(this, this.state.listItemAgent)} className="nav-item">
                              <a className="nav-link" id="agents_rate_id" data-toggle="pill" href="#agents_href" role="tab" aria-controls="pills-profilep" aria-selected="false">Agents</a>
                            </li>
                          </ul>

                          <div className="tab-content" id="pills-tabContent">
                            {/* ************* This section of town ************** */}
                            <div className="tab-pane fade active in blank__img" id="towns_href" role="tabpanel" aria-labelledby="pills-homep">
                              {
                                this.state.listItemTown && this.state.listItemTown.length > 0 ?
                                  <span>
                                    {
                                      this.state.townShowing === true ?
                                        <div className="rate__town--listing before__edit-list">
                                          <ul>
                                            {this.state.listItemTown && this.state.listItemTown.map((rateTown, idx) => {
                                              return (
                                                <li key={idx}>
                                                  <span className="serials">{idx + 1}</span>
                                                  <span className="names">{rateTown.town_name}</span>
                                                </li>
                                              )
                                            })
                                            }
                                          </ul>
                                        </div>
                                        :
                                        <div className="rate__town--wrapper">
                                          <div>
                                            <img src="img/rate-town-default.svg" className="rate__town--img" />
                                            <p className="rate__town--para">
                                              You can rank the towns assigned to you so your strategist can know how they stack up against each other.
                                    </p>
                                            <button className="btn btn__contant" onClick={this.ratedTownClick.bind(this)}>Rank Your Towns</button>
                                          </div>
                                        </div>
                                    }
                                  </span>
                                  :
                                  <div className="rate__town--outer">
                                    <div className="rate__town--wrapper">
                                      <div className="">
                                        <img src="img/rate-town-default.svg" className="rate__town--img" />
                                        <p className="rate__town--para">
                                          You don’t have any towns assigned to you yet. Once your strategist assigns them, you’ll be able to rank them here
                                    </p>
                                        <button onClick={this.contactStrategist.bind(this)} className="btn btn__contant">Contact Strategist</button>
                                      </div>
                                    </div>
                                  </div>
                              }
                            </div>
                            {/* ************* This section end of town ************** */}

                            {/* ************* This section of agent ************** */}
                            <div className="tab-pane fade blank__img" id="agents_href" role="tabpanel" aria-labelledby="pills-profilep">
                              {
                                this.state.listItemAgent && this.state.listItemAgent.length > 0 ?
                                  <span>
                                    {
                                      this.state.agentShowing === true ?
                                        <div className="rate__town--listing before__edit-list">
                                          <ul>
                                            {
                                              this.state.listItemAgent.map((agent, index) => {
                                                return (
                                                  <li key={index}>
                                                    <span className="serials">{index + 1}</span>
                                                    <span className="profile__image"><img src={agent.image ? agent.image : "img/default-user.svg"} /></span>
                                                    <span className="names">{agent.name}</span>
                                                  </li>
                                                )
                                              })
                                            }
                                          </ul>
                                        </div>
                                        :
                                        <div className="rate__town--outer">
                                          <div className="rate__town--wrapper">
                                            <div>
                                              <img src="img/rate-town-default.svg" className="rate__town--img" />
                                              <p className="rate__town--para">
                                                You can rank the agents assigned to you so your strategist can know how they stack up against each other.
                                      </p>
                                              <button className="btn btn__contant" onClick={this.ratedAgentClick.bind(this)}>Rank Your Agent(s)</button>
                                            </div>
                                          </div>
                                        </div>
                                    }
                                  </span>
                                  :
                                  <div className="rate__town--outer">
                                    <div className="rate__town--wrapper">
                                      <div className="">
                                        <img src="img/rate-town-default.svg" className="rate__town--img" />
                                        <p className="rate__town--para">
                                          You don’t have any towns assigned to you yet. Once your strategist assigns them, you’ll be able to rank them here
                                  </p>
                                        <button onClick={this.contactStrategist.bind(this)} className="btn btn__contant">Contact Strategist</button>
                                      </div>
                                    </div>
                                  </div>
                              }
                            </div>
                            {/* ************* This section end of agent ************** */}
                          </div>
                        </div>
                      ) :
                        //===================This is Editin or Edit Data ==================
                        <div>
                          <div className="tab-content">
                            {
                              (this.state.editingType === "town") ? (
                                <div>
                                  <div className="drag__wrapper">
                                    <span className="drag__icon"><img src="img/rate-menu.svg" /></span>
                                    <p className="drag__txt">
                                      Drag the towns in the order of your preference - your most preferred ones up top!
                              </p>
                                  </div>

                                  <SortableComponent1
                                    data={this.state.listItemTown}
                                    onSortEnd={this.orderListTown}
                                    onRemove={(index) => this.remove(index)}
                                  />
                                  <button type="button" className="drag__drop--ratetown" onClick={this.dragDropSave.bind(this, "town")}>Save</button>
                                </div>
                              ) :
                                <div>
                                  <div>
                                    <div className="drag__wrapper">
                                      <span className="drag__icon"><img src="img/rate-menu.svg" /></span>
                                      <p className="drag__txt">
                                        Drag the agents in the order of your preference - your most preferred ones up top!
                                </p>
                                    </div>

                                    <SortableComponent2
                                      data={this.state.listItemAgent}
                                      onSortEnd={this.orderListAgent}
                                      onRemove={(index) => this.deleteAgent(index)}
                                    />
                                    <button type="button" className="drag__drop--ratetown" onClick={this.dragDropSave.bind(this, "agent")}>Save</button>
                                  </div>
                                </div>
                            }
                          </div>
                        </div>
                      //====================End Editing Data ====================
                    }
                  </div>
                  {/* end rate town */}
                </div>
                :
                <div className="rate__town--outer">
                  <div className="rate__town--wrapper">
                    <div className="">
                      <img src="img/rate-town-default.svg" className="rate__town--img" />
                      <p className="rate__town--para">
                        You don’t have any towns assigned to you yet. Once your strategist assigns them, you’ll be able to rank them here
                    </p>
                      <button onClick={this.contactStrategist.bind(this)} className="btn btn__contant">Contact Strategist</button>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
        {/* ************ This is function is for rate town *************/}



        {/*This is client chat open side bar*/}
        <div className={this.state.msgToggle ? "right__chat--section" : "chat__box__open"}>
          <div className={this.state.msgToggle ? " activity__page schedule__sidebar--container open right__section" : "schedule__sidebar--container right__section"}>
            <div className="schedule__sidebar--header search__peirsans">
              Chat With Agent
              <div className="close-btn" onClick={this.closeMsgToggleBar.bind(this)}>
                <img src="img/close.svg" />
              </div>
            </div>

            <div className="strategist__new--search" onClick={this.dropDownAgentClick.bind(this)}>
              <input
                onChange={(e) => this.agentSearchClick(e)}
                type="text"
                value={this.state.agentSearch}
                placeholder="Select agent"
                className="search__peirsans" />
            </div>

            {this.state.agentDropDown &&
              <div id="dropdownBtn" className="sel-outer new__search--outer">
                {agentSearchData && agentSearchData.map((agent, agentIdx) => {
                  return (
                    <li onClick={this.selectAgent.bind(this, agent)} key={agentIdx} className="custom-select-option">
                      <span className="coverage__checkbox">
                        <label className="custom-checkbox">
                          {agent.agent_full_name}
                        </label>
                      </span>
                    </li>
                  )
                })}
              </div>
            }

            <div className="chat__listing">
              <ul className="chat_ul_scroll chat__listing--newclient">
                {
                  chatClientAgentHistory && chatClientAgentHistory.length > 0 ? (
                    chatClientAgentHistory.map((chat, idx) => {
                      let userAvatar = _user && _user.avatar;
                      let userName = _user && _user.first_name + ' ' + _user.last_name;
                      let myuserId = _user && _user.id;
                      return (
                        <div key={idx}>
                          {
                            (chat.receiver_id === this.state.userid && chat.sender_id === this.state.chatSelectAgentId) ? (
                              <li>
                                <img src={chat.sender_avatar ? chat.sender_avatar : "../../img/default-user.svg"} alt="" />
                                <div className="details">
                                  <h6 className="search__peirsans">{chat.sender_full_name}</h6>
                                  <p className="search__peirsans">{chat.content}</p>
                                  <div className="date__format--client search__peirsans">{(chat.showMsg !== true) ? moment(chat.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z") : ''}</div>
                                </div>
                              </li>
                            ) :
                              <div>
                                {
                                  (this.state.userid === chat.sender_id) &&
                                  <li className="you">
                                    <img src={chat.sender_avatar ? chat.sender_avatar : "../../img/default-user.svg"} alt="" />
                                    <div className="details">
                                      <h6 className="search__peirsans">You</h6>
                                      <p className="search__peirsans" spellCheck="true">{chat.content}</p>
                                      <div className="date__format--client search__peirsans">{moment(chat.created).tz(moment.tz.guess()).format("MMMM DD, YYYY hh:mm a z")}</div>
                                    </div>
                                  </li>
                                }
                              </div>
                          }
                        </div>
                      )
                    })
                  ) : ""
                }
              </ul>

              {
                (agentDataArray && agentDataArray.length > 0 && this.state.agentClickable) &&
                <div className="send__wrapper">
                  <input
                    type="text"
                    name="chat_text"
                    spellCheck="true"
                    className="search__peirsans"
                    value={this.state.chat_text}
                    onChange={this.chatHandleChange.bind(this)}
                    placeholder={(this.state.chatAgentName) ? `Chat with ${this.state.chatAgentName}` : "Chat with agent"} />
                  <button className="search__peirsans" onClick={this.sendChatMessage.bind(this)}>Send</button>
                </div>
              }
            </div>
          </div>
        </div>
        {/*****************End***************/}



        {/*THis is model for remove strategist*/}
        <Modal isOpen={this.state.openStrategistModel} center className="updatemodal">
          <div>
            <div className="flexcontent">
              <h2 className="modal-header">Are you sure?</h2>
              <div className="close__btn" onClick={this.onCloseCheckModal.bind(this)}><img src="img/close_btn.svg" /></div>
            </div>
            <div>
              <p className="allpoppadding__para marg25 piersandfonts">
                <br />
              If you select “Yes”, you will be reassigned to a different Strategist.
              <br /><br />
              If you want to tell us more, please include any other feedback. Thanks so much!
            </p>
              <textarea
                rows="4"
                cols="40"
                className="popup__textarea"
                onChange={this.strHandleChange.bind(this)}
              />
              <div className="flexbuttons">
                <button
                  className="btns__fullwidth piersandfonts no-radius yes-remove uppercasefonts"
                  onClick={this.removeStrategist.bind(this)}>Yes</button>
              </div>
            </div>
          </div>
        </Modal>
        {/*THis is end for remove strategist*/}

        {/*THis is model for client feedback*/}
        <Modal isOpen={this.state.feedbackModelOpen} center className="updatemodal piersandfonts">
          <div>
            <div className="flexcontent">
              <h2 className="modal-header piersandfonts">Feedback</h2>
              <div className="close__btn" onClick={this.feedbackCloseClick.bind(this)}><img src="img/close_btn.svg" /></div>
            </div>
            <div>
              <p className="allpoppadding__para marg25 piersandfonts">
                <br />
                  We want to hear from you! Rate your overall Suburban Jungle experience.
              </p>
              <ul className="rating-icons-feedback">
                <li><img onClick={this.rateClick.bind(this, 1)} src={this.state.rating >= 1 ? "img/rating-active.png" : "img/feedback_inactive_rating.png"} /> <span>unhappy</span></li>
                <li><img onClick={this.rateClick.bind(this, 2)} src={this.state.rating >= 2 ? "img/rating-active.png" : "img/feedback_inactive_rating.png"} /></li>
                <li><img onClick={this.rateClick.bind(this, 3)} src={this.state.rating >= 3 ? "img/rating-active.png" : "img/feedback_inactive_rating.png"} /></li>
                <li><img onClick={this.rateClick.bind(this, 4)} src={this.state.rating >= 4 ? "img/rating-active.png" : "img/feedback_inactive_rating.png"} /></li>
                <li><img onClick={this.rateClick.bind(this, 5)} src={this.state.rating == 5 ? "img/rating-active.png" : "img/feedback_inactive_rating.png"} /> <span>excellent</span></li>
              </ul>
              {/*Rating error message*/}
              {this.state.err.rating ?
                <span className="error_field feedback__error">
                  {this.state.err.rating}
                </span> : ''}
              <textarea
                rows="4"
                cols="40"
                name="feedbackText"
                placeholder="If you want to tell us more, please include any other feedback. Thanks so much!"
                className="popup__textarea--feedback"
                onChange={this.feedbackChange.bind(this)}
              />
              <div className="flexbuttons">
                <button
                  className="btns__fullwidth piersandfonts no-radius yes-remove uppercasefonts"
                  onClick={this.feedbackSubmit.bind(this)}>Yes</button>
              </div>
            </div>
          </div>
        </Modal>
        {/*THis is end for client feedback*/}

        {/* This is for town  */}
        <Modal
          isOpen={this.state.townOpenModel}
          onAfterOpen={this.afterTownOpenModel}
          onRequestClose={this.closeTownOpenModel}
          style={customStyles}
          contentLabel="Town Modal">
          <div className="deletealert-custom-ui allpoppadding piersandfonts">
            <div className="flexdiv">
              <h4 className="popupheader__heading">{this.state.townOpenModelTitle}</h4>
              <button
                onClick={() => this.closeTownOpenModel()}
                type="button"
                id="close_user_modal"
                className="close_img close_img--white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="../../img/close_btn.svg" />
              </button>
            </div>
            

            <div className="text-left">

              {!this.state.townOpenModelConfirm && 
                <>
                  <label className="label__text">I want to move {this.state.moveTownName} to the following section:</label>
                  <select value={this.state.moveStatus} className="selectinputs__global" onChange={this.changeTownStatus.bind(this)}>
                    <option value={1}>Favorites and Ready to Explore</option>
                    <option value={3}>Interested but not ready to tour yet</option>
                    <option value={0}>Not Interested</option>
                  </select> 
                  <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={() => {
                    this.confirmMoveTown(this.state.moveTownId, this.state.moveAgentId, this.state.moveStatus)
                  }}>Move</button>                  
                </>
              }

              {this.state.townOpenModelConfirm && 
                <>
                  <p>
                    Once you move {this.state.moveTownName} to "Not Interested", your Strategist and/or the assigned real estate 
                    agent will be notified that you are no longer interested in this town.
                  </p>
                  <p>
                    Reach out to your Strategist if you want to discuss or need assistance!
                  </p>
                  <button className="btns__fullwidth piersandfonts no-radius uppercasefonts" onClick={() => {
                    this.moveTown(this.state.moveTownId, this.state.moveAgentId, this.state.moveStatus)
                  }}>Confirm</button>                  
                </>              
              
              }

            </div>
          </div>
        </Modal>


        {/* ****************Leave Comment Modal**************************** */}
        <div className={this.state.openComment ? "right__chat--section" : "chat__box__open"}>
          <div className={this.state.openComment ? "schedule__sidebar--container comment-wrapper open" : "schedule__sidebar--container"}>

            <div className="">
              <div className="comment-box-wrapper">
                <span className="modalclose" onClick={this.closeCommentModel.bind(this)}><img src="img/close_btn.svg" /></span>
                <h2>Leave a comment</h2>
                <p>Leave a comment or note about {this.state.moveTownName} for your strategist </p>
                <div className="clearfix"></div>
                <textarea
                  placeholder="Write a comment or note…"
                  value={this.state.comment}
                  onChange={this.handleCommentChange.bind(this)}
                  onKeyDown={this.handleKeyDown}
                  style={{ "resize": "none", "overflowY": "hidden" }}
                  rows="6"
                >
                </textarea>
                {this.state.err.comment ?
                  <span className="error_comment_msg">
                    {this.state.err.comment}
                  </span> : ''}

                <div className="text-right">
                  <button className="send-btn" onClick={this.sendComment.bind(this)}>{this.state.comment_id != null ? "EDIT" : "SEND"}</button>
                </div>
              </div>
              <div className="comment-list-wrapper">
                <ul>

                  {
                    (this.state.commentsData && this.state.commentsData.length > 0) ?
                      this.state.commentsData.map((res, idx) => {
                        return (
                          <li key={idx}>
                            <div className="d-flex align-items-center justify-content-start">
                              <div className="profile-image profile-image-40"><img src={res.sender_image ? res.sender_image : "img/default-user.svg"} /></div>
                              <div className="profile-name">{res.sender_full_name}</div>
                              <div className="profile-date">{moment(res.created).tz(moment.tz.guess()).format('LL') + " at " + moment(res.created).tz(moment.tz.guess()).format('LT')}</div>
                            </div>
                            <div className="txt-wrapper">
                              <p>
                                {res.comment}
                              </p>
                            </div>
                            {
                              parseInt(_user.id) === parseInt(res.sender_id) ? (
                                <div className="btn-wrapper">
                                  <a href="javascript:void(0)" className="edit-delete-chat" onClick={this.editComment.bind(this, res.id, _user.id, res.comment)}>Edit</a>
                                  <a href="javascript:void(0)" className="edit-delete-chat" onClick={this.deleteComment.bind(this, res.id)}>Delete</a>
                                </div>
                              )
                                : ''
                            }
                          </li>
                        )
                      }) : ''
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* ******************************************** */}

      </div>
    )
  }
}

export default reduxForm({
  form: 'client-dash',  // a unique identifier for this form
  destroyOnUnmount: true,
  initialValues
})(ClientDashboardComponent)
