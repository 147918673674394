import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import _ from 'lodash'
import $ from 'jquery'
import {getTimeFormate} from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
import BigCalendar from 'react-big-calendar'
import Calendar from 'react-calendar'
import moment from 'moment'
import dates from './dates'
import DatePicker from 'react-datepicker'
import calendar from './calendar.scss'
import styles from './styles.scss'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { logoutInvalidRequest } from '../../utils/helper'
const required = value => value ? undefined : 'Required'
const cookies = new Cookies()

const HOSTNAME = process.env.API_HOSTNAME
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

BigCalendar.momentLocalizer(moment); 
let _user = ''
let forEndDate = ''
let availableDateSlots = ''
let selectedAvailTimeslots = []
let agentDateSlots = ''

class DatePickerCustomInput extends React.Component {

  componentDidMount(){
    document.title = "Jungler: Calendar";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class CalendarForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      openSchedule: false,
      agentOpenSchedule: false,
      startDate:moment(),
      agentStartDate:moment(),
      selectClient: '',
      selectAppointment:'',
      selectStartTime: '', 
      selectEndTime: '',
      selectStrategist: '',
      agentSelectStartTime: '',
      agentSelectEndTime: '',
      add_edit_type: '',
      itemCount: '',
      description: '',
      selectAgent:'',
      selectAgentId: '',
      err1: {},
      agentErr: {},
      err: {},
      startTimeErr: '',
      agentTimeErr: '',
      agentDescription:'',
      availableDates:[],
      startDate:moment()
    }
    this.handleFetchAvailaibility = this.handleFetchAvailaibility.bind(this)
  }

  componentWillMount(){

    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data1 = {}
      data1.user_id  = _user.id
      data1.timezone = _user.time_zone
      this.props.getStrategistApt(data1)
      const data2 = {}
      data2.user_id  = _user.id
      data2.timezone = _user.time_zone
      this.props.getAppointments(data2)
      this.props.getFilterParams()
      this.props.getAllClients()
    
      let StateData = this.props.location && this.props.location.state;
      if(StateData){
        this.setState({ 
          openSchedule : true, 
          agentOpenSchedule: false,
          selectClient: StateData.client_id, 
          startDate: moment(StateData.start_date),
          selectStrategist: StateData.strategists && StateData.strategists.length > 0 && StateData.strategists[0].user_id, 
          selectStartTime:  moment(StateData.start_date).format("HH:mm"),
          selectAppointment: StateData.appointment_type
        })
        selectedAvailTimeslots.push(moment(StateData.start_date).format("HH:mm"))
      }
    }
    this.handleChange = this.handleChange.bind(this)    
    setTimeout(()=>{
      var classname = document.getElementsByClassName("react-calendar__navigation__arrow")
      var myNxtFunction = function() {
        var attribute = this.getAttribute("class")
        if(attribute === "react-calendar__navigation__arrow react-calendar__navigation__next-button"){
          document.getElementsByClassName('rbc-btn-group')[0].children[2].click();
        }
      };
      var myPreFunction = function() {
        var attribute = this.getAttribute("class")
        if(attribute === "react-calendar__navigation__arrow react-calendar__navigation__prev-button"){
          document.getElementsByClassName('rbc-btn-group')[0].children[1].click();
        }
      };
      for (var i = 0; i < classname.length; i++) {
        if(classname[i].getAttribute('class').indexOf("2") ===-1){
          classname[i].addEventListener('click', myNxtFunction, false)
        }
        if(classname[i].getAttribute('class').indexOf("1") ===-1){
          classname[i].addEventListener('click', myPreFunction, false)
        }
      }
    },2000)
  }


  componentWillReceiveProps(nextProps){
    const {filterParams, allclientData} = this.props
    if(nextProps.addStrategyAptPhase === "success"){
      this.props.clearPhase()
      _user = cookies.get('user')
      if(_user) {
        this.setState({userid: _user.id})
        const data = {}
        data.user_id = _user.id;
        data.timezone = _user.time_zone
        this.props.getAppointments(data)
        this.calendarBackground()
        alert('Appointment created successfully')
        window.location.reload();
      }
      this.setState({ openSchedule : false })
    }
    if(nextProps.addAppointmentPhase === "success"){
      this.props.clearAgentPhase()
      if(_user) {
        this.setState({userid: _user.id})
        const data = {}
        data.user_id = _user.id;
        data.timezone = _user.time_zone
        this.props.getAppointments(data)
        this.calendarBackground()
        alert('Appointment created successfully')
        window.location.reload();
      }
      this.setState({ agentOpenSchedule : false })
    }

    if(nextProps.filterParamsPhase === "success"){
      const data1 = {}
      let strategistId = ''
      data1.timezone = _user.time_zone
      data1.date  = moment(this.state.startDate._d).format("YYYY-MM-DD")
      if(_user.role === "superadmin" || _user.role === "admin"){
        if(filterParams &&  filterParams.data && 
            filterParams.data.strategists && 
            filterParams.data.strategists.length > 0){
            strategistId = _.get(filterParams.data.strategists,'[0]id')
            this.props.clearFilterPhase()
            data1.strategist_id  = strategistId        
            this.handleFetchAvailaibility(strategistId)
        }
      } else {
        data1.strategist_id  = strategistId
        strategistId = _user.id
        this.props.clearFilterPhase()
        this.handleFetchAvailaibility(strategistId)
      }
    }

    if(nextProps.allClientPhase === "success"){
      if(allclientData &&  allclientData.data && allclientData.data.length>0) {
         let clientID = _.get(allclientData.data,'[0]client_id')
         this.setState({ selectClient: clientID})
      }
    }

  }

  handleFetchAvailaibility(strategistId){
    this.setState({
        selectStrategist:strategistId
      }, () => {
        let userT = {
          strategist_id:strategistId,
          date:moment().format("YYYY-MM-DD"),
          timezone:_user.time_zone
        }
        this.handleSetDates(userT)
      }
    )
  }

  componentDidMount(){
    this.calendarBackground()
  }

  handleSetDates(data) {

    const queryString = new URLSearchParams({
      date: data.date,
      timezone: data.timezone
    })

    const url = `${SUBURBANJUNGLE_API_HOST}/strategists/${data.strategistId}/availability-dates?${queryString}`
    
    axios({
      url: url,
      data: data 
    })
    .then((res) => {
      if(res && res.data && res.data.data){
        let dateArray = this.formateDates(res.data.data);
        if (dateArray.length > 0) {
          this.setState({ availableDates:dateArray, startDate: dateArray[0]})
          const data1 = {}
          data1.timezone = _user.time_zone
          data1.date  = moment(dateArray[0]).format("YYYY-MM-DD")
          data1.strategist_id  = this.state.selectStrategist
          this.props.getStrategistAvail(data1)
        } else {
          this.setState({availableDates:[]}) 
        }
      } else {
          this.setState({availableDates:[]}) 
      }
    })
      .catch(logoutInvalidRequest)
  }

  formateDates(dateArray){
    let finalArr = [];
    if(dateArray.length > 0){
      dateArray.forEach(function(date){
        finalArr.push(moment(date.date));
      });
    }
    return finalArr;
  }

  handleChange(event) {
    let itemCount = []
    let avilData = this.props.availabilityData && this.props.availabilityData.data && this.props.availabilityData.data.slot
    if(event.target.name === "agentSelectStartTime"){
       itemCount = _.filter(avilData, (avil) => {
        if(avil > event.target.value){
          return avil;
        }
      })
    }
    if(event.target.name === "selectStrategist"){ 
      this.setState({startDate:moment()})
      let userT = {
        strategist_id:event.target.value,
        date:moment().format("YYYY-MM-DD"),
        timezone:_user.time_zone
      }
      this.handleSetDates(userT)
    }    
    this.setState({[event.target.name]: event.target.value, itemCount: itemCount})

  }

  handelDateChange(timezone, id, date ){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
     let _user = cookies.get('user')
      const data = {}
      data.strategist_id  = this.state.selectStrategist
      data.timezone       = timezone
      data.date           = selectDate
    this.props.getStrategistAvail(data)
    this.setState({startDate: date})
  }

  agenthandelDateChange(timezone, date){
    let selectDate =  moment(date._d).format("YYYY-MM-DD")
     let _user = cookies.get('user')
      const data = {}
      data.user_id  = this.state.selectAgentId
      data.timezone = timezone
      data.date     = selectDate
    this.props.getAgentAvailability(data)
    this.setState({agentStartDate: date})
  }

  calendarBackground(){
    setTimeout(()=> {
      let data = this.props.strategistAptData.data;
      if(data && data.length>0){
      for (var i = 0; i < data.length; i++) {
          let calVal = document.getElementsByClassName('react-calendar__tile')
          let calVal2 = Array.from(calVal);
          calVal2.forEach((element)=> {
            // if(moment(data[i].start_date) > moment(new Date())){
              if(moment(element.firstChild.dateTime).format('YYYY-MM-DD')=== moment(data[i].start_date).format('YYYY-MM-DD')){
                element.style.backgroundColor = "#C3CF21"
                element.style.color = "#FFF"
              }
            // }
          });
        }
      }
    }, 1000)

    let redColorValue = document.getElementsByClassName('react-calendar__month-view__days__day--weekend')
    let redVal = Array.from(redColorValue)
    redVal.forEach((val)=> {
      val.style.color = "$primaryColor"
    })
    let navLabel = document.getElementsByClassName('react-calendar__navigation__label'); 
    for (let i = 0; i < navLabel.length; i++) { 
        navLabel[i].style.backgroundColor = "#FFF"
        navLabel[i].disabled = true;
    }
    let next2 = document.getElementsByClassName('react-calendar__navigation__arrow react-calendar__navigation__next2-button');
    for (let i = 0; i < next2.length; i++) { 
        next2[i].style.display = "none";
    }
    var prev2 = document.getElementsByClassName('react-calendar__navigation__arrow react-calendar__navigation__prev2-button');
    for (let i = 0; i < prev2.length; i++) { 
        prev2[i].style.display = "none";
    }
    //document.getElementsByClassName('rbc-toolbar-label')[0] && document.getElementsByClassName('rbc-toolbar-label')[0].remove()
    
  }


  handleSelectEvent(event,target){
    if(event.appointment_type === "Initial strategy session" || event.appointment_type === "Follow up strategy call"){
      if(event.type === "google_calendar"){
        this.setState({ openSchedule: false })
      }else{
        setTimeout(()=>{
          this.setState(
          { 
            openSchedule : true, 
            agentOpenSchedule: false,
            selectClient: event.client_id, 
            startDate: moment(event.start),
            selectStrategist: event.user_id, 
            selectStartTime:  moment(event.start).format("HH:mm"),
            selectAppointment: event.appointment_type, 
            description: event.description
          }, () => {
              let userT = {
                strategist_id:event.user_id,
                date:moment(event.start).format("YYYY-MM-DD"),
                timezone:_user.time_zone
              }
              this.handleSetDates(userT)  
          }
        )
        }, 100)
      }
    }else{
        if(event.type === "google_calendar"){
          this.setState({ openSchedule: false })
        }else{
          let agent_full_name = event.agent_first_name+' '+event.agent_last_name
          const data = {}
          data.user_id  = event.agent_id
          data.timezone = _user.time_zone
          data.dateType = "forAllDate"
          data.date     = moment(this.state.startDate._d).format("YYYY-MM-DD")
          this.props.getAgentAvailability(data)

          setTimeout(()=>{
            this.setState({
              agentOpenSchedule: true, 
              openSchedule : false,
              selectClient: event.client_id,
              startDate: moment(event.start), 
              selectAgent: agent_full_name,
              agentSelectStartTime:moment(event.start).format("HH:mm"),
              agentSelectEndTime:moment(event.end).format("HH:mm"),
              selectAppointment: event.appointment_type, 
              selectAgentId: event.agent_id,
              agentDescription: event.description
            })
          }, 100)
        }
    }
  }

  handleSelect(e){
   this.setState({ agentOpenSchedule: false,  openSchedule : false})
  }


  changeMonth(event){
    this.calendarBackground()
  }

  openScheduleBar(e) {
    this.setState({ 
      openSchedule : true,
      add_edit_type: 'add_schedule'
    })
  }

  closeScheduleBar(){
    this.setState({ 
      openSchedule : false, 
      selectClient: '', 
      selectStrategist: '',
      selectStartTime: '', 
      selectEndTime: '', 
      selectAppointment: '', 
      description: ''
    })
  }

  closeAgentScheduleBar(){
    this.setState({agentOpenSchedule: false})
  }

  submitSchedulingData(timezone, userId, event){
    this.setState({ add_edit_type: "edit_schedule" })
    const err = {}
    const { selectStartTime, selectEndTime, selectClient, selectAppointment, textarea,
           startDate, selectStrategist, description } = this.state
    
    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (selectStartTime === '' || selectStartTime === "Start Time" || selectStartTime.trim() === '') {
      err.selectStartTime = 'Select Start Time'
    }
    if(description == null){
      this.state.description = ''
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      let date =  moment(startDate._d).format("YYYY-MM-DD")
      let data = {}
      data.client_id        = this.state.selectClient
      data.appointment_type = selectAppointment
      data.date             = date
      data.timezone         = timezone
      data.description      = this.state.description
      data.time             = selectStartTime
      data.strategist_id    = selectStrategist
      this.props.addStrategistApt(data)
     }
  }


  agentSubmitSchedulingData(timezone, userId, event){
    this.setState({ add_edit_type: "edit_schedule" })
    const err = {}
    const { agentSelectStartTime, agentSelectEndTime, selectClient, selectAppointment, textarea,
           agentStartDate, selectAgentId } = this.state

    if (selectAppointment === '' || selectAppointment === "Select the type of Appointment" || selectAppointment.trim() === '') {
      err.selectAppointment = 'Please Select Appointment'
    }
    if (agentSelectStartTime === '' || agentSelectStartTime === "Start Time" || agentSelectStartTime.trim() === '') {
      err.agentSelectStartTime = 'Select Start Time'
    }
    if (agentSelectEndTime === '' || agentSelectEndTime === "End Time" || agentSelectEndTime.trim() === '') {
      err.agentSelectEndTime = 'Select End Time'
    }
    if (selectAgentId === '' || selectAgentId === "Select the strategist" ) {
      err.selectAgentId = 'Select the Agent'
    }
    if(this.state.agentDescription == null){
      this.state.agentDescription = ''
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      let date =  moment(agentStartDate._d).format("YYYY-MM-DD")
      let data = {}
       data.timezone = timezone
       data.user_id  = selectAgentId
       data.appointment_type = selectAppointment
       data.date     = date
       data.client_id = this.state.selectClient
       data.start_time = agentSelectStartTime
       data.end_time   = agentSelectEndTime
       data.description = this.state.agentDescription
       this.props.addAgentAppointment(data)
     }
  }

  render() {
    const {
      strategistAptData,
      allclientData,
      availabilityData,
      strategistAvailabilityData,
      strategistAvailabilityPhase,
      filterParams } = this.props

    const {
      availableDates
    } = this.state 

    $(document).ready(function(){
      $('.rbc-toolbar button:contains("agenda")').text('List');
    })

    const {
      selectStrategist
    } = this.state

  let events= []
  let appointmentData = ''
    if(strategistAptData && strategistAptData.data && 
      strategistAptData.data.length > 0){
        appointmentData = _.get(strategistAptData, 'data')
          appointmentData && appointmentData.map((appData,index) => {
            let obj = []
            if(appData.type === "jungler_calendar"){
              obj = {
                id   : appData.client_id,
                title: <div dangerouslySetInnerHTML={{ 
                  __html: moment(appData.start_date).format("HH:mm a")+'<br/>'+
                          '<h4>'+appData.client_first_name+' '+appData.client_last_name+'</h4>'+
                          'Type: '+appData.appointment_type+'<br/>'+
                          'Strategist: '+appData.strategist_first_name+' '+ appData.strategist_last_name}} />,
                start: new Date(appData.start_date),
                end  : new Date(appData.end_date),
                strategist_first_name: appData.strategist_first_name,
                strategist_last_name: appData.strategist_last_name,
                client_first_name: appData.client_first_name,
                client_last_name: appData.client_last_name,
                client_id: appData.client_id,
                user_id: appData.user_id,
                appointment_type: appData.appointment_type,
                description: appData.description,
                role: appData.role,
                agent_first_name: appData.agent_first_name,
                agent_last_name: appData.agent_last_name,
                agent_id: appData.agent_id,
                type: appData.type
              }
            }else{
              obj = {
                id   : appData.client_id,
                title: <div dangerouslySetInnerHTML={{ 
                  __html: moment(appData.start_date).format("HH:mm a")+'<br/>'+
                          '<h5>'+appData.description+'</h5>'+
                          'Type: '+'Google Calendar'}} />,
                start: new Date(appData.start_date),
                end  : new Date(appData.end_date),
                strategist_first_name: appData.strategist_first_name,
                strategist_last_name: appData.strategist_last_name,
                client_first_name: appData.client_first_name,
                client_last_name: appData.client_last_name,
                client_id: appData.client_id,
                user_id: appData.user_id,
                appointment_type: appData.appointment_type,
                description: appData.description,
                role: appData.role,
                agent_first_name: appData.agent_first_name,
                agent_last_name: appData.agent_last_name,
                agent_id: appData.agent_id,
                type: appData.type
              }
            }
            events.push(obj)
         })
  }

  let clientList = ''
  if(allclientData &&  allclientData.data && allclientData.data.length>0) {
    clientList = allclientData.data.map((val, index) => {
      return (
        <option key={index} value={val.client_id}>{val.first_name +' '+ val.last_name}</option>
      )
    });
  }


  let strategistList = ''
  if(filterParams &&  filterParams.data && filterParams.data.strategists && filterParams.data.strategists.length>0) {
    strategistList = filterParams.data.strategists.map((strategist, idx) => {
      return (
        <option key={idx} value={strategist.id}>{strategist.first_name +' '+ strategist.last_name}</option>
      )
    });
  }


  let appoinment = config.strategistAppointment.map((val, index) => {
    return (
      <option key={index} value={val.name}>{val.name}</option>
    )
  });

  let agentAppoinment = config.typeOfAppointments.map((val, index) => {
    return (
      <option key={index} value={val.name}>{val.name}</option>
    )
  });

  if( this.state.itemCount && this.state.itemCount.length > 0 ){
    forEndDate = this.state.itemCount.map((endTime, endIndex) =>{
        return(
         <option key={endIndex} value={endTime}>{endTime}</option>
        )
    });
  }

  if(strategistAvailabilityData == '' || strategistAvailabilityData.data && strategistAvailabilityData.data.length === 0){
    this.state.err1.startTimeErr = 'In this date no time slot available'
  }else{
    this.state.err1.startTimeErr = ''
  }

  availableDateSlots = strategistAvailabilityData && strategistAvailabilityData.data && strategistAvailabilityData.data.length > 0 && strategistAvailabilityData.data.map((slotval, indexslot) => { 
    let pos = strategistAvailabilityData.data.length - 1
    if (pos === indexslot) {
      return(
        <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
      )
    } else {
      return(
        <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
      )
    }
  });

  if(strategistAvailabilityData && strategistAvailabilityData.length === 0){
    availableDateSlots = selectedAvailTimeslots && selectedAvailTimeslots.length > 0 && selectedAvailTimeslots.map((slotval, indexslot) => { 
      let pos = selectedAvailTimeslots.length - 1
      if (pos === indexslot) {
        return(
          <option disabled={pos} key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
        )
      } else {
        return(
          <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
        )
      }
    });
  }
  
  agentDateSlots = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => { 
    let pos = availabilityData.data.slot.length - 1
    if (pos === indexslot) {
      return(
        <option disabled={pos} key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
      )
    } else {
      return(
        <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
      )
    }
  });


    return (
      <div>
        <div className="calendar__page--container">
          <div className="calendar__page--header">
            <div className="calendar__page--header-title">
              Jungler Calendar
            </div>

            <button 
              type="button" className="appointment-btn"
              onClick={this.openScheduleBar.bind(this)}>
               + Appointment
            </button>

            <div className="calendar__page--close">
               <img className="forCursor" onClick={this.props.history.goBack} src="img/close.svg"/>
            </div>
          </div>

          <div className="calendar__page--wrapper">
            <div className="calendar__page--small-calendar">
              <Calendar
                activeStartDate={new Date()}
                value={new Date()}
                onActiveDateChange={this.changeMonth.bind(this)}
                 />
            </div>

            <div className="calendar__page--big-calendar">
              <BigCalendar
                selectable
                events={events}
                views={['agenda', 'month', 'week', 'day']}
                defaultView={'agenda'}
                step={30}
                onSelectEvent={this.handleSelectEvent.bind(this)}
                showMultiDayTimes
                defaultDate={new Date()}
                onSelectSlot={this.handleSelect.bind(this)}
              />
            </div>
          </div>
        </div>


      {/*This schedult for strategist*/}
        <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            + Appointment
            <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body">
                    <div className="schedule__sidebar--form--wrapper">

                      <div className="form-group">
                        <label className="text__label--sm">Client Name</label>
                        <select 
                          className="form-control custom-select" 
                          name="selectClient" 
                          value = {this.state.selectClient}  
                          onChange={this.handleChange.bind(this)}>
                           {clientList}
                        </select>
                      </div>

                     {
                      (_user.role == 'superadmin' || _user.role == 'admin')?
                       <div>
                        <div className="form-group">
                          <label className="text__label--sm">Strategist Name</label>
                          <select 
                            className="form-control custom-select" 
                            name="selectStrategist" 
                            value = {this.state.selectStrategist}  
                            onChange={this.handleChange.bind(this)}>
                             {strategistList}
                          </select>
                        </div>
                       </div>
                       :
                        <div>
                        <div className="form-group">
                          <label className="text__label--sm">Strategist Name</label>
                          <select 
                            className="form-control custom-select" 
                            name="selectStrategist" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                            <option key={0} value={_user.id}>{_user.first_name+' '+_user.last_name}</option>
                          </select>
                        </div>
                        </div>
                     }
                      
                      <div className="form-group">
                        <label className="text__label--sm">Appointment Type</label>
                        <select 
                          className="form-control custom-select" 
                          name="selectAppointment" 
                          value = {this.state.selectAppointment}  
                          onChange={this.handleChange}>
                          <option>Select the type of Appointment</option>
                            {appoinment}
                        </select>
                      </div>
                      {this.state.err.selectAppointment ?
                      <span className="error_msg">
                      {this.state.err.selectAppointment}
                      </span> : '' }

                      {
                        availableDates.length > 0 ?
                         <div>
                          <div className="form-group">
                             <DatePicker
                              selected={this.state.startDate}
                              customInput={<DatePickerCustomInput />}
                              onChange={this.handelDateChange.bind(this, _user.time_zone, _user.id)}
                              includeDates={availableDates.length > 0 ? availableDates : ''}
                              showTimeSelect
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              className="form-control custom-select date__picker"
                              value={this.state.startDate}
                              placeholderText="Pick a date">
                            </DatePicker>
                          </div>

                          <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text__label--sm">Start</label>
                              {strategistAvailabilityPhase === "loading" ?
                                <span  className="time_loading">
                                  Loading ...
                                </span>
                                :
                                <select 
                                  name='selectStartTime' 
                                  className="form-control custom-select-small" 
                                  value={this.state.selectStartTime}  
                                  onChange={this.handleChange.bind(this)}>
                                  <option>Time</option>
                                   {availableDateSlots}
                                </select>
                              }
                            </div>
                            {this.state.err1.startTimeErr ?
                            <span className="error_msg">
                            {this.state.err1.startTimeErr}
                            </span> : '' }
                          </div>
                          <div className="col-md-6">
                            <p>
                              You only need to put in your start time because we have already defined the length of the appointment in Acuity. Once you create this it will be created in Acuity as well.  
                              </p>
                            </div>
                            </div>
                          </div>
                        :<p>Date Not Available</p>
                      }


                      <div className="form-group rem__marg--btm">
                        <label className="text__label--sm">Appointment Description</label>
                        <textarea 
                          name="description" 
                          className="form-control custom-textarea"
                          onChange={this.handleChange}
                          value={this.state.description}
                          placeholder="Enter description here..."></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="schedule__sidebar--footer">
                    <button 
                      className="btn btn__green btn__green--lg btn-block btn__schedule" 
                      onClick={this.submitSchedulingData.bind(this, _user.time_zone, _user.id, )}>Schedule</button>
                  </div>
        </div>



      {/*-----------This schedult for agent-----------*/}
        <div className={this.state.agentOpenSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
          <div className="schedule__sidebar--header">
            + Appointment
            <div className="close-btn" onClick={this.closeAgentScheduleBar.bind(this)}>
                <img src="img/close.svg"/>
            </div>
          </div>

          <div className="schedule__sidebar--body">
                    <div className="schedule__sidebar--form--wrapper">

                      <div className="form-group">
                        <label className="text__label--sm">Client Name</label>
                        <select 
                          className="form-control custom-select" 
                          name="selectClient" 
                          value = {this.state.selectClient}  
                          onChange={this.handleChange.bind(this)}>
                           {clientList}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="text__label--sm">Agent Name</label>
                        <select 
                          className="form-control custom-select" 
                          name="selectAgentId" 
                          value = {this.state.selectAgentId}  
                          onChange={this.handleChange.bind(this)}>
                          <option>Select the Agent</option>
                          <option key={0} value={this.state.selectAgentId}>{this.state.selectAgent}</option>
                        </select>
                      </div>
                      { this.state.err.selectAgent ?
                      <span className="error_msg">
                      {this.state.err.selectAgent}
                      </span> : '' }


                      <div className="form-group">
                        <label className="text__label--sm">Appointment Type</label>
                        <select 
                          className="form-control custom-select" 
                          name="selectAppointment" 
                          value = {this.state.selectAppointment}  
                          onChange={this.handleChange}>
                          <option>Select the type of Appointment</option>
                            {agentAppoinment}
                        </select>
                      </div>
                      {this.state.err.selectAppointment ?
                      <span className="error_msg">
                      {this.state.err.selectAppointment}
                      </span> : '' }
                      <div className="form-group">
                         <DatePicker
                          selected={this.state.agentStartDate}
                          customInput={<DatePickerCustomInput />}
                          onChange={this.agenthandelDateChange.bind(this, _user.time_zone)}
                          showTimeSelect
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          minDate={new Date()}
                          className="form-control custom-select date__picker"
                          value={this.state.agentStartDate}
                          placeholderText="Pick a date">
                        </DatePicker>
                      </div>
                      <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text__label--sm">Start</label>
                          <select 
                            name='agentSelectStartTime' 
                            className="form-control custom-select-small" 
                            value={this.state.agentSelectStartTime}  
                            onChange={this.handleChange.bind(this)}>
                            <option>Time</option>
                              {agentDateSlots}
                          </select>
                        </div>
                        {/*this.state.err.agentSelectStartTime ?
                        <span className="error_msg">
                        {this.state.err.agentSelectStartTime}
                        </span> : '' }
                        {this.state.agentErr.agentTimeErr ?
                        <span className="error_msg">
                        {this.state.agentErr.agentTimeErr}
                        </span> : '' */}
                      
                      
                      </div>
                      

                      {<div className="col-md-6">
                        <div className="form-group">
                          <label className="text__label--sm">End</label>
                          <select 
                            name="agentSelectEndTime" 
                            className="form-control custom-select-small" 
                            value = {this.state.agentSelectEndTime}  
                            onChange={this.handleChange.bind(this)}>
                            <option>End Time</option>
                             {forEndDate}
                          </select>
                        </div>
                        {this.state.err.agentSelectEndTime ?
                      <span className="error_msg">
                      {this.state.err.agentSelectEndTime}
                      </span> : '' }
                      </div>}
                      </div>

                      <div className="form-group rem__marg--btm">
                        <label className="text__label--sm">Appointment Description</label>
                        <textarea 
                          name="agentDescription" 
                          className="form-control custom-textarea"
                          onChange={this.handleChange}
                          value={this.state.agentDescription}
                          placeholder="Enter description here..."></textarea>
                      </div>

                    </div>
                  </div>
                  <div className="schedule__sidebar--footer">
                    <button 
                      className="btn btn__green btn__green--lg btn-block btn__schedule" 
                      onClick={this.agentSubmitSchedulingData.bind(this, _user.time_zone, _user.id, )}>Schedule</button>
                  </div>
        </div>


      </div>
    )
  }
}

export default reduxForm({
  form: 'calendar',  // a unique identifier for this form
  destroyOnUnmount: true,
})(CalendarForm)
