import React from 'react'
import ReactModal from 'react-modal'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { get, map, compact, find } from 'lodash'
import NumberFormat from 'react-number-format'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Cookies from 'universal-cookie'

import { CloseIcon } from '../../../../../components/icons'
import Validation from '../../../../../config/validation'

const cookies = new Cookies()
const multiSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '8px 12px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'none'
  }),
  menu: () => ({
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '5px 10px 30px #E5E5E5'
  })
}

export default function EditProspectusInformation({ editProspectModel, closeEditProspectModal, ...props }) {
// export default function EditProspectusInformation({ editProspectModel, closeEditProspectModal, openBeforePreviewModel, ...props }) {
  const prices = get(props, 'filterParams.data.price_brackets', [])
  const statuses = get(props, 'filterParams.data.statuses', [])
  const suburbs = get(props.filterParams, 'data.suburbs', [])
  const allStrategists = get(props, 'filterParams.data.strategists', [])
  // const preAuth = cookies.get('i_user')
  // if (preAuth && (preAuth.role !== 'superadmin' && preAuth.role !== 'admin')) {
  //   allStrategists = filter(allStrategists, (s) => s.role !== 'former-strategist-inactive')
  // } else if (!preAuth && user && user.role == 'strategist') {
  //   allStrategists = filter(allStrategists, (s) => s.role !== 'former-strategist-inactive')
  // }

  const clientId = props.match.params.id
  const typeOfAdvices = [
    "Moving from urban to suburban",
    "Rental",
    "Second home",
    "Empty nesters/resizers",
    "Urban jungle(staying in city!)",
    "Relocation! moving from one city to another",
    "Moving from suburb to suburb"
  ]
  // submit handle on call api
  const handleSubmit = (data) => {
    props.createProspectus(data)
  }

  // set unset type of advice from selected adive t
  const handleSelectAdviceType = (type, selectedAdviceType, setFieldValue) => {
    if (selectedAdviceType.indexOf(type) === -1) {
      selectedAdviceType.push(type)
    } else {
      selectedAdviceType.splice(selectedAdviceType.indexOf(type), 1)
    }
    setFieldValue("typeOfAdvices", selectedAdviceType)
  }
  // status options list from filter props
  const statusOptions = map(statuses, (status, index) => (
    <option key={index} value={status.name}>{status.name}</option>
  ))
  // suburbs options list from filter props
  const suburbsOptions = map(suburbs, (suburb, index) => {
      return { label: suburb.name, value: suburb.id }
   })
  // price options list from filter props .map((pricesval, priceindx) => {
  const priceOptions = prices.map((price, priceIndx) => {
      let priceRange = `$${price.price_min.toLocaleString()} - $${price.price_max.toLocaleString()}`
      if(price.price_min.toLocaleString() == 0){
        priceRange = `$${price.price_min.toLocaleString()}`
      }
      return (
        <option key={priceIndx} value={price.id}>{priceRange}</option>
      )
    })
  const budgetOptionItem = (budgetPrice) => {
      const priceRange = (budgetPrice && budgetPrice.split('-')) || []
      const price_min = get(priceRange, "[0]", '').trim() ? parseInt(get(priceRange, "[0]", '').trim()) : 0
      const price_max = get(priceRange, "[1]", '').trim() ? parseInt(get(priceRange, "[1]", '').trim()) : 0
      const priceItem = find(prices, { price_min, price_max })
      return priceItem ? priceItem.id : ''
  }
  // strategist option list for multiple select
  const strategistOptions = map(allStrategists, (strategist, index) => ({
      label: `${strategist.strategist_name}${strategist.role === 'former-strategist-inactive' ? '(inactive)' : ''}`,
      value: strategist.id
  }))
  const handleMultiSelectionOption = (options, event, setFieldValue) => {
    const selectedOptions = map(options, option => option.value)
    setFieldValue(event, selectedOptions)
  }
  const selectedAssignedStrategist = (assignedStrategist) => {
    return compact(map(strategistOptions, strategist => {
      if (assignedStrategist.includes(strategist.value)) {
        return { label: strategist.label, value: strategist.value }
      }
    }))
  }
  const selectedAssignedSuburbs = (assignedSuburb) => {
    return compact(map(suburbsOptions, suburb => {
      if (assignedSuburb.includes(suburb.value)) {
        return { label: suburb.label, value: suburb.value }
      }
    }))
  }
  const prospect = get(props, 'clientDetail', {})
  return (
    <ReactModal
      isOpen={editProspectModel}
      // onAfterOpen={(e) => this.afterOpenModal(e)}
      onRequestClose={closeEditProspectModal}
      contentLabel="Edit Client Information"
      portalClassName="react-modal"
    >
      <div className="react-modal-dialog react-modal-dialog-centered">
        <div className="react-modal-header">
          <h5 className="react-modal-title">Client Information</h5>
          <button type="button" className="btn react-modal-close" onClick={closeEditProspectModal}><CloseIcon /></button>
        </div>
        <div className="react-modal-body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              // initial values
              clientId,
              status: get(prospect, 'status', ''),
              budget: get(prospect, 'price_range', '') ? budgetOptionItem(prospect.price_range) : '',
              rentalBudget: get(prospect, 'rental_budget', ''),
              signupSource: get(prospect, 'signup_source', ''),
              timing: get(prospect, 'timing', ''),
              assignedSuburbs: map(prospect.suburbs, suburb => suburb.suburb_id),
              assignedStrategist: map(prospect.strategists, strategist => strategist.user_id),
              typeOfAdvices: get(prospect, 'advice', '') ? prospect.advice.split(',') : [],
              primaryCompany: get(prospect, 'primary_company'),
              secondaryCompany: get(prospect, 'secondary_company')
            }}
            validationSchema={Validation.editProspectusInformation}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              handleSubmit(values)
              closeEditProspectModal()
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              setFieldValue
            }) => (
                <Form noValidate>
                  <div className="form-group material-textfield">
                    <Field
                      as="select"
                      name="status"
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                      // onChange={(e) => { 
                      //   setFieldValue('status', e.target.value)
                      //   if(/\s?closed\s?/i.test(e.target.value)) {
                      //     openBeforePreviewModel()
                      //   }
                      // }}
                    >
                      <option value="">Select the Status</option>
                      {statusOptions}
                    </Field>
                    <label className="material-textfield-label label-lg">Status</label>
                    <ErrorMessage className="invalid-feedback" name="status" component="div" />
                  </div>

                  <div className="form-group material-textfield">
                      <Field
                        as="select"
                        className="form-control custom__select material-textfield-input textfield-input-lg"
                        name="timing"
                      >
                        <option value="">Select</option>
                        <option value="ASAP">ASAP</option>
                        <option value="Uncertain">Uncertain</option>
                        <option value="Spring 2021">Spring 2021</option>
                        <option value="Fall 2021">Fall 2021</option>
                        <option value="Spring 2022">Spring 2022</option>
                        <option value="Fall 2022">Fall 2022</option>
                        <option value="Spring 2023">Spring 2023</option>
                        <option value="Fall 2023">Fall 2023</option>
                        <option value="Spring 2024">Spring 2024</option>
                        <option value="Fall 2024">Fall 2024</option>
                        <option value="Spring 2025">Spring 2025</option>
                        <option value="Fall 2025">Fall 2025</option>
                        <option value="Spring 2026">Spring 2026</option>
                        <option value="Fall 2026">Fall 2026</option>
                      </Field>
                      <label className="material-textfield-label label-lg">Timing</label>
                      <ErrorMessage className="invalid-feedback" name="timing" component="div" />
                  </div>
                  <div className="form-group material-textfield">
                    <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
                      <ReactMultiSelectCheckboxes
                        styles={multiSelectStyles}
                        width="100%"
                        name="assignedSuburbs"
                        options={suburbsOptions}
                        value={selectedAssignedSuburbs(values.assignedSuburbs)}
                        onChange={(event) => handleMultiSelectionOption(event, 'assignedSuburbs', setFieldValue)}
                      />
                      <label className="multiselect__checkboxes__field__label">Select the Suburb</label>
                    </div>
                    <ErrorMessage className="invalid-feedback" name="suburb" component="div" />
                  </div>
                  <div className="form-group material-textfield">
                      <Field
                        as="select"
                        className="form-control custom__select material-textfield-input textfield-input-lg"
                        name="signupSource"
                      >
                        <option value="sbj">Suburban Jungle</option>
                        <option value="ubj">Urban Jungle</option>
                      </Field>
                      <label className="material-textfield-label label-lg">Signup Source</label>
                      <ErrorMessage className="invalid-feedback" name="signupSource" component="div" />
                  </div>
                  <div className="form-group material-textfield">
                    <Field
                      as="select"
                      name="budget"
                      className="form-control custom__select material-textfield-input textfield-input-lg"
                    >
                      <option value="">Select the Budget</option>
                      {priceOptions}
                    </Field>
                    <label className="material-textfield-label label-lg">Budget</label>
                    <ErrorMessage className="invalid-feedback" name="budget" component="div" />
                  </div>
                  <div className="form-group material-textfield">
                     <NumberFormat
                        name="rentalBudget"
                        className="form-control material-textfield-input textfield-input-lg"
                        value={values.rentalBudget}
                        onChange={({target}) => setFieldValue('rentalBudget', target.value.replace(/\D/g, ''))}
                        thousandSeparator={true}
                        prefix="$"
                        required
                      /> 
                    <label className="material-textfield-label label-lg">Rental Budget (monthly) </label>
                    <ErrorMessage className="invalid-feedback" name="rentalBudget" component="div" />
                  </div>
                  <div className="form-group material-textfield">
                    <div className="multiselect__checkboxes__field multiselect__checkboxes__field__lg">
                      <ReactMultiSelectCheckboxes
                        styles={multiSelectStyles}
                        width="100%"
                        name="assignedStrategist"
                        options={strategistOptions}
                        value={selectedAssignedStrategist(values.assignedStrategist)}
                        onChange={(event) => handleMultiSelectionOption(event, 'assignedStrategist', setFieldValue)}
                      />
                      <label className="multiselect__checkboxes__field__label">Strategist(s)</label>
                    </div>
                    <ErrorMessage className="invalid-feedback" name="strategist" component="div" />
                  </div>

                  {/*<div className="form-group info__block__wrap">*/}
                  {/*  <label className="label__text__sm">Type of Advice</label>*/}
                  {/*  <ul className="radiobox__list vertical">*/}
                  {/*    {map(typeOfAdvices, (typeOfAdvice, index) => (*/}
                  {/*      <li key={index}>*/}
                  {/*        <label className="custom__checkbox">*/}
                  {/*          {typeOfAdvice}*/}
                  {/*          <input*/}
                  {/*            type="checkbox"*/}
                  {/*            defaultChecked={values.typeOfAdvices.indexOf(typeOfAdvice) !== -1}*/}
                  {/*            className="custom__checkbox__input"*/}
                  {/*            onClick={() => handleSelectAdviceType(typeOfAdvice, values.typeOfAdvices, setFieldValue)}*/}
                  {/*            required*/}
                  {/*          />*/}
                  {/*          <span className="custom__checkmark"></span>*/}
                  {/*        </label>*/}
                  {/*      </li>*/}
                  {/*    ))}*/}
                  {/*  </ul>*/}
                  {/*  <ErrorMessage className="invalid-feedback" name="typeOfAdvices" component="div" />*/}
                  {/*</div>*/}
                  <div>
                    <button className="btn btn__btn btn__lg btn-dark w__100" type="submit" disabled={isSubmitting}>Save</button>
                  </div>
                </Form>
              )}
          </Formik>
        </div>
      </div>
    </ReactModal>
  )
}
