/* eslint-disable */
/* eslint no-magic-numbers: 0 */
import MobileDetect from 'mobile-detect'
import Moment from 'moment-timezone'
// import ScreenFull from 'screenfull'

// if (ScreenFull.enabled) {
//   ScreenFull.request()
// }

const detectWebView = () => {
  const isUIwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)

  if (isUIwebview) {
    return true
  } else {
    return false
  }
}

const mobileDetector = new MobileDetect(window.navigator.userAgent)


export default {
  Photo: {
    MaxWidth: 1080,
    MaxHeight: 633,
    PreviewWidth: 50,
    PreviewHeight: 50
  },
  md: mobileDetector,
  isMobile: !!mobileDetector.mobile(),
  isWebView: detectWebView(),
  LocalStorageKeys: {
    Authorization: 'Authorization',
    Credentials: 'credentials'
  },
  defaultPageSize: 100,
  pageSizeOptions: ['20', '40', '60', '80', '100'],
  cities: [
    {"label":"New York City","value":"New York City"},
    {"label":"San Francisco","value":"San Francisco"},
    {"label":"Dallas/Ft. Worth","value":"Dallas/Ft. Worth"},
    {"label":"Chicago","value":"Chicago"},
    {"label":"Southern California","value":"Southern California"},
    {"label":"Boston","value":"Boston"},
    {"label":"Washington D.C.","value":"Washington D.C."},
    {"label":"Hamptons","value":"Hamptons"}
    
    //,{"label":"Boca","value":"Boca"}
  ],
  clientAgentAppointment:[
    {"id":"1", "value":"Initial Agent Town Call", "name":"Initial Agent Town Call", "label": "Initial Agent Town Call"},
    {"id":"2", "value":"Initial Town Tour", "name":"Initial Town Tour", "label":"Initial Town Tour"},
    {"id":"3", "value":"Follow up Town Tour", "name":"Follow up Town Tour", "label":"Follow up Town Tour"},
    {"id":"5", "value":"Inspection", "name":"Inspection", "label":"Inspection"},
    {"id":"6", "value":"Closing", "name":"Closing", "label":"Closing"},
  ],

  /*Before work this type of code in typeaOfAppointment*/
  // typeOfAppoinment:[
  // {"id":"1","name":"Initial agent call"},
  // {"id":"2","name":"Follow up agent call"},
  // {"id":"3","name":"Initial agent town tour"},
  // {"id":"4","name":"Follow up agent town tour"},
  // {"id":"5","name":"Inspection"},
  // {"id":"6","name":"Closing"},
  // ],

  /*Now change for follow up agent town tour to follow up agent visit*/
  typeOfAppointments:[
      {"id":"1","value":"Initial agent call","name":"Initial agent call"},
      {"id":"2","value":"Follow up agent call","name":"Follow up agent call"},
      {"id":"3","value":"Initial agent town tour","name":"Initial agent town tour"},
      {"id":"4","value":"Follow up agent town tour","name":"Follow up agent visit"},
      {"id":"5","value":"Inspection","name":"Inspection"},
      {"id":"6","value":"Closing","name":"Closing"},
  ],
  strategistAppointment:[
  {"id":"1", "value":"Initial Strategy Session", "name":"Initial Strategy Session", "label": "Initial Strategy Session"},
  {"id":"2", "value":"Follow up strategy call", "name":"Follow up strategy call", "label": "Follow up strategy call"},
  ],
  roleList:[
    {"id":"1","key":"superadmin","name":"superadmin"},
    {"id":"2","key":"admin","name":"admin"},
    {"id":"3","key":"strategist","name":"strategist"},
    {"id":"9","key":"former-strategist-inactive","name":"former strategist (inactive)"},
    {"id":"4","key":"agent","name":"agent"},
    {"id":"5","key":"prospect-agent","name":"prospect agent"},
    {"id":"8","key":"former-agent","name":"former agent"},
    {"id":"10","key":"business development","name":"business development"},
    {"id":"11","key":"former business development","name":"former business development"},
    // {"id":"6","key":"local","name":"local"},
    // {"id":"7","key":"former-local","name":"former local"},    
  ],
  entries: [
    {"label":"20","value":'20'},
    {"label":"50","value":'50'},
    {"label":"100","value":'100'},
    {"label":"250","value":'250'},
    {"label":"500","value":'500'},
    {"label":"1000","value":'1000'},
    {"label":"2000","value":'2000'},
    {"label":"3000","value":'3000'},
    {"label":"4000","value":'4000'},
    {"label":"5000","value":'5000'},
    {"label":"6000","value":'6000'},
    {"label":"7000","value":'7000'}
  ],
  timings:[
    {"id":"0","time":"00:00:00"},
    {"id":"1","time":"00:15:00"},
    {"id":"2","time":"00:30:00"},
    {"id":"3","time":"00:45:00"},

    {"id":"4","time":"01:00:00"},
    {"id":"5","time":"01:15:00"},
    {"id":"6","time":"01:30:00"},
    {"id":"7","time":"01:45:00"},

    {"id":"8","time":"02:00:00"},
    {"id":"9","time":"02:15:00"},
    {"id":"10","time":"02:30:00"},
    {"id":"11","time":"02:45:00"},

    {"id":"12","time":"03:00:00"},
    {"id":"13","time":"03:15:00"},
    {"id":"14","time":"03:30:00"},
    {"id":"15","time":"03:45:00"},

    {"id":"16","time":"04:00:00"},
    {"id":"17","time":"04:15:00"},
    {"id":"18","time":"04:30:00"},
    {"id":"19","time":"04:45:00"},

    {"id":"20","time":"05:00:00"},
    {"id":"21","time":"05:15:00"},
    {"id":"22","time":"05:30:00"},
    {"id":"23","time":"05:45:00"},

    {"id":"24","time":"06:00:00"},
    {"id":"25","time":"06:15:00"},
    {"id":"26","time":"06:30:00"},
    {"id":"27","time":"06:45:00"},

    {"id":"28","time":"07:00:00"},
    {"id":"29","time":"07:15:00"},
    {"id":"30","time":"07:30:00"},
    {"id":"31","time":"07:45:00"},

    {"id":"32","time":"08:00:00"},
    {"id":"33","time":"08:15:00"},
    {"id":"34","time":"08:30:00"},
    {"id":"35","time":"08:45:00"},

    {"id":"36","time":"09:00:00"},
    {"id":"37","time":"09:15:00"},
    {"id":"38","time":"09:30:00"},
    {"id":"39","time":"09:45:00"},

    {"id":"40","time":"10:00:00"},
    {"id":"41","time":"10:15:00"},
    {"id":"42","time":"10:30:00"},
    {"id":"43","time":"10:45:00"},

    {"id":"44","time":"11:00:00"},
    {"id":"45","time":"11:15:00"},
    {"id":"46","time":"11:30:00"},
    {"id":"47","time":"11:45:00"},

    {"id":"48","time":"12:00:00"},
    {"id":"49","time":"12:15:00"},
    {"id":"50","time":"12:30:00"},
    {"id":"51","time":"12:45:00"},

    {"id":"52","time":"13:00:00"},
    {"id":"53","time":"13:15:00"},
    {"id":"54","time":"13:30:00"},
    {"id":"55","time":"13:45:00"},

    {"id":"56","time":"14:00:00"},
    {"id":"57","time":"14:15:00"},
    {"id":"58","time":"14:30:00"},
    {"id":"59","time":"14:45:00"},

    {"id":"60","time":"15:00:00"},
    {"id":"61","time":"15:15:00"},
    {"id":"62","time":"15:30:00"},
    {"id":"63","time":"15:45:00"},

    {"id":"64","time":"16:00:00"},
    {"id":"65","time":"16:15:00"},
    {"id":"66","time":"16:30:00"},
    {"id":"67","time":"16:45:00"},

    {"id":"68","time":"17:00:00"},
    {"id":"69","time":"17:15:00"},
    {"id":"70","time":"17:30:00"},
    {"id":"71","time":"17:45:00"},

    {"id":"72","time":"18:00:00"},
    {"id":"73","time":"18:15:00"},
    {"id":"74","time":"18:30:00"},
    {"id":"75","time":"18:45:00"},

    {"id":"76","time":"19:00:00"},
    {"id":"77","time":"19:15:00"},
    {"id":"78","time":"19:30:00"},
    {"id":"79","time":"19:45:00"},

    {"id":"80","time":"20:00:00"},
    {"id":"81","time":"20:15:00"},
    {"id":"82","time":"20:30:00"},
    {"id":"83","time":"20:45:00"},

    {"id":"84","time":"21:00:00"},
    {"id":"85","time":"21:15:00"},
    {"id":"86","time":"21:30:00"},
    {"id":"87","time":"21:45:00"},

    {"id":"88","time":"22:00:00"},
    {"id":"89","time":"22:15:00"},
    {"id":"90","time":"22:30:00"},
    {"id":"91","time":"22:45:00"},

    {"id":"92","time":"23:00:00"},
    {"id":"93","time":"23:15:00"},
    {"id":"94","time":"23:30:00"},
    {"id":"95","time":"23:45:00"},
    
  ],
  clientStatusList:[
    {"id":1,"key":"actively_engaged","name":"Actively Engaged"},
    {"id":2,"key":"accepted_offer","name":"Accepted Offer"},
    {"id":3,"key":"closed","name":"Closed"},
    {"id":4,"key":"initial_welcome_sent","name":"Initial Welcome Sent"},
    {"id":5,"key":"moderate_engaged","name":"Moderately Engaged"},
    {"id":6,"key":"signed_contract","name":"Signed Contract"},
    {"id":7,"key":"unresponsive","name":"Unresponsive"},
  ],
  timezonelist:[
    {"id":"1","key":"Pacific/Midway","name":"(UTC-11:00) Coordinated Universal Time-11"},
    {"id":"2","key":"Pacific/Samoa","name":"(UTC-11:00) Samoa"},
    {"id":"3","key":"Pacific/Honolulu","name":"(UTC-10:00) Hawaii"},
    {"id":"4","key":"America/Anchorage","name":"(UTC-09:00) Alaska"},
    {"id":"5","key":"America/Los_Angeles","name":"(UTC-08:00) Pacific Time (US & Canada)"},
    {"id":"6","key":"America/Tijuana","name":"(UTC-08:00) Tijuana, Baja California"},
    {"id":"7","key":"America/Denver","name":"(UTC-07:00) Mountain Time (US & Canada)"},
    {"id":"8","key":"America/Chihuahua","name":"(UTC-07:00) Chihuahua, La Paz, Mazatlan"},
    {"id":"9","key":"America/Mazatlan","name":"(UTC-07:00) Mazatlan"},
    {"id":"10","key":"America/Phoenix","name":"(UTC-07:00) Arizona"},
    {"id":"11","key":"America/Regina","name":"(UTC-06:00) Saskatchewan"},
    {"id":"12","key":"America/Tegucigalpa","name":"(UTC-06:00) Central America"},
    {"id":"13","key":"America/Chicago","name":"(UTC-06:00) Central Time (US & Canada)"},
    {"id":"14","key":"America/Mexico_City","name":"(UTC-06:00) Mexico City"},
    {"id":"15","key":"America/Monterrey","name":"(UTC-06:00) Monterrey"},
    {"id":"16","key":"America/New_York","name":"(UTC-05:00) Eastern Time (US & Canada)"},
    {"id":"17","key":"America/Bogota","name":"(UTC-05:00) Bogota, Lima, Quito"},
    {"id":"18","key":"America/Lima","name":"(UTC-05:00) Lima"},
    {"id":"19","key":"America/Rio_Branco","name":"(UTC-05:00) Rio Branco"},
    {"id":"20","key":"America/Indiana/Indianapolis","name":"(UTC-05:00) Indiana (East)"},
    {"id":"21","key":"America/Caracas","name":"(UTC-04:30) Caracas"},
    {"id":"22","key":"America/Halifax","name":"(UTC-04:00) Atlantic Time (Canada)"},
    {"id":"23","key":"America/Manaus","name":"(UTC-04:00) Manaus"},
    {"id":"24","key":"America/Santiago","name":"(UTC-04:00) Santiago"},
    {"id":"25","key":"America/La_Paz","name":"(UTC-04:00) La Paz"},
    {"id":"26","key":"America/Cuiaba","name":"(UTC-04:00) Cuiaba"},
    {"id":"27","key":"America/Asuncion","name":"(UTC-04:00) Asuncion"},
    {"id":"28","key":"America/St_Johns","name":"(UTC-03:30) Newfoundland"},
    {"id":"29","key":"America/Argentina/Buenos_Aires","name":"(UTC-03:00) Buenos Aires"},
    {"id":"30","key":"America/Sao_Paulo","name":"(UTC-03:00) Brasilia"},
    {"id":"31","key":"America/Godthab","name":"(UTC-03:00) Greenland"},
    {"id":"32","key":"America/Montevideo","name":"(UTC-03:00) Montevideo"},
    {"id":"33","key":"Atlantic/South_Georgia","name":"(UTC-02:00) Mid-Atlantic"},
    {"id":"34","key":"Atlantic/Azores","name":"(UTC-01:00) Azores"},
    {"id":"35","key":"Atlantic/Cape_Verde","name":"(UTC-01:00) Cape Verde Is."},
    {"id":"36","key":"Europe/London","name":"(UTC) London, Edinburgh, Dublin, Lisbon"},
    {"id":"37","key":"UTC","name":"(UTC) Coordinated Universal Time, Greenwich Mean Time"},
    {"id":"38","key":"Africa/Monrovia","name":"(UTC) Monrovia, Reykjavik"},
    {"id":"39","key":"Africa/Casablanca","name":"(UTC) Casablanca"},
    {"id":"40","key":"Europe/Belgrade","name":"(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"},
    {"id":"41","key":"Europe/Sarajevo","name":"(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"},
    {"id":"42","key":"Europe/Brussels","name":"(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"},
    {"id":"43","key":"Africa/Algiers","name":"(UTC+01:00) West Central Africa"},
    {"id":"44","key":"Europe/Amsterdam","name":"(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"},
    {"id":"45","key":"Europe/Minsk","name":"(UTC+02:00) Minsk"},
    {"id":"46","key":"Africa/Cairo","name":"(UTC+02:00) Cairo"},
    {"id":"47","key":"Europe/Helsinki","name":"(UTC+02:00) Helsinki, Riga, Sofia, Tallinn, Vilnius"},
    {"id":"48","key":"Europe/Athens","name":"(UTC+02:00) Athens, Bucharest"},
    {"id":"49","key":"Europe/Istanbul","name":"(UTC+02:00) Istanbul"},
    {"id":"50","key":"Asia/Jerusalem","name":"(UTC+02:00) Jerusalem"},
    {"id":"51","key":"Asia/Amman","name":"(UTC+02:00) Amman"},
    {"id":"52","key":"Asia/Beirut","name":"(UTC+02:00) Beirut"},
    {"id":"53","key":"Africa/Windhoek","name":"(UTC+02:00) Windhoek"},
    {"id":"54","key":"Africa/Harare","name":"(UTC+02:00) Harare"},
    {"id":"55","key":"Asia/Kuwait","name":"(UTC+03:00) Kuwait, Riyadh"},
    {"id":"56","key":"Asia/Baghdad","name":"(UTC+03:00) Baghdad"},
    {"id":"57","key":"Africa/Nairobi","name":"(UTC+03:00) Nairobi"},
    {"id":"58","key":"Asia/Tehran","name":"(UTC+03:30) Tehran"},
    {"id":"59","key":"Asia/Tbilisi","name":"(UTC+04:00) Tbilisi"},
    {"id":"60","key":"Europe/Moscow","name":"(UTC+04:00) Moscow, Volgograd"},
    {"id":"61","key":"Asia/Muscat","name":"(UTC+04:00) Abu Dhabi, Muscat"},
    {"id":"62","key":"Asia/Baku","name":"(UTC+04:00) Baku"},
    {"id":"63","key":"Asia/Yerevan","name":"(UTC+04:00) Yerevan"},
    {"id":"64","key":"Asia/Karachi","name":"(UTC+05:00) Islamabad, Karachi"},
    {"id":"65","key":"Asia/Tashkent","name":"(UTC+05:00) Tashkent"},
    {"id":"66","key":"Asia/Kolkata","name":"(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"},
    {"id":"67","key":"Asia/Colombo","name":"(UTC+05:30) Sri Jayawardenepura"},
    {"id":"68","key":"Asia/Katmandu","name":"(UTC+05:45) Kathmandu"},
    {"id":"69","key":"Asia/Dhaka","name":"(UTC+06:00) Dhaka"},
    {"id":"70","key":"Asia/Almaty","name":"(UTC+06:00) Almaty"},
    {"id":"71","key":"Asia/Yekaterinburg","name":"(UTC+06:00) Ekaterinburg"},
    {"id":"72","key":"Asia/Rangoon","name":"(UTC+06:30) Yangon (Rangoon)"},
    {"id":"73","key":"Asia/Novosibirsk","name":"(UTC+07:00) Novosibirsk"},
    {"id":"74","key":"Asia/Bangkok","name":"(UTC+07:00) Bangkok, Jakarta"},
    {"id":"75","key":"Asia/Brunei","name":"(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"},
    {"id":"76","key":"Asia/Krasnoyarsk","name":"(UTC+08:00) Krasnoyarsk"},
    {"id":"77","key":"Asia/Ulaanbaatar","name":"(UTC+08:00) Ulaan Bataar"},
    {"id":"78","key":"Asia/Kuala_Lumpur","name":"(UTC+08:00) Kuala Lumpur, Singapore"},
    {"id":"79","key":"Asia/Taipei","name":"(UTC+08:00) Taipei"},
    {"id":"80","key":"Australia/Perth","name":"(UTC+08:00) Perth"},
    {"id":"81","key":"Asia/Irkutsk","name":"(UTC+09:00) Irkutsk"},
    {"id":"82","key":"Asia/Seoul","name":"(UTC+09:00) Seoul"},
    {"id":"83","key":"Asia/Tokyo","name":"(UTC+09:00) Tokyo"},
    {"id":"84","key":"Australia/Darwin","name":"(UTC+09:30) Darwin"},
    {"id":"85","key":"Australia/Adelaide","name":"(UTC+09:30) Adelaide"},
    {"id":"86","key":"Australia/Canberra","name":"(UTC+10:00) Canberra, Melbourne, Sydney"},
    {"id":"87","key":"Australia/Brisbane","name":"(UTC+10:00) Brisbane"},
    {"id":"88","key":"Australia/Hobart","name":"(UTC+10:00) Hobart"},
    {"id":"89","key":"Asia/Vladivostok","name":"(UTC+10:00) Vladivostok"},
    {"id":"90","key":"Pacific/Guam","name":"(UTC+10:00) Guam, Port Moresby"},
    {"id":"91","key":"Asia/Yakutsk","name":"(UTC+10:00) Yakutsk"},
    {"id":"92","key":"Etc/GMT-11","name":"(UTC+11:00) Solomon Is., New Caledonia"},
    {"id":"93","key":"Pacific/Fiji","name":"(UTC+12:00) Fiji"},
    {"id":"94","key":"Asia/Kamchatka","name":"(UTC+12:00) Kamchatka"},
    {"id":"95","key":"Pacific/Auckland","name":"(UTC+12:00) Auckland"},
    {"id":"96","key":"Asia/Magadan","name":"(UTC+12:00) Magadan"},
    {"id":"97","key":"Pacific/Tongatapu","name":"(UTC+13:00) Nukualofa"}
    ],

  resultsCountOptions: [
      {"label":"20","value":20},
      {"label":"50","value": 50},
      {"label":"100","value":100},
      {"label":"250","value": 250},
      {"label":"500","value": 500},
      {"label":"1000","value": 1000},
      {"label":"2000","value":2000},
      {"label":"3000","value": 3000},
      {"label":"4000","value": 4000},
      {"label":"5000","value": 5000},
      {"label":"6000","value": 6000},
      {"label":"7000","value": 7000}
    ],  
    
  realEstateOptions:[
    {"id":1,"key":"Under 1 Year","name":"Under 1 Year"},
    {"id":2,"key":"1 Year","name":"1 Year"},
    {"id":3,"key":"2 Year","name":"2 Year"},
    {"id":4,"key":"3 Year","name":"3 Year"},
    {"id":5,"key":"4 Year","name":"4 Year"},
    {"id":6,"key":"5 Year","name":"5 Year"},
    {"id":7,"key":"6 Year","name":"6 Year"},
    {"id":8,"key":"7 Year","name":"7 Year"},
    {"id":9,"key":"8 Year","name":"8 Year"},
    {"id":10,"key":"9 Year","name":"9 Year"},
    {"id":11,"key":"10 Year","name":"10 Year"},
    {"id":12,"key":"11 Year","name":"11 Year"},
    {"id":13,"key":"12 Year","name":"12 Year"},
    {"id":14,"key":"13 Year","name":"13 Year"},
    {"id":15,"key":"14 Year","name":"14 Year"},
    {"id":16,"key":"15 Year","name":"15 Year"},
    {"id":17,"key":"16 Year","name":"16 Year"},
    {"id":18,"key":"17 Year","name":"17 Year"},
    {"id":19,"key":"18 Year","name":"18 Year"},
    {"id":20,"key":"19 Year","name":"19 Year"},
    {"id":21,"key":"20+ Year","name":"20+ Year"},
  ],

  bugets: [
    {"label":"Under $400,000","value":"Under $400,000"},
    {"label":"$400,000 - $500,000","value":"$400,000 - $500,000"},
    {"label":"$500,000 - $600,000","value":"$500,000 - $600,000"},
    {"label":"$600,000 - $700,000","value":"$600,000 - $700,000"},
    {"label":"$700,000 - $800,000","value":"$700,000 - $800,000"},
    {"label":"$800,000 - $900,000","value":"$800,000 - $900,000"},
    {"label":"$900,000 - $1,000,000","value":"$900,000 - $1,000,000"},
    {"label":"$1,000,000 - $1,200,000","value":"$1,000,000 - $1,200,000"},
    {"label":"$1,200,000 - $1,500,000","value":"$1,200,000 - $1,500,000"},
    {"label":"$1,500,000 - $2,000,000","value":"$1,500,000 - $2,000,000"},
    {"label":"$2,000,000 - $2,500,000","value":"$2,000,000 - $2,500,000"},
    {"label":"$2,500,000 - $3,000,000","value":"$2,500,000 - $3,000,000"},
    {"label":"$3,000,000 - $3,500,000","value":"$3,000,000 - $3,500,000"},
    {"label":"$3,500,000 - $4,000,000","value":"$3,500,000 - $4,000,000"},
    {"label":"$4,000,000 - $4,500,000","value":"$4,000,000 - $4,500,000"},
    {"label":"$4,500,000 - $5,000,000","value":"$4,500,000 - $5,000,000"},
    {"label":"$5,000,000 - $5,500,000","value":"$5,000,000 - 5,500,000"},
    {"label":"$5,500,000 - $6,000,000","value":"$5,500,000 - $6,000,000"},
    {"label":"$6,000,000 - $6,500,000","value":"$6,000,000 - $6,500,000"},
    {"label":"$6,500,000 - $7,000,000","value":"$6,500,000 - $7,000,000"},
    {"label":"$7,000,000 - $7,500,000","value":"$7,000,000 - $7,500,000"},
    {"label":"$7,500,000 - $8,000,000","value":"$7,500,000 - $8,000,000"},
    {"label":"$8,000,000 - $8,500,000","value":"$8,000,000 - $8,500,000"},
    {"label":"$8,500,000 - $9,000,000","value":"$8,500,000 - $9,000,000"},
    {"label":"$9,000,000 - $9,500,000","value":"$9,000,000 - $9,500,000"},
    {"label":"$10,000,000 - $12,000,000","value":"$10,000,000 - $12,000,000"},
    {"label":"$12,000,000 - $15,000,000","value":"$12,000,000 - $15,000,000"},
    {"label":"$15,000,000 - $20,000,000","value":"$15,000,000 - $20,000,000"},
    {"label":"$20,000,000 - $25,000,000","value":"$20,000,000 - $25,000,000"},
    {"label":"Above $25,000,000","value":"Above $25,000,000"}
  ],
  url:{
    wpUrl: "https://suburbanjunglegroup.com/blog",
    backendUrl: "https://jungler.suburbanjungleinc.com"  
  },
  questions1: [
    {
      id: 1,
      q:"How many kids do you have?",
      options:["NAME","AGE","SCHOOL"]
    },
    {
      id: 2,
      q:"Tell us a little about you...",
      options:[
        "What industry do you work in?",
        "What's the name of the company you work for?",
        "Where did you go to Undergrad?",
        "Did you go to Grad school?",
        "Enter the Name of your grad school",
        "What city or town did you grow up in?",
        "Did you like it?",
        "Where does your family live?",
        "Is it important to be nearby?"
      ]
    },
    {
      id: 3,
      q:"Are you...",
      options:[
        "Partner's Name",
        "Mobile Number",
        "Email",
        "What industry do they work in?",
        "What's the name of the company they work for?",
        "Where did they go to Undergrad?",
        "Did they go to Grad school?",
        "Enter the Name of their grad school",
        "What city or town did they grow up in?",
        "Did they like it?",
        "Where does their family live?",
        "Is it important to be nearby?",
        "They would commute using the following modes of transportation",
        "They would be willing to commute:",
        "Where do they commute to?",
        "Can they work from home?",
        "They plan to use"
      ]
    },
    {
      id: 4,
      q:"Tell us a bit about your ideal town…"
    },
    {
      id: 5,
      q:"What kind of feel are you looking for…"
    },
    {
      id: 6,
      q:"What would you prefer the moms to be in town:"
    },
    {
      id: 7,
      q:"Are you looking for Type A types of folks…"
    },
    {
      id: 8,
      q:"Are you looking for fancy or laid back?"
    },
    {
      id: 9,
      q:"Do you want people around town wearing heels or flats?",
      hide: true
    },
    {
      id: 10,
      q:"Do you want a town where people get blow outs and wear makeup on an average day?",
      hide: true
    },
    {
      id: 11,
      q:"How important is it for you to have diversity in town?"
    },
    {
      id: 12,
      q:"The occupation of most in your town would ideally be"
    },
    {
      id: 13,
      q:"The people in town would ideally drive:",
      hide: true
    },
    {
      id: 14,
      q:"The type of town think would be great for us would be"
    },
    {
      id: 15,
      q:"The town that would be great for us would have a large mix of people who graduated from ivy league schools",
      hide: true
    },
    {
      id: 16,
      q:"What type of taxes would you be ok with"
    },
    {
      id: 17,
      q:"What type of school system are you looking for"
    },
    {
      id: 18,
      q:"I am ok with a pressure cooker type of school",
      hide: true
    },
    {
      id: 19,
      q:"Language Immersion program is a must for me"
    },
    {
      id: 20,
      q:"I need a strong special needs program"
    },
    {
      id: 21,
      q:"Do you need full day or ½ day kindergarten?"
    },
    {
      id: 22,
      q:"Do you need busing?"
    },
    {
      id: 23,
      q:"Do you need universal pre-k"
    },
    {
      id: 24,
      q:"I would commute using the following modes of transportation"
    },
    {
      id: 25,
      q:"I would be willing to commute:"
    },
    {
      id: 26,
      q:"I plan to use"
    },
    {
      id: 27,
      q:"The ideal amount of land for us would be"
    },
    {
      id: 28,
      q:"The ideal type of home would be"
    },
    {
      id: 29,
      q:"Is a pool ideal for you in your yard?"
    },
    {
      id: 30,
      q:"Would you say you prefer more folks in town to be progressive v. conservative?"
    },
    {
      id: 31,
      q:"Is it very important for you to have a beach nearby?"
    },
    {
      id: 32,
      q:"Is it important for you to live in a walkable town?"
    },
    {
      id: 33,
      q:"Is it very important for you to have Organic Food options?"
    },
    {
      id: 34,
      q:"Is it important for you to have Hiking trails nearby?"
    },
    {
      id: 35,
      q:"How do you feel about kids wearing brand name clothing?",
      hide: true
    },
    {
      id: 36,
      q:"Please check off the activities that are most important to your family:"
    },
    {
      id: 37,
      q:"Would any part of your weekend activities be centered around:"
    },
    {
      //done
      id: 38,
      q:"Is it important for you to have great restaurants nearby?"
    },
    {
      id: 39,
      q:"Do you prefer name brand stores or mom and pop shops in town?"
    },
    {
      id: 40,
      q:"How many cars do you plan to have once you move out?",
      hide: true
    },
    {
      id: 41,
      q:"Give us a round about budget…"
    },
    {
      id: 42,
      q:"The town that would be great for us would have a large mix of people who are highly educated with advanced degrees."
    },
    {
      id: 43,
      q:"Where do you commute to?"
    },
    {
      id: 44,
      q:"Can you work from home?"
    },
    {
      id: 45,
      q:"Are there any places where you want to be or look?"
    },
    {
      id: 46,
      q:"Are there any places you don’t want to be?"
    },
    {
      id: 47,
      q:"Where do you live?"
    },
  ],
  questions:[
    {
      id:1,
      show: true,
      q:"Tell us a bit about yourself"
    },
    {
      id:2,
      q:"Where do you currently live?",
    },
    {
      id: 3,
      q:"Tell us a little about you...",
      options:[
        
          "Tell us a little bit about where you work and what you do!",
          "Which of these commuting options would you prefer?",
          "How far would you be willing to commute?",
          "Where do you commute to?",
          "Can you work from home?",
           "Tell us about where you grew up…(name of the town and what you liked or did not like about it!...)",
           "Where does your family live?",
           "Is it important to be nearby?",
           "How far?",
         
      ]
    },
    {
      id: 4,
      show: false,
      q:"Are you...",
      "options":[
          "Name",
          "Mobile Number",
          "Tell us a little bit about where they work and what they do!",
          "Which of these commuting options would your partner/significant other prefer?",
          "How far would your partner/significant other be willing to commute?",
          "Where does your partner/significant other commute to?",
          "Can your partner/significant other work from home?",
          "Tell us about where they grew up…(name of the town and what they liked or did not like about it!...)",
          "Where does the family of your partner/significant other live?",
          "Is it important to be nearby?",
          "How far?"
        
      ]
    },
    {
      //done
      id: 5,
      q:"Are there any places where you want to be or look?",

    },
    {
      //done
      id: 6,
      q:"Are there any places you don’t want to be?",
    },
    {
      //done
      id: 7,
      q:"Tell us a bit about your ideal town...",
      options:[
          "Give us some details!"
      ]
    },
    {
      //done
      id: 8,
      q:"What kind of feel are you looking for?"
      
    },
    {
      //done
      id: 9,
      q:"Is walkability important?",
      options:[
            "Tell us more...",
      ]
    },
    
    {
      //done
      id: 10,
      q:"Would you like to have great restaurants nearby?",
    },
    {
      //done
      id: 11,
      q:"Do you prefer name-brand stores or mom and pop shops in town?"
    },
    {
      //done
      id: 12,
      q:"Would you like to live near the water (beach, lake, river, etc.)?",
  
    },
    {
      //done
      id: 13,
      q:"Would you like to have Hiking trails nearby?",
     
    },
    {
      //done
      id: 14,
      q:"Please check off the activities that are most important to your family:",
      options:[
          "Tell us what else you/your family like to do...",
       ]
    },
    {
      //done
      id: 15,
      q:"For Child Care (if applicable), I plan to use",
    },
    {
      //done
      id: 16,
      q:"Are you considering Public or Private schools?",
    },
    {
      //done
      id: 17,
      q:"How important is a language immersion program?",
      "options":[
        "languages?"
    ]
    },
    {
      //done
      id: 18,
      q:"Do you need a special needs program?",
    },
    {
      //done
      id: 19,
      q:"Do you need full-day or ½ day kindergarten?"
    },
    {
      //done
      id: 20,
      q:"Do you need school busing?"
    },
    {
      //done
      id: 21,
      q:"Do you need universal pre-k?"
    },
    {
      //done
      id: 22,
      q:"The ideal type of home would be...",
    },
    {
      //done
      id: 23,
      q:"The ideal amount of land for us would be...",

    },
    {
      //done
      id: 24,
      q:"The ideal number of bedrooms would be…",

    },
    {
      //done
      id: 25,
      q:"The ideal number of bathrooms would be…",

    },
    {
      //done
      id: 26,
      q:"Is a pool ideal for you in your yard?",
    },
    {
      //done
      id: 27,
      q:"What are the top 3 priorities in your search?",

    },
    {
      id: 28,
      q:"Is there anything else you would like to share with us or that would be helpful in selecting the best towns for you?",
    },
    {
      id: 29,
      q:"Are you working with a real estate agent in any town(s)?",
      "options":[
              "We would love to understand where you are in your search so that we can add the most value."
          
      ]
    }
  ],
  timeslot: [
    "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
    "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00",
  ],
  timeslotHalfHours: [
    "01:00", "01:15", "01:30",  "01:45", "02:00", "02:15", "02:30",  "02:45", "03:00", "03:15", "03:30",  "03:45", "04:00", "04:15", "04:30",  "04:45", "05:00", "05:15", "05:30",  "05:45", "06:00", "06:15", "06:30",  "06:45", "07:00", "07:15", "07:30",  "07:45", "08:00", "08:15", "08:30",  "08:45", "09:00", "09:15", "09:30",  "09:45", "10:00", "10:15", "10:30",  "10:45", "11:00", "11:15", "11:30",  "11:45", "12:00", "12:15", "12:30",  "12:45",
    "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45", "00:00"
   ]
}

export const getQueryParam = (param) => {
  var result =  window.location.search.match(
    new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
  )
  return result ? result[3] : false
}

export const encode = (value) => {
  if (typeof(value) !== 'number') {
    throw 'Value is not number!';
  }
  var result = '', mod;
  do {
    mod = value % 64;
    result = ALPHA.charAt(mod) + result;
    value = Math.floor(value / 64);
  } while(value > 0);
  return result;
}

export const decode  = (value) => {
  var result = 0;
  for (var i = 0, len = value.length; i < len; i++) {
    result *= 64;
    result += ALPHA.indexOf(value[i]);
  }
  return result;
}

export const getAgentTimeFormate = (val) => {  
  if(val){
  let t = parseInt(val.split(':')[0]);
  var v = "";
  let timeObj = {type : "", tval : ""}
  if(t >= 12) {
    if(t === 12){
       timeObj.type = "pm";
       timeObj.tval = 12 + ":" + val.split(':')[1];
    }else{
       var diff = t - 12; 
       timeObj.tval = diff +':'+ val.split(':')[1] 
       timeObj.type = "pm";
    }
  } else {
    if(t === 0){
       timeObj.type = "am";
       v = 12 + ":" + val.split(':')[1];
    } else {
      timeObj.type = "am";
      if(parseInt(val.split(':')[0]) <= 9){
        v = parseInt(val.split(':')[0]) + ":" + val.split(':')[1];
      } else {
        v = parseInt(val.split(':')[0]) + ":" + val.split(':')[1];
      } 
    }
    timeObj.tval = v;
  }
  
  return timeObj;
  }else{
    timeObj = {type : "", tval : ""}
    return timeObj;
  }
}

export const getTimeFormate = (val) => {
  if(val){
  let t = parseInt(val.split(':')[0]);
  var v = "";
  let timeObj = {type : "", tval : ""}
  if(t >= 12) {
    if(t === 12){
       timeObj.type = "pm";
       timeObj.tval = 12 + ":" + val.split(':')[1];
    }else{
       var diff = t - 12; 
       timeObj.tval = diff +':'+ val.split(':')[1] 
       timeObj.type = "pm";
    }
  } else {
    if(t === 0){
       timeObj.type = "am";
       v = 12 + ":" + val.split(':')[1];
    } else {
      timeObj.type = "am";
      if(parseInt(val.split(':')[0]) <= 9){
        v = parseInt(val.split(':')[0]) + ":" + val.split(':')[1];
      } else {
        v = val
      } 
    }
    timeObj.tval = v;
  }
  
  return timeObj;
  }else{
    timeObj = {type : "", tval : ""}
    return timeObj;
  }
}

export const timeConverter = (time) => {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}


export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{1,9}|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+'+match[1] : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return ''
}

export const uniqueArrayObject = (arr, prop) => {
  if(arr && arr.length >0){
    return arr.map(function(e) { return e[prop]; }).filter(function(e,i,a){
        return i === a.indexOf(e);
    });
  }
}

export function isValidLatLng(loc) {
  if (Array.isArray(loc) && loc.length === 2) {
    const lat = loc[0]
    const lng = loc[1]
    if (isNaN(lat) || isNaN(lng)) {
      return false
    }
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
      return false
    }
    return true
  } else {
    return false
  }
}

// Check if a valid google object
export function isValidGoogleObject(google) {
  if (typeof google === 'object' && google) {
    return !!google.id
  } else {
    return false
  }
}

// get URL parameters
export function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
  const results = regex.exec(location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

/*
* Convert legacy open/close hours to the latest Hours format
* */

export function topostcardHours(hours) {
  if (!Array.isArray(hours)) {
    return []
  }

  const newHours = []
  for (let i = 0; i < hours.length; i++) {
    const day = hours[i]
    const { startTime, endTime, opens } = day
    if (opens) {
      const openingHours = {
        open: {
          day: -1,
          time: -1
        },
        close: {
          day: -1,
          time: -1
        }
      }
      if (isDate(startTime) && isDate(endTime)) {
        // closing hour is on the next day
        if (startTime.getTime() > endTime.getTime()) {
          openingHours.open.day = i
          openingHours.close.day = i + 1
          openingHours.close.time = startTime.getHours() * 100 + startTime.getMinutes()
          openingHours.open.time = endTime.getHours() * 100 + endTime.getMinutes()
        } else {
          openingHours.open.day = i
          openingHours.close.day = i
          openingHours.open.time = startTime.getHours() * 100 + startTime.getMinutes()
          openingHours.close.time = endTime.getHours() * 100 + endTime.getMinutes()
        }
        newHours.push(openingHours)
      } else {
       
      }
    }
  }
  return newHours
}


export const formatNumber = num => {
  if(num === '0') {
    return '0'
  }
  if(num === 0) {
    return '0'
  }
  if(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')  
  }
}