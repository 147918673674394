import 'rxjs'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Field, reduxForm } from 'redux-form'
import config from '../../config'
import { fetch } from '../../utils';
import {getTimeFormate, formatPhoneNumber} from '../../config'
import { Redirect, Link } from 'react-router-dom'
import Snackbar from 'material-ui/Snackbar'
// import TopNavigation from '../TopNavigation/container'
import TopNavigation from '../TopNavigation/container'
import Calendar from 'react-calendar'
import moment from 'moment'
import Slider from 'react-slick'
import timezone from 'moment-timezone';
import NumberFormat from 'react-number-format';
import axios from 'axios'
import _ from 'lodash';
import Modal from 'react-modal';
import ShowMore from 'react-show-more';

import styles from './styles.scss'
import Cookies from 'universal-cookie'
import DatePicker from 'react-datepicker'
import {getHostname} from "../../utils/helper";
const required = value => value ? undefined : 'Required'
const HOSTNAME = process.env.API_HOSTNAME

const cookies = new Cookies()
let _user = ''
let pre_Auth = ''


const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows:true,
  autoplay:false
};

const settings2 = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll:1,
  arrows:true,
  autoplay:false,
  responsive: [
    {
      breakpoint: 767,
      dots:true,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)'
  }
};

const strategistAppointmentList = ['Initial strategy session','Follow up strategy call']
const agentAppointmentList = ['Initial Agent Town Call','Initial Town Tour','Follow up Town Tour','Inspection','Closing']

Modal.setAppElement('#root')

class DatePickerCustomInput extends React.Component {

  render () {
    return (
      <button
        className="form-control custom-select date__picker"
        onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

class Clients extends Component {
  constructor(props) {
    super(props)
     this.state = {
      id:"",
      openSnackbar: false,
      errMessage:'',
      email: '',
      firstname:'',
      lastname:'',
      phone:'',
      userid:'',
      success: '',
      role: '',
      selctedRole: "",
      errProfileMessage: "",
      isLoadingProfile: false,
      isLoadingAddress: false,
      isLoadingPassword: false,
      openSchedule: false,
      source:"",
      times:[],
      date: moment().format("MM/DD/YYYY"),
      timezone: moment.tz.guess(),
      availableDates: [],
      startDate:moment(),
      timezone_type:"UTC",
      selectedTime : "",
      noTimeWorks:false,
      noSchedule:false,
      err: {},
      infoLength:0,
      strategy:[],
      isDateLoading:false,
      strategist:"",
      currentDate: moment(),
      selectedAgent: [],
      appointment_type: '',
      message: '',
      pre_Auth: '',
      modalIsOpen: false,
      isLoading: false
    }
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount(){
    _user = cookies.get('user')
    if(_user) {
      this.setState({userid: _user.id})
      const data = {}
      data.user_id = _user.id;
      this.props.fetchUser(data)
      this.props.getClientStrategist(data)
      this.props.getClientAgent(data)
      //this.props.getClientStrategistAvaillability({"user_id":3591,"date":"11/22/2018","timezone":"America/New_York"})
      pre_Auth = cookies.get('i_user')
      if(pre_Auth){
        this.setState({pre_Auth: pre_Auth})
      }
      
    }
  }
  
  componentDidMount(){
    document.title = "Jungler: Client";
  }

  componentWillUnmount(){
    document.title = "Suburban Jungle";
  }

  componentWillReceiveProps(nextProps) {
    
    let self = this
    if (nextProps.profilePhase === "error") {
      this.setState({ isLoadingProfile: false, errProfileMessage: nextProps.profileError})
    } else if (nextProps.profilePhase === "success") {
      this.setState({ isLoadingProfile: false, errProfileMessage: "Profile info saved successfully."})

      if(nextProps.isSubmitting !=false){
          if(this.state.userid !=""){
            const data = {}
            data.user_id = this.state.userid;
            this.props.fetchUser(data)
          }
      }
    }
    if (nextProps.profilePhase === "success") {
      if(nextProps.user) {
        let user = nextProps.user
        this.setState({
          id: user.id,
          firstname: user.first_name,
          lastname: user.last_name,
          phone: user.phone_mobile,
          email: user.email,
          role: user.role
        })
      }
    }
    if (nextProps.submitSchedulePhase === true) {
      this.setState({message:nextProps.resMessage})
      setTimeout(function() {
        self.closeScheduleBar()
      }, 3000);
    }
    if (nextProps.submitSchedulePhase === false) {
      this.setState({message:nextProps.resMessage})
      setTimeout(function() {
        self.setState({ message: ''})
      }, 3000);
    }

    if (nextProps.getStrategistDataPhase === true) {
      this.setState({isLoading: false})
      this.props.clearDatePhase()
      if(nextProps.getStrategistData && nextProps.getStrategistData.length){
      this.availability(this.state.selectedUserId, nextProps.getStrategistData[0].date);
      const availableDates = []
      if(nextProps.getStrategistData && nextProps.getStrategistData.length){
        nextProps.getStrategistData.map(function(date){
          availableDates.push(moment(date.date))
        })
        
      }
      this.setState({availableDates, startDate:moment(nextProps.getStrategistData[0].date)})
    }
    }else{
      if (nextProps.getStrategistDataPhase === false) {
        this.setState({isLoading: false})
      }

    }

    if (nextProps.getStrategistAvaillabilityPhase === true) {
      this.setState({isLoading: false})
      this.props.clearDatePhase()
      if(nextProps.getStrategistAvaillabilityData.dates.length){
      const data = {}
      data.user_id  = this.state.selectedUserId
      data.date = moment(nextProps.getStrategistAvaillabilityData.dates[0]).format("MM/DD/YYYY");
      // data.timezone = this.state.timezone
      data.timezone = _user.time_zone
       this.props.getAgentAvailability(data)
      //this.availability(this.state.selectedUserId,  nextProps.getStrategistAvaillabilityData.dates[0]);
      const availableDates = []
      if(nextProps.getStrategistAvaillabilityData.dates.length){
         nextProps.getStrategistAvaillabilityData.dates.map(function(date){
          availableDates.push(moment(date))
        })
        
      }
      this.setState({availableDates, startDate:moment(nextProps.getStrategistAvaillabilityData.dates[0])})
    }
    }else{
      if (nextProps.getStrategistAvaillabilityPhase === false) {
        this.setState({isLoading: false})
      }
    }

    if(nextProps.agentAppPhase === "success"){
      this.setState({
        modalIsOpen: true,
        openSchedule: false, 
        selectTime: '', 
        selectedUserId: '', 
        date: moment().format("MM/DD/YYYY"),
        timezone: '',
        appointment_type: '',
        isDateLoading: false})
      this.closeScheduleBar()

      const data = {}
      data.user_id = _user.id
      this.props.getClientStrategist(data)
      this.props.getClientAgent(data)

      this.props.clearPhase()
    }
  }

  availability(id,date){
    this.setState({isDateLoading:true})
    let data = {};
    data.user_id = id;
    data.date = moment(date ?date :  this.state.date).format("MM/DD/YYYY");
    data.timezone = this.state.timezone;
    fetch(`${HOSTNAME}/client/client_strategist_availability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      if(payload.status){
      if(payload.timezone_type){
        this.setState({timezone_type: payload.timezone_type})
      }
      if(payload.data && payload.data.length > 0) {
        let timeArray = payload.data; //res.data.data;
        if (timeArray.length > 0) {
          this.handleSlider(timeArray);
        }
      }
      
        // else if(!date) {
        //   var currentTimestamp = moment(this.state.date).format("X");
        //   var d = moment(this.state.date).add(1, 'days')._d
        //   var SevenDays = moment().add(7, 'days')._d
        //   var AfterWeektimestamp = moment(SevenDays).format("X");
        //   if (currentTimestamp < AfterWeektimestamp) {
        //     this.setState({ date: moment(d).format("MM/DD/YYYY") }, ()  => {
        //       this.availability(id)
        //     });
        // }
        // else{
        //     this.setState({ times: [], isDateLoading: false })
        //   }
        // }
        else{
          this.setState({ times: [], isDateLoading: false })
        }
      }
      else {
        this.setState({ times: [] })
        alert("Please select different date");
      }
    })
    .catch(error => {
      throw error;
    });
  }

  handleSlider(timeArray){
    setTimeout(function() { this.setState({isDateLoading: false}); }.bind(this), 500);
    let finalArray = []
    let arr = []
    let i = -1;
    let next = () => {
       i++;
       if(i < timeArray.length){
          arr.push(timeArray[i])
          if(arr.length > 8){
            finalArray.push(arr);
            arr = []
            next();
          } else {
            next();
          }
       } else {
          if(arr.length > 0){
            finalArray.push(arr);
          }
          this.setState({ times: arr })
       }
    }
    next();
  }

  handleDateClick(date){

    this.setState({ times: [], date: moment(date).format("MM/DD/YYYY"), startDate: moment(date)  });
    let id = this.state.selectedUserId;
    if(this.state.selectedRole == "strategist") {
      this.availability(id,date);
    } else {
      const data = {}
      data.user_id  = this.state.selectedUserId
      data.timezone = _user.time_zone
      data.date     = moment(date).format("MM/DD/YYYY")
      this.props.getAgentAvailability(data)
    }   
    
  }

  selectTime(time) {
    this.setState({selectedTime: time, err: {}})
  }

  // Function to set form input values in state
  handleChange(event) {
    if(event.target.value==='null'){
      return;
    }
    let role = ''
    if(this.state.selectedAgent.length > 0){
      role = 'agent'
    }else{
      let scheduleArray = _.concat(this.props.strategistData, this.props.agentData);
      let data = _.filter(scheduleArray, function(o) {
        if (o) {
          let id = o.role === 'strategist' ? o.id : o.agent_id;
          return id === parseInt(event.target.value);
        }
      });
      role = data[0].role === 'strategist' ? data[0].role : data[0].agent_role
    }
    this.setState({selectedUserId:event.target.value, selectedRole: role ,timezone:_user.time_zone})
    //this.availability(event.target.value)
    if(role == "strategist"){
          this.props.getClientStrategistData({strategist_id: event.target.value,date:moment(this.state.currentDate._d).format("MM/DD/YYYY"),timezone:_user.time_zone})
          this.setState({isLoading: true, availableDates: [], times: []})
          this.props.clearAgentDatePhase()
        }else{
          const data = {
            user_id:event.target.value,
            date:moment(this.state.currentDate._d).format("MM/DD/YYYY"),
            timezone:_user.time_zone
          }
          this.props.getClientStrategistAvaillability(data)
          this.setState({isLoading: true, availableDates: [], times: [], timezone:_user.time_zone})
          this.props.clearAgentDatePhase()
        }
  }

  selectAppointmentType(val){
    this.setState({appointment_type : val, err: {}})
  }

  handleChangeApt(event){
    this.setState({ appointment_type: event.target.value })
  }

  handleChangeTime(event){
    this.setState({selectedTime: event.target.value})
  }

  openScheduleBar(agentData) {
    if(agentData) {
      const data = {}
      data.user_id  = agentData.agent_id
      data.timezone = _user.time_zone
      data.date     = moment(this.state.currentDate._d).format("MM/DD/YYYY")
      
      
      let arr = []
      let scheduleArray = _.concat(this.props.strategistData, this.props.agentData);
      let val = scheduleArray[0];
      if(agentData && agentData.agent_id){
        val = agentData;
        arr.push(agentData)
      }
      if(val){
        let id = val.role === 'strategist' ? val.id : val.agent_id;
        let role = val.role === 'strategist' ? val.role : val.agent_role;

        if(val.role == "strategist"){
          this.props.getClientStrategistData({strategist_id: val.id, date:moment(this.state.currentDate._d).format("MM/DD/YYYY"),timezone:_user.time_zone})
          this.setState({isLoading: true, availableDates: [], times: []})
          this.props.clearAgentDatePhase()
        }else{

          const data = {
            user_id:val.agent_id,
            date:moment(this.state.currentDate._d).format("MM/DD/YYYY"),
            timezone:_user.time_zone
          }
          this.props.getClientStrategistAvaillability(data)
          this.setState({isLoading: true, availableDates: [], times: []})
          this.props.clearAgentDatePhase()
          //this.props.getAgentAvailability(data)
          //this.availability(id);
        }
        this.setState({timezone: val.time_zone, openSchedule: true, selectedUserId: id, selectedAgent: arr, selectedRole: role})
      }
    } else {
      let arr = []
      let scheduleArray = _.concat(this.props.strategistData, this.props.agentData);
      let val = scheduleArray[0];
      
      
      if(agentData && agentData.agent_id){
        val = agentData;
        arr.push(agentData)
      }
      if(val){
        let id = val.role === 'strategist' ? val.id : val.agent_id;
        let role = val.role === 'strategist' ? val.role : val.agent_role;
        if(val.role == "strategist"){
          this.props.getClientStrategistData({strategist_id: val.id,date:moment(this.state.currentDate._d).format("MM/DD/YYYY"),timezone:_user.time_zone})
          this.setState({isLoading: true, availableDates: [], times: []})
          this.props.clearAgentDatePhase()
        }else{
          const data = {
            user_id:val.agent_id,
            date:moment(this.state.currentDate._d).format("MM/DD/YYYY"),
            timezone:_user.time_zone
          }
          this.props.getClientStrategistAvaillability(data)
          this.setState({isLoading: true, availableDates: [], times: []})
          this.props.clearAgentDatePhase()
          //this.availability(id);
        }
        //
        this.setState({openSchedule: true, selectedUserId: id, selectedAgent: arr, selectedRole: role})
      }
    }
  }

  closeScheduleBar(event) {
    this.setState({
      openSchedule: false,
      date: moment().format("MM/DD/YYYY"),
      selectedTime: '',
      appointment_type: '',
      selectedUserId: '',
      message: ''
    });
    let chechboxes = document.getElementsByClassName('appointmentTypeButtons');
    for (var i = 0; i < chechboxes.length; i++) {
    if (chechboxes[i].type == "radio") {
        chechboxes[i].checked = false;
    }
    }
  }

  submitSchedulingData() {
    const err = {}
    const { selectedTime, selectedUserId, timezone, appointment_type, date, startDate } = this.state
    this.setState({startDate: moment()})
    if (appointment_type === '' || appointment_type === "Select the type of Appointment" || appointment_type.trim() === '') {
      err.appointment_type = 'Please select a appointment type.';
    }
    if (selectedTime === '' || selectedTime === "Time" || selectedTime.trim() === '') {
      err.selectedTime = 'Please select a time.';
    }
    
    this.setState({ err });
    if (!Object.keys(err).length) {
      let data = {}
      data.time = selectedTime;
      data.user_id = selectedUserId;
      data.date = moment(startDate).format("YYYY-MM-DD");
      data.timezone = _user.time_zone
      data.appointment_type   = appointment_type;
      // this.props.scheduleMeeting(data)
      this.props.createAgentAppointment(data)
    }
  }

  authClick(event){
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let user = cookies.get('i_user')
    let authorization = cookies.get('i_Authorization')
    localStorage.clear();
    let expires = new Date()
    //expires.setHours(expires.getHours() + 24 )
    expires.setMonth(expires.getMonth()+2);
    expires = new Date(expires)
    cookies.set('Authorization',authorization, { path: '/', expires, domain: getHostname() });
    cookies.set('user', JSON.stringify(user), { path: '/', expires, domain: getHostname()  });
    
    document.cookie = "i_Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "i_user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if(pre_Auth.role === "superadmin"){
      this.props.history.push({'pathname' : "/dashboard" })
    }
    if(pre_Auth.role === "admin" || pre_Auth.role === "strategist"){
      this.props.history.push({'pathname' : "/home/new-clients" })
    }
  }

  afterOpenModal() {
    
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    const {
      submitSchedulePhase, 
      handleSubmit, 
      pristine, 
      isSubmitting, 
      rxError, 
      user, 
      token, 
      loginUserClient, 
      phase, 
      strategistData, 
      agentData,
      availabilityData
   } = this.props
  let tArray =[];
  let AgArray = [];
  let authorization = cookies.get('Authorization')
  let scheduleArray = _.concat(strategistData, agentData);
  if(this.state.selectedAgent.length > 0){
    scheduleArray = this.state.selectedAgent;
  }
  if(user && user.user && user.user.role !== "client" ) {
    this.setState({'user': user})
    return(
      <Redirect to={`/login`}/>
    )
  }

  let agentArrayList = []
  let localArrayList = []
  
    agentData && agentData.map((agent, idx) =>{
      if(agent.agent_role === "agent"){
        agentArrayList.push(agent)
      }else if(agent.agent_role === "local"){
        localArrayList.push(agent)
      }
    })
  
    let appointmentTypeArray = this.state.selectedRole === 'strategist' ? strategistAppointmentList : agentAppointmentList;
    
    let appoinment = ''
    if(this.state.selectedRole === 'strategist'){
      appoinment = config.strategistAppointment.map((val, index) => {
        return (
          <option key={index} value={val.name}>{val.name}</option>
        )
      });
    }else {
      appoinment = config.clientAgentAppointment.map((val, index) => {
        return (
          <option key={index} value={val.name}>{val.name}</option>
        )
      });
    }

    let times = ""
    if(availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0) {
      times = availabilityData && availabilityData.data && availabilityData.data.slot && availabilityData.data.slot.length > 0 && availabilityData.data.slot.map((slotval, indexslot) => { 
         return(
           <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
         )
      });
    } else {
      times = this.state.times && this.state.times.length > 0 && this.state.times.map((slotval, indexslot) => { 
        return(
          <option key={indexslot} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</option>
        )
      });
    }

    /*<div className="schedule__time--list" key={indexslot}>
            <ul>
              <li className={this.state.selectedTime === slotval ? 'active' : ''} onClick={this.selectTime.bind(this, slotval)} value={slotval}>{getTimeFormate(slotval).tval} {getTimeFormate(slotval).type}</li>
            </ul>
          </div>*/

    return (
      <div>

        {
          (pre_Auth)?
          <div className="auth-name">You are impersonating {'( '+_user.first_name+' '+_user.last_name+' ) '}<a onClick={this.authClick.bind(this)} id="impersonate__link" >Click Here to go back to Jungler</a></div>
          :''
        }
        <TopNavigation onRef={ref => (this.TopNavigation = ref)} {...this.props} openScheduleBar={this.openScheduleBar.bind(this)}/>
        <div className="clients__page--container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="clients__page--head">
                  <h1>Welcome <span className={this.state.pre_Auth !== '' ? "clients__page--username_top": "clients__page--username"}>{user && user.first_name ? user.first_name : ''}</span> to the Suburban Jungle!</h1>
                  {
                  // <p>Your Home Search Strategy is on its way! You will receive all of these details within 24 hours of your initial strategy session.
                  //    If you have not yet scheduled your strategy session, please click above to schedule with your strategist!
                  // </p>
                }
                </div>
                <ul className="clients__page--stratagist-wrapper">
                <Slider {...settings2} className="stratagist__slider--section">
                {strategistData && strategistData.length > 0 ? strategistData.map((val, index) => {
                  let schedulingLink = 'signup?strategist='+val.first_name+' '+val.last_name;
                  let strategist_descrp = val.description
                  return (
                    <li key={index} className="clients__page-stratagist-list" key={index}>
                    <div className="clients__page--profile-wrapper">
                      <div className="clients__page--profile-info">
                        <div className="clients__page--profile--avtar">
                          <img src={val && val.avatar ? val.avatar : 'img/login-bg-img.png'}/>
                        </div>
                        <h1 className="profile__title">{val && val.designation ? val.designation : 'Strategist'}</h1>
                        <a className="profile__email" href={val && val.email ? 'mailto:'+val.email : ''}>{val && val.email ? val.email : ''}</a>
                        <a className="profile__phone" href={val && val.phone_mobile ? 'tel:'+val.phone_mobile : val && val.phone_work ? val.phone_work : ''}>M: {val && val.phone_mobile ? formatPhoneNumber(val.phone_mobile) : val && val.phone_work ? formatPhoneNumber(val.phone_work.split('pin')[0]) + 'pin '+ val.phone_work.split('pin')[1] : ''}</a>
                      </div>
                      <div className="profile__details">
                      <ShowMore
                        lines={4}
                        more='View more'
                        less='View less'
                        anchorClass='ShowMoreToggle'
                        >
                        {val && strategist_descrp ? strategist_descrp : ''}
                      </ShowMore>
                      <button className="btn btn__transparent btn__schedule space-top" onClick={this.openScheduleBar.bind(this, val)}>Schedule with Strategist<img src="img/long-right-arrow.svg"/></button>
                        {/* <button className="btn btn__transparent btn__schedule space-top" onClick={this.openScheduleBar.bind(this, val)}>Schedule Time with {val && val.first_name ? val.first_name : ''} {val && val.last_name ? val.last_name : ''}<img src="img/long-right-arrow.svg"/></button> */}
                      </div>
                    </div>
                    </li>
                    
                  );
                }) : ''}
                </Slider>
                </ul>

                <div className="clients__page--list">
                  { agentData && agentData.length > 0 ?

                    <div className="clients__page--list">
                    <ul className="clients__page--list-view">
                      {agentData && agentData.length > 0 && agentData.map((val, index) => {
                        // let agent_descrp = val.agent_description
                         if(val.agent_id && val.agent_id!=null){
                          AgArray.push(val.agent_id)
                         }
                         val.towns.forEach(function(t){
                          if(t.town_id && t.town_id !=null){
                             tArray.push(t.town_id)
                          }
                        })
                       })
                     }
                    </ul>
                    {/*Start Here*/}
                    <div className="product-dtl-tab-wrapper">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="product-dtl-tab-section">
                          
                          <div className="product__tab">
                            <ul className="nav nav-tabs">
                              {/* <li className="active"><a data-toggle="tab" href="#agent_tab" >Agents ({agentArrayList && agentArrayList.length})</a></li>
                              <li ><a data-toggle="tab" href="#local_tab" >Locals ({localArrayList && localArrayList.length})</a></li> */}
                              <li className="active"><a data-toggle="tab" href="#agent_tab" >Your Towns</a>
                              </li>

                            </ul>
                          </div>

                          {
                            // (AgArray.length >0 || tArray.length >0)?
                            <div  className="clients__page--list">
                              <div className="clients__page--list-view">
                              <div className="tab-content">
                                <div id="agent_tab" className="tab-pane fade in active">
                                {
                                  agentData && agentData.length ? agentData.map((agent, ind1)=>{
                                    return (
                                          <li key={ind1}>
                                            <div className="row">
                                              {agent.agent_id !=null ?
                                              <div className="col-md-8 p__r--0 right_bord">
                                                {(agent && agent.agent_role =='agent') ? 
                                                  <div>
                                                  <div className="clients__page--list__info">
                                                  <div className="media avtar__wrapper">
                                                    <div className="media-left media-middle">
                                                      <div className="clients__page--list--avtar">
                                                        <img className="media-object" src={agent.agent_image ? agent.agent_image : ''} alt=""/>
                                                      </div>
                                                    </div>
                                                    <div className="media-body">
                                                      <h4 className="clients__page--list--avtar-title">{agent ? agent.agent_first_name+' '+agent.agent_last_name : ''}</h4>
                                                      <p className="clients__page--list--avtar-profile">{agent.agent_role}</p>
                                                      {(agent && agent.agent_role =='agent') ?
                                                      <div> 
                                                      <a className="navigation__links btn__grey client__msg--btn" href="/client-chat-center">Message Agent</a>
                                                      <button className="btn btn__green agent-schedule" onClick={this.openScheduleBar.bind(this, agent)}>Schedule </button>
                                                       </div>
                                                       : ''
                                                      }
                                                    </div>
                                                  </div>
                                                 
                                                  <p className="clients__page--list--description">
                                                   <ShowMore
                                                    lines={4}
                                                    more='View more'
                                                    less='View less'
                                                    anchorClass='ShowMoreToggle'
                                                    >
                                                    {agent &&  agent.agent_description ? agent.agent_description : ''}
                                                    </ShowMore>

                                                  </p>
                                                </div>
                                                <div className="clients__page--list__contact--info">
                                                  <div className="row">
                                                    <div className="col-md-5">
                                                      <div className="list__contact">
                                                        <h2>Email</h2>
                                                        <h4><a href={agent.agent_email ? 'mailto:'+agent.agent_email : ''}>{agent.agent_email ? agent.agent_email : ''}</a></h4>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <div className="list__contact">
                                                        <h2>Phone Number</h2>
                                                        <h4><a href={agent.agent_mobile ? 'tel:'+agent.agent_mobile : ''}>{agent.agent_mobile ? formatPhoneNumber(agent.agent_mobile) : ''}</a></h4>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                    {(agent && agent.agent_role =='agent') ?
                                                      <div className="list__contact">
                                                        <h2>Upcoming Dates</h2>
                                                        <h4>{agent.upcoming_schedules ? agent.upcoming_schedules : 'No Appointments Scheduled'}</h4>
                                                      </div>
                                                    : ''
                                                    }
                                                    </div>
                                                  </div>
                                                </div>
                                                  </div>
                                                  :
                                                  <div>
                                                  <div className="clients__page--list__info">
                                                  <div className="media avtar__wrapper">
                                                    <div className="media-left media-middle">
                                                      <div className="clients__page--list--avtar">
                                                        <img className="media-object" src={agent.agent_image ? agent.agent_image : ''} alt=""/>
                                                      </div>
                                                    </div>
                                                    <div className="media-body">
                                                      <h4 className="clients__page--list--avtar-title">{agent ? agent.agent_first_name+' '+agent.agent_last_name : ''}</h4>
                                                      <p className="clients__page--list--avtar-profile">{agent.agent_role}</p>
                                                      {(agent && agent.agent_role =='agent') ?
                                                      <div> 
                                                      <a className="navigation__links btn__grey client__msg--btn" href="/client-chat-center">Message Agent</a>
                                                      <button className="btn btn__green agent-schedule" onClick={this.openScheduleBar.bind(this, agent)}>Schedule </button>
                                                       </div>
                                                       : ''
                                                      }
                                                    </div>
                                                  </div>
                                                  <div className="clients__page--list__contact--info" style={{"border":0, "padding":0}}>
                                                  <div className="row">
                                                    <div className="col-md-7">
                                                      <div className="list__contact">
                                                        <h2>Email</h2>
                                                        <h4><a href={agent.agent_email ? 'mailto:'+agent.agent_email : ''}>{agent.agent_email ? agent.agent_email : ''}</a></h4>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                      <div className="list__contact">
                                                        <h2>Phone Number</h2>
                                                        <h4><a href={agent.agent_mobile ? 'tel:'+agent.agent_mobile : ''}>{agent.agent_mobile ? formatPhoneNumber(agent.agent_mobile) : ''}</a></h4>
                                                      </div>
                                                    </div>
                                                    </div>
                                                  </div>
                                                  
                                                </div>
                                                <div className="clients__page--list__contact--info">
                                                <p className="clients__page--list--description" style={{    'color': '#73777A'}}>
                                                   <ShowMore
                                                    lines={4}
                                                    more='View more'
                                                    less='View less'
                                                    anchorClass='ShowMoreToggle'
                                                    >
                                                    {agent &&  agent.agent_description ? agent.agent_description : ''}
                                                    </ShowMore>
                                                  </p>
                                                </div>
                                               </div>
                                                  }
                                              </div>
                                              :
                                              <div className="col-md-8 p__r--0 right_bord">
                                              <div className='KeepMessageinMiddle text-center'>
                                              You have no agents or locals assigned to you right now. Once we assign an agent or local you will be able to see them here.
                                              </div>
                                              </div>
                                              }
                                              <div className="col-md-4 left_bord">
                                                <div className="clients__town--reports">
                                                  <div className="clients__town--reports-head">
                                                    <img className="town__icon" src="img/town-report-icon.svg"/>  View Selected Town Reports
                                                  </div>
                                                  <ul className="clients__town--reports-list">
                                                    {agent && agent.towns && agent.towns.length > 0 ? agent.towns.map((towns, index2) => {
                                                      if(towns.town_id){
                                                        let town_link = 'javascript:void(0);'
                                                      if(towns && towns.town_url){
                                                      town_link = towns.town_url ? towns.town_url+'?userid='+this.state.userid+'&auth='+authorization : '';
                                                      }
                                                      let discription = towns.town_description ? towns.town_description : ''
                                                      if(discription.length > 70) {
                                                        discription = discription.substring(0,70)+'...';
                                                      }
                                                      return (
                                                        <li key = {index2}>
                                                          <a  className="clients__town--title">{towns && towns.town_name ? towns.town_name : ''}</a>
                                                          {/* <div className="clients__town--description">{towns && discription}</div> */}
                                                          <div className="clients__town--reports-action">
                                                            <a className="btn btn__grey" disabled={towns && town_link ? false : true} href={towns && town_link} target="_blank">View Town Report </a>
                                                          </div>
                                                        </li>
                                                      );
                                                      }else{
                                                        return (
                                                        <li key = {index2}>
                                                        This is where you would see a list of the towns assigned to you .There are no towns for you. Please check back later or contact your strategist.
                                                        </li>
                                                      );
                                                      }
                                                    }) : 
                                                      <li>
                                                        This is where you would see a list of the towns assigned to you .There are no towns for you. Please check back later or contact your strategist.
                                                       </li>
                                                    }
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      }) : 
                                      <div className="client__center">
                                        You have no locals assigned to you
                                      </div>
                                    }
                                </div>
                              </div>
                              </div>
                            </div>
                            // :
                            // <div className="welMsg">
                            //   {<img src="img/welMesIcon.svg" />}
                            //   <p>Your Home Search Strategy will arrive shortly after your initial strategy session!</p>
                            //   {/* <p>If you have any questions in the meantime, feel free to message your strategist.</p>
                            //   <button className="btn btn__green">Message Strategist</button> */}
                            // </div>
                          }

                         </div>
                        </div>
                      </div>
                    </div>
                  {/*End Here*/}
                    

                 </div>
                  :
                  <div className="welMsg">
                    <img src="img/welMesIcon.svg" />
                    <p>You will receive an email when we assign you these towns.</p>
                  </div>
                  }
                </div>




                <div className={this.state.openSchedule ? "schedule__sidebar--container open" : "schedule__sidebar--container"}>
                  <div className="schedule__sidebar--header">
                    Scheduling
                    <div className="close-btn" onClick={this.closeScheduleBar.bind(this)}>
                        <img src="img/close.svg"/>
                    </div>
                  </div>
                  <div className="schedule__sidebar--body">
                    <p>Schedule some time to speak or meet with your personal Suburban
                    strategist or Agent. They will get back to you within 24 hours.</p>
                    <div className="schedule__sidebar--form--wrapper">
                      <div className="form-group">
                        <label className="text__label--sm">With</label>
                        <select className="form-control custom-select" value = {this.state.selectedUserId}  onChange={this.handleChange.bind(this)}>
                          <option value = {'null'}>Select the Agent or Strategist</option>
                          {scheduleArray && scheduleArray.length > 0 ? scheduleArray.map((val, index) => {
                            let name = val && val.role === 'strategist' ? val && val.first_name+' '+val.last_name+ ' (Strategist)' : val && val.agent_first_name+' '+val.agent_last_name+ ' (Agent)' ;
                            let id = val && val.role === 'strategist' ? val && val.id : val && val.agent_id;
                            if(id && id !=null){
                              return (
                                <option 
                                  key = {index} 
                                  value = {id}>{name}</option>
                              );
                            }
                            
                          }) : ''}
                        </select>
                      </div>
                      <div className="form-group">
                        {<div className="form-group">
                        <label className="text__label--sm">Appointment Type</label>
                        <select 
                          className="form-control custom-select" 
                          name="appointment_type" 
                          value = {this.state.appointment_type} 
                          onChange={this.handleChangeApt.bind(this)}>
                          <option>Select the type of Appointment</option>
                            {appoinment}
                        </select>

                        {this.state.err.appointment_type ?
                        <span className="alert alert-danger error_field-schedule">
                        {this.state.err.appointment_type}
                        </span> : '' }
                      </div>}

                      </div>
                      {this.state.isLoading == false  ?
                      <div>
                      { this.state.availableDates.length > 0 ?
                        <div>
                        <DatePicker
                          selected={this.state.startDate}
                          customInput={<DatePickerCustomInput />}
                          onChange={this.handleDateClick.bind(this)}
                          includeDates={this.state.availableDates}
                          className="schedule-date"
                          placeholderText="Select available dates to pick a time" />
                          </div>
                        : <p>No data found.</p>
                      }
                        
                        {this.state.isDateLoading ?
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                            <img src="../../img/loader2.svg" />
                          </div>
                        </div>
                        :
                        <div className="row">
                         <div className="col-md-12" style={{'marginTop':'36px'}}>
                          {times != ""?
                          <select 
                            name='selectedTime' 
                            className="form-control custom-select-small" 
                            value = {this.state.selectStartTime}
                            onChange={this.handleChangeTime.bind(this)}>
                            <option>Time</option>
                             {times}
                          </select>
                          :
                          <div className="col-md-12 text-center">
                            <div className="alert alert-danger No-timeSlots">Time slots not available.</div>
                          </div>
                          }
                          </div>
                        </div>
                        }
                      </div>
                      : <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                               Fetching available dates...
                              </div>
                              </div>}
                       {this.state.err.selectedTime ?
                        <div className="alert alert-danger error_field-schedule">
                          {this.state.err.selectedTime}
                        </div>
                      : ''}
                      {this.state.message !=='' ?
                        <div className={submitSchedulePhase=== true ? "alert alert-success error_field" : "alert alert-danger error_field"}>
                          {this.state.message}
                        </div>
                      : ''}
                    </div>
                  </div>
                  {this.props.submitSchedulePhase === 'loading' ?
                    <div className="schedule__sidebar--footer">
                      <button className="btn btn-default   btn__green--lg btn-block btn__schedule disabled">Please wait...</button>
                    </div>
                    :
                    <div className="schedule__sidebar--footer">
                      <button className="btn btn__green btn__green--lg btn-block btn__schedule" onClick={this.submitSchedulingData.bind(this)}>Schedule</button>
                    </div>
                  }
                </div>
             
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal">
          <div className="client__popup-msg">
            <div className="close-btn" onClick={this.closeModal}>
              <img src="img/close.svg"/>
            </div>
            <h3>Appointment Scheduled</h3>
            <h4>Please check your email for more details from us</h4>
          </div>
        </Modal>

      </div>

    )
  }
}

export default reduxForm({
  form: 'clients',  // a unique identifier for this form
  destroyOnUnmount: true,
})(Clients)
