import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { Spin } from 'antd'

import './styles.scss'
import MobileView from './partials/MobileView'
import { SUCCESS } from '../../../../../constants/phase'
import Loader from '../../../../../components/Loader'

export default function clientDatarmationComponent(props) {
  const [isLoading, setLoading] = useState(false)
  // on component first mount  start  loader
  useEffect(() => {
    setLoading(true)
  }, [])
  // stop loader when client information fetched
  useEffect(() => {
    if (props.fetchClientByIdPhase === SUCCESS) {
      setLoading(false)
      props.clearClientPhase()
    }
  }, [props.fetchClientByIdPhase])

  // format number with coutnry code
  const formatNumber = (contactNumber, countryCode) => {
    const trimContact = `${contactNumber} `.replace(/\D/g, '')
    const matched = trimContact.match(/^(\d{3})(\d{3})(\d*)$/)
    if (matched) {
      return `${countryCode} (${matched[1]}) ${matched[2]}-${matched[3]}`
    }
    return ''
  }
  const client = get(props, 'clientDetail', {})
  const clientId = props.match.params.id && props.match.params.id
  const primaryContact = formatNumber(
    get(client, 'phone_mobile', '') ? get(client, 'phone_mobile', '').replace(/\D/g, '') : '',
    get(client, 'country_code', '')
  )
  const secondContact = formatNumber(
    get(client, 'secondary_phone_email', '') ? get(client, 'secondary_phone_email', '').replace(/\D/g, ''): '',
    get(client, 'secondary_country_code', '')
  )

  const primaryCompany = get(client, 'primary_company', '')
  const secondaryCompany = get(client, 'secondary_company', '')

  return (
    <div>
      <MobileView />
      <div className="card">
        <div className="card-header d__flex align__items__center">
          <div>
            <h5 className="card-title mb__3">
              {get(client, 'client_full_name', '')}
              <span className="client-status">
                {' '}
                {get(client, 'status', '') && `(${client.status})`}
              </span>
            </h5>
            <p className="card-subtitle">
              <strong>{get(client, 'secondary_name', false) && 'Secondary Contact:'} </strong>
              {get(client, 'secondary_first_name', false) && get(client, 'secondary_first_name')}{' '}
              {get(client, 'secondary_last_name', false) && get(client, 'secondary_last_name')}
            </p>
          </div>
          <div className="cleint__information__cta">
            <Link
              to={{
                pathname: `/strategist/edit-information/${clientId}`,
                state: { prevRoute: get(props, 'history.location.state.prevRoute', '') },
              }}
              className="btn btn__btn btn-dark btn__edit__client"
            >
              Edit Client Contact Information
            </Link>
          </div>
        </div>
        <Spin size="large" spinning={isLoading} indicator={<Loader />}>
          <div className="card-body card-body-sm">
            <div className="row">
              <div className="col-md-4">
                {get(client, 'client_email', '') && (
                  <div className="info__block">
                    <label>Client Email (primary)</label>
                    <p>
                      <a href={`mailto:${get(client, 'client_email', '')}`} target="_blank">
                        {get(client, 'client_email', '') ? get(client, 'client_email', '') : ''}
                      </a>
                    </p>
                  </div>
                )}
                {primaryContact && (
                  <div className="info__block">
                    <label>Client Phone Number (primary)</label>
                    <p>
                      <a href={`tel:${primaryContact}`} target="_blank">{`${primaryContact}`}</a>
                    </p>
                  </div>
                )}
                {get(client, 'secondary_email', '') && (
                  <div className="info__block">
                    <label>Client Email (secondary)</label>
                    <p>
                      <a href={`mailto:${get(client, 'secondary_email', '')}`} target="_blank">
                        {get(client, 'secondary_email', '')}
                      </a>
                    </p>
                  </div>
                )}
                {secondContact && (
                  <div className="info__block">
                    <label>Client Phone Number (secondary)</label>
                    <p>
                      <a href={`tel:${secondContact}`} target="_blank">{`${secondContact}`}</a>
                    </p>
                  </div>
                )}
              </div>
              <div className="col-md-8">
                {get(client, 'source', '') && (
                  <div className="info__block">
                    <label>Source</label>
                    <p>{get(client, 'source', '')}</p>
                  </div>
                )}

                <div className="info__block">
                  <label>Companies</label>
                  <p>Primary: {primaryCompany.length > 0 ? primaryCompany : 'N/A'}</p>
                  <p>Secondary: {secondaryCompany.length > 0 ? secondaryCompany : 'N/A'}</p>
                </div>

                {/*   <div className="info__block">
                <label>Make Client Local </label>
                <p>Yes</p>
              </div> */}
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}
